import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import LeaderBoardBackground from "../assets/images/Mask-Group-41.svg"

const styles = {
  container: {
    background: `url(${LeaderBoardBackground}) no-repeat`,
    height: 400,
    backgroundSize:"cover",
    "@media (min-width:0px) and (max-width:700px)":{
      height:620  
    },
    color: "white",
  },
  mainTitle: {
    height: "400px",
    "@media (min-width:0px) and (max-width:700px)":{
      height:200,
      paddingTop:26
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  scoreSection: {
    display: "flex",
    width: "100%",
    justifyContent: "space-around",
    height: "50%",
    alignItems: "center",
  },
  alignScore:{
    display:"flex",
    justifyContent:"center",
    flexDirection:"column",
    alignItems:"center",
    width:"50%"
  },
  scoreNumber:{
    fontSize:45,
    "@media (min-width:0px) and (max-width:700px)":{
      fontSize:36  
    },
  },
  caption:{
    opacity:0.4,
    fontWeight:"bolder",
    "@media (min-width:0px) and (max-width:700px)":{
      fontSize:14
    },
  }

};

class LeaderBoard extends Component {
  render() {
    const { classes, pointStats = {} } = this.props;
    return (
      <div className={classes.container}>
        <div className="sm-col sm-col-6 md-col-6 lg-col-6">
          <div className={classes.mainTitle}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <img
                src={require("../assets/images/Group 2289.svg")}
                alt="cup"
                height="100px"
              />
              <span style={{ marginTop: 10, fontWeight: "bolder", fontSize: 40 }}>
                LEADERBOARD
              </span>
            </div>
          </div>
        </div>
        <div className="sm-col sm-col-6 md-col-6 lg-col-6">
          <div style={{ height: "400px" }}>
            <div className={classes.scoreSection}>
              <div className={classes.alignScore}>
                <div className={classes.scoreNumber}>{pointStats.rank || 'N/A'}</div>
                <div className={classes.caption}>Your Rank</div>
              </div>
              <div className={classes.alignScore}>
                <div className={classes.scoreNumber}>{pointStats.participantsCount}</div>
                <div className={classes.caption}>People Participating</div>
              </div>
            </div>
            <div className={classes.scoreSection}>
              <div className={classes.alignScore}>
                <div className={classes.scoreNumber}>{pointStats.totalPoints}</div>
                <div className={classes.caption}>Your Score</div>
              </div>
              <div className={classes.alignScore}>
                <div className={classes.scoreNumber}>{pointStats.topPoints}</div>
                <div className={classes.caption}>Top Score</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(LeaderBoard);
