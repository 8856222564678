import qa from './qa';
import development from './development';
import production from './production';

let currentConfig = development.casa;
switch (process.env.REACT_APP_DEPLOY_ENV) {
  case 'dev':
    currentConfig = development;
    break;
  case 'qa':
    currentConfig = qa;
    break;
  case 'production':
    currentConfig = production;
    break;
  case 'prod':
    currentConfig = production;
    break;
  default:
    throw new Error(
      `env config missing for ${process.env.REACT_APP_DEPLOY_ENV}`
    );
}
const config = currentConfig;

export default config;
