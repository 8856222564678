import config from '../config';
import axios from 'axios';

function getBeatBusiness(id) {
    return fetch(`${config.ruleServerUrl}/dalmia/beat-business/${id}`, {
        'method': 'GET',
        'credentials': 'include'
    })
        .then(res => res.json())
}

function getSalesPersonDetails(token) {
    return axios.get(`${config.beatServerUrl}/api/users/me`, { headers: {"Authorization" : `Bearer ${token}`} })
        .then(res => {
            return res.data
        })
}

export default {
    getBeatBusiness,
    getSalesPersonDetails
}
