import React from "react";
import CustomerRegistrationHeader from "../assets/images/customer_registration_header.png";
import withStyles from "@material-ui/styles/es/withStyles";
import {
  Button,
  RadioGroup,
  Select,
  StepConnector,
  TextField,
  Popover,
  Typography,
} from "@material-ui/core";
import RightArrow from "../assets/icons/arrow-right.svg";
import Timer from "../components/timer";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import StepIcon from "../components/user-registration-stepper-icon";
// import Connector from '../components/stepper-connector-component';
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import config from "../config";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getCity, getStates } from "../util/city-state-util";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import FormHelperText from "@material-ui/core/FormHelperText";
import * as _ from "lodash";
import OtpInput from "react-otp-input";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import * as emailValidator from "email-validator";
import sessionService from "../services/session-service";
import { UserModel } from "../models/user-model";
import Redirect from "react-router-dom/Redirect";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = (theme) => ({
  privacyPolicy: {
    letterSpacing: 0,
    fontFamily: "Noto Sans TC",
    color: "#707070",
    backgroundColor: "#E6E7E8",
    fontSize: 14,
    height: 40,
    marginTop: "-5px",
    display: "flex",
    alignItems: "center",
    padding: 10,
    "@media (min-width:0px) and (max-width:700px)": {
      fontSize: 12,
    },
  },
  container: {},
  otpHeader: {
    textAlign: "center",
    fontSize: 18,
    marginTop: 30,
    fontWeight: "bold",
    fontFamily: "Noto Sans",
    letterSpacing: 0,
    color: "#151415",
    opacity: 1,
  },
  buttonBottom: {
    bottom: 0,
    textAlign: "center",
    width: "100%",
    padding: 10,
    backgroundColor: "#F47F1F",
    color: "white",
    fontSize: 16,
    fontFamily: "Noto Sans",
    borderRadius: 0,
  },
  text: {
    color: "#939597",
    fontSize: 12,
    fontFamily: "Noto Sans",
    width: "80%",
    margin: "0 auto",
    marginTop: 20,
  },
  timer: {
    color: "#1E9FDA",
    fontSize: 21,
    fontFamily: "Noto Sans",
    fontWeight: "bold",
  },
  highlightedText: {
    color: "#F47F1F",
    textTransform: "uppercase",
    fontSize: 12,
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    marginTop: 30,
  },
  stepperLabel: {
    width: "100%",
    color: "#F47F1F",
    textTransform: "uppercase",
    fontSize: 12,
    fontFamily: "Noto Sans",
    fontWeight: "bold",
  },
  inactiveStepperLabel: {
    width: "100%",
    textTransform: "uppercase",
    fontSize: 12,
    fontFamily: "Noto Sans",
    fontWeight: "bold",
  },
  radioLabel: {
    fontFamily: "Noto Sans",
    fontSize: 12,
    color: "#151415",
    fontWeight: 700,
    opacity: 1,
  },
  formControl: {
    width: "95%",
    marginTop: 10,
  },
  radio: {
    "&$checked": {
      color: "#F47F1F !important",
    },
  },
  formSection: {
    padding: "0px 50px",
    margin: "0 auto",
    marginBottom: "0px",
    background: "rgb(246, 246, 246)",
    paddingBottom: 60,
    "@media (min-width:0px) and (max-width:700px)": {
      padding: "0px 30px",
      paddingBottom: 60,
    },
  },
  nextButton: {
    width: 300,
    height: 50,
    margin: "0px auto",
    "@media (min-width:0px) and (max-width:700px)": {
      width: "100%",
      margin: 0,
    },
  },
  nextSection: {
    width: "100%",
    height: 150,
    background: "#F6F6F6",
    marginTop: "-60px",
    "@media (min-width:0px) and (max-width:700px)": {
      // marginTop:"90px",
      height: 0,
    },
  },
  next: {
    bottom: 0,
    textAlign: "center",
    width: "100%",
    padding: "10px !important",
    backgroundColor: "#F47F1F !important",
    color: "white !important",
    fontSize: "16px !Important",
    fontFamily: "Noto Sans !important",
    borderRadius: 0,
    marginTop: "50px !important",
    "@media (min-width:0px) and (max-width:700px)": {
      marginTop: "12px !important",
    },
  },
  verificationSection: {
    marginTop: 40,
    padding: "20px 50px",
    background: "white",
    marginBottom: 70,
    "@media (min-width:0px) and (max-width:700px)": {
      padding: "0px 20px",
      height: "auto",
      // marginBottom:60,
    },
  },
  verifyOtp: {
    textAlign: "center",
    width: "300px",
    height: 50,
    padding: 10,
    backgroundColor: "#F47F1F !important",
    color: "white !important",
    fontSize: 16,
    fontFamily: "Noto Sans !important",
    borderRadius: 0,
    "@media (min-width:0px) and (max-width:700px)": {
      width: "100%",
    },
  },
  connector: {
    borderTopWidth: "2px !important",
    borderTopStyle: "dashed !important",
    margin: "0 -6px !important",
  },
  labelSize: {
    flex: "0.2 !important",
    "@media (min-width:0px) and (max-width:700px)": {
      flex: "0.5 !important",
    },
    "@media (min-width:700px) and (max-width:1000px)": {
      flex: "1 !important",
    },
  },
  stepperLayout: {
    display: "flex",
    justifyContent: "center",
    padding: "24px !important",
    "@media (min-width:0px) and (max-width:700px)": {
      // padding:"10px !important",
      padding: "30px 0px !important",
    },
  },
  banner: {
    width: "100%",
    height: 313,
    background: `url(${CustomerRegistrationHeader}) no-repeat`,
    backgroundSize: "100% 100%",
    display: "flex",
    justifyContent: "flex-end",
    "@media (min-width:0px) and (max-width:700px)": {
      height: 110,
    },
    // "@media (min-width:700px) and (max-width:1000px)":{
    //   height:110
    // }
  },
  bannerContent: {
    padding: "21px 32px",
    color: "white",
    "@media (min-width:0px) and (max-width:700px)": {
      padding: "6px 7px",
    },
    "@media (min-width:700px) and (max-width:1000px)": {
      padding: "21px 4px",
    },
  },
  moreIconSize: {
    fontSize: "65px !important",
    cursor: "pointer",
    "@media (min-width:0px) and (max-width:700px)": {
      fontSize: "26px !important",
    },
  },
  companyName: {
    fontSize: 12,
    fontFamily: "Noto Sans",
    width: "96%",
    marginTop: 20,
    "@media (min-width:0px) and (max-width:700px)": {
      width: "100%",
    },
  },
  fieldStyles: {
    color: "#939597 !important",
    fontWeight: "300 !important",
    fontFamily: "Noto Sans TC !important",
    fontSize: "16px !important",
    "@media (min-width:0px) and (max-width:700px)": {
      fontSize: "14px !important",
    },
  },
  checked: {},
});

const statuses = {
  OTP_REQUESTED: "otp_requested",
  OTP_VERIFIED: "otp_verified",
  OTP_EXPIRED: "otp_expired",
  FORM_PAGE_1: "form_page_1",
  DEFAULT: "",
};

const documentLabel = {
  gst: "GST Number",
  pan: "PAN Number",
  other: "Other",
};

const styles = useStyles();
class CustomerLandingPage extends React.Component {
  constructor(props) {
    super(props);
    let stateFromLocal = {};
    let tokenObj = sessionService.isLoggedIn()
      ? sessionService.getUserTokenObj()
      : undefined;
    if (tokenObj) {
      stateFromLocal = {
        tokenObj: tokenObj,
        status: statuses.OTP_VERIFIED,
      };
    }
    this.state = {
      loggedInUser: new UserModel(),
      flowHeader: "Registration",
      isOtpValid: true,
      activeStep: 0,
      status: statuses.DEFAULT,
      mobileNumber: null,
      companyName: "",
      documentType: null,
      addressLine1: null,
      addressLine2: null,
      city: null,
      state: null,
      pincode: null,
      validationField: null,
      firstName: null,
      middleName: null,
      lastName: null,
      ownerAddressLine1: null,
      ownerAddressLine2: null,
      ownerCity: null,
      ownerState: null,
      ownerPincode: null,
      email: null,
      alternateMobile: null,
      otp: "",
      isGetOtpTriggered: false,
      isGenerating: false,
      anchorEl: null,
      ...stateFromLocal,
    };
  }
  saveCustomerRegistration = async (status) => {
    const {
      companyName,
      addressLine1,
      addressLine2,
      city,
      state,
      // documentType,
      validationField,
      firstName,
      middleName,
      lastName,
      pincode,
      ownerAddressLine1,
      ownerAddressLine2,
      ownerCity,
      ownerState,
      ownerPincode,
      email,
      mobileNumber,
      alternateMobile,
    } = this.state;

    if (!firstName) {
      this.setState({ firstNameError: "Please Enter Your First Name" });
      return;
    }

    if (!ownerAddressLine1) {
      this.setState({
        ownerAddressLine1Error: "Please Enter Your Address Line 1",
      });
      return;
    }

    // if(!ownerAddressLine2) {
    //   this.setState({ownerAddressLine2Error: 'Please Enter Your Address Line 2'});
    //   return;
    // }

    if (!ownerState) {
      this.setState({ ownerStateError: "Please Select Your State" });
      return;
    }

    if (!ownerCity) {
      this.setState({ ownerCityError: "Please Enter Your City" });
      return;
    }

    if (!ownerPincode) {
      this.setState({ ownerPinError: "Please Enter Your Pin Code" });
      return;
    }

    if (ownerPincode.length !== 6) {
      this.setState({ ownerPinError: "Pin Code can only be 6 digits" });
      return;
    }
    if (isNaN(ownerPincode)) {
      this.setState({ ownerPinError: "Pin Code can only contain numbers" });
      return;
    }

    // if(!email) {
    //   this.setState({ownerEmailError: 'Please Enter Your Email ID'});
    //   return;
    // }

    if (email && !emailValidator.validate(email)) {
      this.setState({ ownerEmailError: "Please Enter a Valid Email ID" });
      return;
    }

    if (alternateMobile && alternateMobile.length !== 10) {
      this.setState({
        alternateMobileError: "Mobile number can only be 10 digits",
      });
      return;
    }

    if (alternateMobile && isNaN(alternateMobile)) {
      this.setState({
        alternateMobileError: "Mobile number can only contain numbers",
      });
      return;
    }

    const body = {
      tenantId: config.tenantId,
      mobile: mobileNumber,
      status: status,
      tokenObj: this.state.tokenObj,
      form: {
        formStatus: status,
        status: status,
        lastName,
        firstName,
        middleName,
        mobile: mobileNumber,
        alternateMobile: alternateMobile,
        email,
        businessName: companyName,
        name: firstName,
        validationField,
        address: {
          addressLine1,
          addressLine2,
          state,
          city,
          pincode,
        },
        ownerAddress: {
          ownerAddressLine1,
          ownerAddressLine2,
          ownerState,
          ownerCity,
          ownerPincode,
        },
      },
      submission: {
        submitterType: "CUSTOMER",
        submissionStatus: status,
      },
    };

    this.setState({ saveError: undefined });
    fetch(`${config.ruleServerUrl}/dalmia/save`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then(async (response) => {
        if (response.ok) {
          window.location = "/acknowledgement/";
        } else {
          return Promise.reject(await response.json());
        }
      })
      .catch((err) => {
        console.error(err);
        this.setState({ saveError: "Save Failed." });
      });
  };

  renderSteps() {
    const {
      activeStep,
      documentType,
      mobileNumber,
      companyName,
      addressLine1,
      alternateMobile,
      email,
      ownerPincode,
      ownerState,
      ownerCity,
      ownerAddressLine2,
      ownerAddressLine1,
      lastName,
      middleName,
      firstName,
      validationField,
      pincode,
      state,
      city,
      addressLine2,
    } = this.state;
    const {
      businessNameError,
      validationError,
      documentTypeError,
      pinError,
      addressLine1Error,
      addressLine2Error,
      stateError,
      cityError,
      firstNameError,
      lastNameError,
      middleNameError,
      ownerAddressLine1Error,
      ownerAddressLine2Error,
      ownerStateError,
      ownerCityError,
      ownerPinError,
      ownerEmailError,
      alternateMobileError,
    } = this.state;
    const { classes } = this.props;

    switch (activeStep) {
      default:
      case 0:
        return (
          <>
            <div className={classes.formSection}>
              <div className={"col-12 lg-col-4 sm-col-12"}>
                <TextField
                  value={companyName || ""}
                  label={
                    <span className={classes.fieldStyles}>*Company name</span>
                  }
                  error={!!businessNameError}
                  helperText={businessNameError}
                  className={classes.companyName}
                  onChange={(event) =>
                    this.setState({
                      companyName: event.target.value,
                      businessNameError: null,
                    })
                  }
                />
              </div>
              <TextField
                value={addressLine1 || ""}
                label={
                  <span className={classes.fieldStyles}>*Address line 1</span>
                }
                placeholder="maximum of 40 characters"
                inputProps={{ maxlength: 40 }}
                error={!!addressLine1Error}
                helperText={addressLine1Error}
                style={{
                  fontSize: 12,
                  fontFamily: "Noto Sans",
                  width: "100%",
                  marginTop: 20,
                }}
                onChange={(event) =>
                  this.setState({
                    addressLine1: event.target.value,
                    addressLine1Error: null,
                    ownerAddressLine1: event.target.value,
                  })
                }
              />
              <TextField
                value={addressLine2 || ""}
                label={
                  <span className={classes.fieldStyles}>Address line 2</span>
                }
                placeholder="maximum of 40 characters"
                inputProps={{ maxlength: 40 }}
                error={!!addressLine2Error}
                helperText={addressLine2Error}
                style={{
                  fontSize: 12,
                  fontFamily: "Noto Sans",
                  width: "100%",
                  marginTop: 20,
                }}
                onChange={(event) =>
                  this.setState({
                    addressLine2: event.target.value,
                    addressLine2Error: null,
                    ownerAddressLine2: event.target.value,
                  })
                }
              />
              <div style={{ paddingTop: 10, paddingLeft: 10 }}>
                <Grid container spacing={3}>
                  <Grid xs={6} sm={4}>
                    <div style={{ marginTop: 15 }}>
                      <FormControl
                        className={classes.formControl}
                        error={!!stateError}
                      >
                        <InputLabel
                          id="state-customer-reg"
                          classes={{ formControl: classes.fieldStyles }}
                        >
                          *State
                        </InputLabel>
                        <Select
                          IconComponent={() => (
                            <ArrowDropDownIcon style={{ fill: "#F47F1F" }} />
                          )}
                          id="demo-simple-select"
                          labelid="state-customer-reg"
                          value={state || ""}
                          InputLabelProps={{ shrink: !!state }}
                          onChange={(event) =>
                            this.setState({
                              state: event.target.value,
                              stateError: null,
                              ownerState: event.target.value,
                            })
                          }
                        >
                          {/*<MenuItem value="">None</MenuItem>*/}
                          {getStates().map((displayValue) => {
                            return (
                              <MenuItem value={displayValue}>
                                {displayValue}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText>{stateError}</FormHelperText>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid xs={6} sm={4}>
                    <div style={{ marginTop: 15 }}>
                      <FormControl
                        className={classes.formControl}
                        error={!!cityError}
                      >
                        <InputLabel
                          id="-city-customer-reg"
                          classes={{ formControl: classes.fieldStyles }}
                        >
                          *City
                        </InputLabel>
                        <Select
                          IconComponent={() => (
                            <ArrowDropDownIcon style={{ fill: "#F47F1F" }} />
                          )}
                          id="demo-simple-select"
                          displayEmpty
                          labelid="-city-customer-reg"
                          value={city || ""}
                          InputLabelProps={{ shrink: !!city }}
                          onChange={(event) =>
                            this.setState({
                              city: event.target.value,
                              cityError: null,
                              ownerCity: event.target.value,
                            })
                          }
                        >
                          {/*<MenuItem value="">None</MenuItem>*/}
                          {(getCity(state) || []).map((displayValue) => {
                            return (
                              <MenuItem value={displayValue}>
                                {displayValue}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText>{cityError}</FormHelperText>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid xs={6} sm={4}>
                    <TextField
                      value={pincode || ""}
                      label={<span className={classes.fieldStyles}>*PIN</span>}
                      error={!!pinError}
                      helperText={pinError}
                      type="number"
                      style={{
                        fontSize: 12,
                        fontFamily: "Noto Sans",
                        width: "97%",
                        marginTop: 16.5,
                      }}
                      onChange={(event) =>
                        this.setState({
                          pincode: event.target.value,
                          pinError: null,
                          ownerPincode: event.target.value,
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className={classes.verificationSection}>
              <FormControl style={{ width: "100%" }}>
                <FormLabel
                  style={{
                    fontSize: 16,
                    fontWeight: 700,
                    color: "#151415",
                    marginBottom: 15,
                  }}
                >
                  Select any one of the following and provide details
                </FormLabel>
                <RadioGroup
                  aria-label="documentType"
                  name="documentType"
                  value={documentType}
                  onChange={(event) =>
                    this.setState({
                      documentType: event.target.value,
                      documentTypeError: null,
                      validationError: null,
                      validationField: null,
                    })
                  }
                >
                  <Grid container>
                    {Object.keys(documentLabel).map((key) => (
                      <Grid xs={12} sm={4}>
                        <FormControlLabel
                          value={key}
                          control={
                            <Radio
                              classes={{
                                root: classes.radio,
                                checked: classes.checked,
                              }}
                              size="small"
                            />
                          }
                          label={
                            <div
                              style={{
                                fontSize: 16,
                                fontFamily: "Noto Sans",
                                color:
                                  key === documentType ? "#151415" : "#939597",
                              }}
                            >
                              {documentLabel[`${key}`]}
                            </div>
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>
                </RadioGroup>
              </FormControl>
              {documentType && (
                <TextField
                  value={validationField || ""}
                  label={documentLabel[documentType]}
                  style={{
                    fontSize: 12,
                    fontFamily: "Noto Sans",
                    width: "100%",
                  }}
                  error={validationError}
                  helperText={validationError}
                  InputLabelProps={{
                    style: {
                      color: "#939597",
                      fontWeight: 300,
                      fontFamily: "Noto Sans TC",
                      fontSize: 16,
                    },
                  }}
                  onChange={(event) =>
                    this.setState({
                      validationField: _.toUpper(event.target.value),
                      validationError: null,
                    })
                  }
                />
              )}
              {documentTypeError && (
                <div
                  style={{
                    color: "red",
                    fontSize: 12,
                    textAlign: "center",
                    marginTop: 10,
                  }}
                >
                  {documentTypeError}
                </div>
              )}
            </div>
            <div className={classes.nextSection}>
              <div className={classes.nextButton}>
                <Button
                  className={classes.next}
                  onClick={async () => {
                    await this.handleBusinessInfoSave();
                  }}
                >
                  <b style={{ display: "flex", alignItems: "center" }}>
                    NEXT
                    <img src={RightArrow} alt="right" style={{ width: 20 }} />
                  </b>
                </Button>
              </div>
            </div>
          </>
        );
      case 1:
        return (
          <>
            <div
              className={classes.formSection}
              // style={{width: '90%', margin: '0 auto', marginBottom: 30}}
            >
              <TextField
                value={firstName || ""}
                label={<span className={classes.fieldStyles}>*First name</span>}
                error={!!firstNameError}
                style={{
                  fontSize: 12,
                  fontFamily: "Noto Sans",
                  width: "100%",
                  marginTop: 20,
                }}
                helperText={firstNameError}
                onChange={(event) =>
                  this.setState({
                    firstName: event.target.value,
                    firstNameError: null,
                  })
                }
              />
              <TextField
                value={middleName || ""}
                label={<span className={classes.fieldStyles}>Middle name</span>}
                error={!!middleNameError}
                style={{
                  fontSize: 12,
                  fontFamily: "Noto Sans",
                  width: "100%",
                  marginTop: 20,
                }}
                helperText={middleNameError}
                onChange={(event) =>
                  this.setState({
                    middleName: event.target.value,
                    middleNameError: null,
                  })
                }
              />
              <TextField
                value={lastName || ""}
                label={<span className={classes.fieldStyles}>Last name</span>}
                error={!!lastNameError}
                style={{
                  fontSize: 12,
                  fontFamily: "Noto Sans",
                  width: "100%",
                  marginTop: 20,
                }}
                helperText={lastNameError}
                onChange={(event) =>
                  this.setState({
                    lastName: event.target.value,
                    lastNameError: null,
                  })
                }
              />
              <TextField
                inputProps={{ maxlength: 40 }}
                placeholder="maximum of 40 characters"
                value={ownerAddressLine1 || ""}
                label={
                  <span className={classes.fieldStyles}>*Address line 1</span>
                }
                style={{
                  fontSize: 12,
                  fontFamily: "Noto Sans",
                  width: "100%",
                  marginTop: 20,
                }}
                InputLabelProps={{
                  style: {
                    color: "#939597",
                    fontWeight: 300,
                    fontFamily: "Noto Sans TC",
                    fontSize: 16,
                  },
                }}
                error={!!ownerAddressLine1Error}
                helperText={ownerAddressLine1Error}
                onChange={(event) =>
                  this.setState({
                    ownerAddressLine1: event.target.value,
                    ownerAddressLine1Error: null,
                  })
                }
              />
              <TextField
              inputProps={{ maxlength: 40 }}
              placeholder="maximum of 40 characters"
                value={ownerAddressLine2 || ""}
                label={
                  <span className={classes.fieldStyles}>Address line 2</span>
                }
                error={!!ownerAddressLine2Error}
                helperText={ownerAddressLine2Error}
                style={{
                  fontSize: 12,
                  fontFamily: "Noto Sans",
                  width: "100%",
                  marginTop: 20,
                }}
                onChange={(event) =>
                  this.setState({
                    ownerAddressLine2: event.target.value,
                    ownerAddressLine2Error: null,
                  })
                }
              />
              <div style={{ paddingLeft: 10, paddingTop: 10 }}>
                <Grid container spacing={3} style={{ marginBottom: 1 }}>
                  <Grid xs={6}>
                    <div style={{ marginTop: 15 }}>
                      <FormControl
                        className={classes.formControl}
                        error={!!ownerStateError}
                      >
                        <InputLabel
                          id="owner-state-customer-reg"
                          classes={{ formControl: classes.fieldStyles }}
                        >
                          *State
                        </InputLabel>
                        <Select
                          IconComponent={() => (
                            <ArrowDropDownIcon style={{ fill: "#F47F1F" }} />
                          )}
                          id="demo-simple-select"
                          displayEmpty
                          labelid="owner-state-customer-reg"
                          value={ownerState || ""}
                          InputLabelProps={{ shrink: !!ownerState }}
                          onChange={(event) =>
                            this.setState({
                              ownerState: event.target.value,
                              ownerStateError: null,
                            })
                          }
                        >
                          {/*<MenuItem value="">None</MenuItem>*/}
                          {getStates().map((displayValue) => {
                            return (
                              <MenuItem value={displayValue}>
                                {displayValue}
                              </MenuItem>
                            );
                          })}
                          customer
                        </Select>
                        <FormHelperText>{ownerStateError}</FormHelperText>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid xs={6}>
                    <div style={{ marginTop: 15 }}>
                      <FormControl
                        className={classes.formControl}
                        error={!!ownerCityError}
                      >
                        <InputLabel
                          id="owner-city-customer-reg"
                          classes={{ formControl: classes.fieldStyles }}
                        >
                          *City
                        </InputLabel>
                        <Select
                          IconComponent={() => (
                            <ArrowDropDownIcon style={{ fill: "#F47F1F" }} />
                          )}
                          id="demo-simple-select"
                          displayEmpty
                          labelid="owner-city-customer-reg"
                          value={ownerCity || ""}
                          InputLabelProps={{ shrink: !!ownerCity }}
                          onChange={(event) =>
                            this.setState({
                              ownerCity: event.target.value,
                              ownerCityError: null,
                            })
                          }
                        >
                          {/*<MenuItem value="">None</MenuItem>*/}
                          {(getCity(ownerState) || []).map((displayValue) => {
                            return (
                              <MenuItem value={displayValue}>
                                {displayValue}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText>{ownerCityError}</FormHelperText>
                      </FormControl>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <TextField
                value={ownerPincode || ""}
                label={<span className={classes.fieldStyles}>*PIN</span>}
                type="number"
                style={{
                  fontSize: 12,
                  fontFamily: "Noto Sans",
                  width: "100%",
                  marginTop: 10,
                }}
                error={!!ownerPinError}
                helperText={ownerPinError}
                onChange={(event) =>
                  this.setState({
                    ownerPincode: event.target.value,
                    ownerPinError: null,
                  })
                }
              />
              <TextField
                value={email || ""}
                label={<span className={classes.fieldStyles}>Email ID</span>}
                style={{
                  fontSize: 12,
                  fontFamily: "Noto Sans",
                  width: "100%",
                  marginTop: 20,
                }}
                error={!!ownerEmailError}
                helperText={ownerEmailError}
                onChange={(event) =>
                  this.setState({
                    email: event.target.value,
                    ownerEmailError: null,
                  })
                }
              />
              <Grid container style={{ marginBottom: 40 }}>
                <Grid xs={6}>
                  <TextField
                    label={
                      <span className={classes.fieldStyles}>
                        *Mobile Number
                      </span>
                    }
                    value={mobileNumber}
                    style={{
                      fontSize: 12,
                      fontFamily: "Noto Sans",
                      width: "95%",
                      marginTop: 20,
                    }}
                    disabled
                  />
                </Grid>
                <Grid xs={6}>
                  <TextField
                    value={alternateMobile || ""}
                    label={
                      <span className={classes.fieldStyles}>
                        Alternate Number
                      </span>
                    }
                    type="number"
                    style={{
                      fontSize: 12,
                      fontFamily: "Noto Sans",
                      width: "100%",
                      marginTop: 20,
                    }}
                    error={!!alternateMobileError}
                    helperText={alternateMobileError}
                    onChange={(event) =>
                      this.setState({
                        alternateMobile: event.target.value,
                        alternateMobileError: null,
                      })
                    }
                  />
                </Grid>
              </Grid>
              <div>
                {this.state.saveError && (
                  <div className={"center"} style={{ color: "red" }}>
                    {this.state.saveError}
                  </div>
                )}
              </div>
            </div>
            <div className={classes.nextSection}>
              <div className={classes.nextButton}>
                <Button
                  className={classes.next}
                  onClick={async () => {
                    await this.saveCustomerRegistration("SUBMITTED");
                  }}
                >
                  <b style={{ display: "flex", alignItems: "center" }}>
                    Submit
                    <img src={RightArrow} alt="right" style={{ width: 20 }} />
                  </b>
                </Button>
              </div>
            </div>
          </>
        );
    }
  }

  async handleBusinessInfoSave() {
    const {
      companyName,
      validationField,
      documentType,
      pincode,
      state,
      city,
      addressLine1,
    } = this.state;
    if (!companyName) {
      this.setState({ businessNameError: "Please enter a business name" });
      return;
    }

    if (!addressLine1) {
      this.setState({ addressLine1Error: "Please enter a address" });
      return;
    }

    if (!state) {
      this.setState({ stateError: "State is required" });
      return;
    }

    if (!city) {
      this.setState({ cityError: "City is required" });
      return;
    }

    if (!pincode) {
      this.setState({ pinError: "Pin Code is required" });
      return;
    }

    if (pincode.length !== 6) {
      this.setState({ pinError: "Pin Code can only be 6 digits" });
      return;
    }

    if (isNaN(pincode)) {
      this.setState({ pinError: "Pin Code can only contain numbers" });
      return;
    }

    // if(!documentType) {
    //   this.setState({documentTypeError: 'Please select a document type'});
    //   return;
    // }

    // if (!validationField) {
    //   this.setState({validationError: `Please enter a valid ${documentType.toUpperCase()} Number`});
    //   return;
    // }
    if (documentType === "gst") {
      let gstInputFormat = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[\d]{1}[Z]{1}[A-Z\d]{1}/.test(
        validationField
      );
      if (
        (validationField && validationField.length !== 15) ||
        !gstInputFormat
      ) {
        this.setState({ validationError: `Please enter a valid GST Number` });
        return;
      }
    } else if (documentType === "pan") {
      let panCardInputFormat = /[A-Z]{3}[PCAFHT]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$/.test(
        validationField
      );
      if (
        (validationField && validationField.length !== 10) ||
        !panCardInputFormat
      ) {
        this.setState({ validationError: `Please enter a valid PAN Number` });
        return;
      }
    }

    this.setState({ activeStep: 1 });
    await this.saveCustomerRegistration("SEMI_SUBMITTED");
  }

  handleGetOtp = async () => {
    const { mobileNumber } = this.state;
    if (!mobileNumber || mobileNumber.length !== 10) {
      this.setState({
        mobileNumberError: "Please check the mobile number entered",
      });
      return;
    }
    this.setState({ isGenerating: true });
    // need to move to service
    await fetch(
      `${config.ruleServerUrl}/flow-otp/generate-for/customer-registration`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          tenantId: config.tenantId,
          tenantBrandBusinessId: config.tenantBrandBusinessId,
          mobile: mobileNumber,
        }),
      }
    ).then(
      () => {
        this.setState({ isGenerating: false, status: statuses.OTP_REQUESTED });
      },
      (err) => {
        this.setState({ isGenerating: false });
        console.error(err);
        throw err;
      }
    );
  };

  moveToOtpVerified(tokenObj) {
    this.setState({
      status: statuses.OTP_VERIFIED,
      otpError: undefined,
      tokenObj: tokenObj,
    });
    sessionService.login(tokenObj);
    this.loadUserLoginStatus();
  }

  moveBackToStep(targetStep) {
    let { activeStep } = this.state;
    if (targetStep < activeStep) {
      this.setState({ activeStep: targetStep });
    }
  }

  renderScreen() {
    const {
      status,
      mobileNumber,
      mobileNumberError,
      isOtpValid,
      activeStep,
      otpError,
      isGetOtpTriggered,
      otp,
      //otp
    } = this.state;
    const isOtpEntered = _.size(otp) === 6;
    const { classes } = this.props;
    const steps = ["Business Information", "Owner Information"];

    switch (status) {
      case statuses.DEFAULT:
        return (
          <>
            <div style={{ textAlign: "center" }}>
              <div className={classes.otpHeader}>
                Customer {this.state.flowHeader}
              </div>
              <TextField
                value={mobileNumber || ""}
                placeholder="Mobile Number"
                type="number"
                error={!!mobileNumberError}
                helperText="OTP will be send on this number"
                style={{
                  fontSize: 12,
                  fontFamily: "Noto Sans",
                  width: "90%",
                  marginTop: 30,
                }}
                onChange={(event) =>
                  this.setState({
                    mobileNumber: event.target.value,
                    mobileNumberError: null,
                    isGetOtpTriggered: false,
                  })
                }
              />
              {mobileNumberError && (
                <div
                  style={{
                    fontSize: 12,
                    color: "red",
                    textAlign: "center",
                    marginTop: 10,
                  }}
                >
                  {mobileNumberError}
                </div>
              )}
              <div style={{ position: "absolute", bottom: 0, width: "100%" }}>
                <Button
                  className={classes.verifyOtp}
                  disabled={isGetOtpTriggered}
                  onClick={async () => {
                    this.setState({ isGetOtpTriggered: true });
                    this.handleGetOtp();
                  }}
                >
                  <b style={{ display: "flex", alignItems: "center" }}>
                    Get OTP
                    {this.state.isGenerating ? (
                      <CircularProgress />
                    ) : (
                      <img src={RightArrow} alt="right" style={{ width: 20 }} />
                    )}
                  </b>
                </Button>
              </div>
            </div>
          </>
        );
      case statuses.OTP_REQUESTED:
        return (
          <>
            <div style={{ textAlign: "center" }}>
              <div className={classes.otpHeader}>OTP Verification</div>
              <div className={classes.text}>
                We have sent 6 digit OTP to your mobile number {mobileNumber}
              </div>
              <div
                style={{
                  marginTop: 50,
                  display: "flex",
                  justifyContent: "center",
                }}
                className={"mx3"}
              >
                <OtpInput
                  numInputs={6}
                  value={otp}
                  isInputNum={true}
                  inputStyle={{
                    width: "95%",
                    marginRight: "1%",
                    fontSize: "2em",
                    border: 0,
                    borderBottom: "2px solid #3f51b5",
                  }}
                  separator={<span>&nbsp;</span>}
                  onChange={(otpString) => {
                    this.setState({ otp: otpString });
                  }}
                />
              </div>
              {otpError && (
                <div
                  style={{
                    fontSize: 12,
                    color: "red",
                    textAlign: "center",
                    marginTop: 10,
                  }}
                >
                  {otpError}
                </div>
              )}
              <div style={{ marginTop: 30 }}>
                {isOtpValid ? (
                  <Timer
                    duration={180}
                    onEnd={() => this.setState({ isOtpValid: false })}
                    classes={classes.timer}
                  />
                ) : (
                  <div
                    className={classes.highlightedText}
                    onClick={() => {
                      this.setState({
                        isGetOtpTriggered: true,
                        isOtpValid: true,
                      });
                      this.handleGetOtp();
                    }}
                  >
                    Resend OTP
                  </div>
                )}
              </div>
              <div
                onClick={() =>
                  this.setState({
                    status: statuses.DEFAULT,
                    isGetOtpTriggered: false,
                  })
                }
                className={classes.highlightedText}
              >
                Change mobile number
              </div>
            </div>
            <Button
              style={{
                ...styles.buttonBottom,
                position: "absolute",
                bottom: 0,
              }}
              disabled={!isOtpEntered}
              onClick={async () => {
                this.setState({ otpError: undefined, tokenObj: undefined });
                fetch(
                  `${config.ruleServerUrl}/flow-otp/verify-for/customer-registration`,
                  {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                      tenantId: config.tenantId,
                      tenantBrandBusinessId: config.tenantBrandBusinessId,
                      mobile: mobileNumber,
                      otp: otp,
                    }),
                  }
                )
                  .then(async (response) => {
                    let body = await response.json();
                    if (response.ok) {
                      this.moveToOtpVerified(body.data);
                    } else {
                      let err = body;
                      console.error(err);
                      this.setState({
                        isOtpValid: false,
                        otpError: "Invalid OTP",
                      });
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                    this.setState({
                      isOtpValid: false,
                      otpError: "Invalid OTP",
                    });
                  });
              }}
            >
              <b style={{ display: "flex", alignItems: "center" }}>
                Verify OTP
                <img src={RightArrow} alt="right" style={{ width: 20 }} />
              </b>
            </Button>
          </>
        );
      case statuses.OTP_VERIFIED:
        return (
          <div>
            <div className={classes.privacyPolicy}>
              <div>
                All information taken is purely for business purposes. Please
                see our
                <span
                  style={{ color: "blue", marginLeft: 5, cursor: "pointer" }}
                  onClick={() => {
                    window.open("/privacy-policy", "_blank");
                  }}
                >
                  Privacy Policy
                </span>
              </div>
            </div>
            <Stepper
              alternativeLabel={true}
              activeStep={activeStep}
              style={{ backgroundColor: "#F6F6F6" }}
              connector={
                <StepConnector
                  classes={{ lineHorizontal: classes.connector }}
                />
              }
              classes={{ root: classes.stepperLayout }}
            >
              {steps.map((label, index) => (
                <Step
                  key={label}
                  classes={{ alternativeLabel: classes.labelSize }}
                >
                  <StepLabel
                    onClick={() => this.moveBackToStep(index)}
                    StepIconComponent={(prop) => (
                      <StepIcon
                        index={index}
                        activeStep={activeStep}
                        {...prop}
                      />
                    )}
                  >
                    <div
                      className={
                        activeStep === index
                          ? classes.stepperLabel
                          : classes.inactiveStepperLabel
                      }
                    >
                      {label}
                    </div>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            {this.renderSteps()}
          </div>
        );
      default:
        return;
    }
  }

  componentDidMount() {
    let params = new URL(document.location).searchParams;
    let flow = params.get("flow");
    this.setState({
      flowHeader: flow === "signin" ? "Sign In" : "Registration",
    });
    this.loadUserLoginStatus(true);
  }

  loadUserLoginStatus(logoutOnFailure = false) {
    sessionService.getUserInfo().then((userInfoFromServer) => {
      if (userInfoFromServer.mobile) {
        this.setState({
          mobileNumber: userInfoFromServer.mobile,
          loggedInUser: userInfoFromServer,
        });
      } else {
        logoutOnFailure && sessionService.logout();
      }
    });
  }

  handleClick = (event) => {
    // setAnchorEl(event.currentTarget);
    this.setState({ ...this.state, anchorEl: event.currentTarget });
  };

  handleClose = () => {
    // setAnchorEl(null);
    this.setState({ ...this.state, anchorEl: null });
  };

  render() {
    const { classes } = this.props;
    const loggedInUser = this.state.loggedInUser;
    const open = Boolean(this.state.anchorEl);
    const id = open ? "simple-popover" : undefined;

    return loggedInUser.hasSignedUp() ? (
      <Redirect to="/" />
    ) : (
      <div className={classes.container}>
        {/* <img src={CustomerRegistrationHeader} alt='headerImage' className={classes.banner}/> */}
        <div className={classes.banner}>
          <div className={classes.bannerContent}>
            <MoreVertIcon
              classes={{ root: classes.moreIconSize }}
              onClick={this.handleClick}
            />
            <Popover
              id={id}
              open={open}
              anchorEl={this.state.anchorEl}
              onClose={this.handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Typography style={{ height: 140, width: 185 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "5px 10px",
                  }}
                >
                  <CloseIcon
                    style={{ cursor: "pointer" }}
                    onClick={this.handleClose}
                  />
                </div>
                <div
                  style={{
                    margin: "10px 0px",
                    borderBottom: "2px solid #E6E7E8",
                  }}
                >
                  <span
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      fontWeight: "bold",
                      cursor: "pointer",
                      marginBottom: 10,
                    }}
                    onClick={() => {
                      window.open("/faq", "_blank");
                    }}
                  >
                    FAQ
                  </span>
                </div>
                {/* <hr style={{background:"#E6E7E8"}}/> */}
                <div style={{ marginTop: 10 }}>
                  <div
                    style={{
                      fontSize: 10,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Contact us
                  </div>
                  <div
                    style={{
                      fontSize: 12,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      color: "#1E9FDA",
                      fontWeight: "bolder",
                    }}
                  >
                    18000 0000 1234 1234
                  </div>
                </div>
              </Typography>
            </Popover>
          </div>
        </div>
        {this.renderScreen()}
      </div>
    );
  }
}

export default withStyles(styles)(CustomerLandingPage);
