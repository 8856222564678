import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import tambolaBackground from '../assets/images/tambola-background.svg';
import Arrow from '../assets/images/tambola-arrow.svg';
import BackArrow from '../assets/images/tambola-back.svg';
import EnabledSound from '../assets/images/enable-sound.svg';
// import DisabledSound from "../assets/images/disable-sound.svg";
// import tambola from "tambola-generator";

const useStyles = makeStyles({
  container: {
    background: `url(${tambolaBackground}) no-repeat`,
    backgroundSize: "cover",
    height: "100vh",
    width: "100vw",
    "@media (min-width:0px) and (max-width:700px)": {
      transform: "rotate(-90deg)",
      transformOrigin: "left top",
      width: "100vh",
      height: "100vw",
      overflowX: "hidden",
      position: "absolute",
      top: "100%",
      left: "0",
    },
  },
  content: {
    background: `url(${Arrow}) no-repeat`,
    height: "100%",
    backgroundSize: "contain",
    color: "white",
  },
  navigate: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 100,
    "@media (min-width:0px) and (max-width:700px)": {
      height: "8vh",
    },
  },
  navigateBack: {
    background: `url(${BackArrow}) no-repeat`,
    height: 30,
    width: 30,
    backgroundSize: "100% 100%",
    marginLeft: 80,
    cursor: "pointer",
  },
  enableSound: {
    background: `url(${EnabledSound}) no-repeat`,
    height: 45,
    width: 40,
    backgroundSize: "100% 100%",
    marginRight: 80,
    cursor: "pointer",
  },
  gameSection: {
    marginTop: 50,
    color: "white",
    display: "flex",
    justifyContent: "space-around",
    "@media (min-width:0px) and (max-width:700px)": {
      marginTop: 0,
    },
  },
  value: {
    fontSize: "39px",
    fontWeight: "bolder",
    "@media (min-width:0px) and (max-width:700px)": {
      fontSize: 20,
    },
  },
  caption: {
    color: "#ffffff7a",
    fontSize: 16,
    "@media (min-width:0px) and (max-width:700px)": {
      fontSize: 14,
    },
  },
  grid: {
    marginTop: 30,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    "@media (min-width:0px) and (max-width:700px)": {
      marginTop: 20,
    },
  },
  section: {
    color: "black",
    height: 60,
    width: 70,
    border: "1px solid #CEB0DF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "white",
    fontSize: 18,
    cursor: "pointer",
    "@media (min-width:0px) and (max-width:700px)": {
      width: "14vw",
      height: "6vh",
      fontSize: 15,
    },
  },
  chosenSection: {
    color: "black",
    height: 60,
    width: 70,
    border: "1px solid #CEB0DF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#bdbdbd",
    fontSize: 18,
    cursor: "not-allowed",
    "@media (min-width:0px) and (max-width:700px)": {
      width: "14vw",
      height: "6vh",
    },
  },
  passedNumberSection: {
    marginTop: 50,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    "@media (min-width:0px) and (max-width:700px)": {
      marginTop: 10,
    },
  },
  tambolaNumbers: {
    height: 58,
    width: 800,
    background: "#00000078",
    marginTop: "20px",
    display: "flex",
    alignItems: "center",
    padding: "0px 25px",
    fontSize: 18,
    "@media (min-width:0px) and (max-width:700px)": {
      marginTop: 10,
      fontSize: 15,
      width: "150vw",
      height: "40px",
    },
    "@media (min-width:760px) and (max-width:1000px)": {
      width: 640,
    },
  },
  alignItems: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
});

const TambolaGrid = (props) => {
  const classes = useStyles();
  let initialSeconds, totalTurns;
  if (props.level === 1) {
    initialSeconds = 7;
    totalTurns = 15;
  } else if (props.level === 2) {
    initialSeconds = 6;
    totalTurns = 18;
  } else if (props.level === 3) {
    initialSeconds = 5;
    totalTurns = 21;
  }

  const [state, setState] = useState({
    currentNumber: null,
    isReset: false,
    numberOfTurns: 0,
    timeRemaining: initialSeconds,
  });

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (state.numberOfTurns <= totalTurns) {
        if (state.timeRemaining > 0)
          setState({ ...state, timeRemaining: state.timeRemaining - 1 });
        if (state.timeRemaining === 1) {
          // if (state.numberOfTurns < totalTurns)
          setState({
            ...state,
            numberOfTurns: state.numberOfTurns + 1,
            timeRemaining: initialSeconds,
          });
          if (state.numberOfTurns === totalTurns) {
            clearInterval(myInterval);
            props.nextPage();
            return;
          }
        }
        if (props.passedNumbers.length === totalTurns) {
          clearInterval(myInterval);
          props.nextPage();
          return;
        }
        if (state.isReset && state.numberOfTurns < totalTurns)
          setState({ ...state, isReset: false, timeRemaining: initialSeconds });
        if (
          props.passedNumbers.length === totalTurns ||
          (state.isReset && state.numberOfTurns === totalTurns)
        ) {
          clearInterval(myInterval);
          props.nextPage();
          return;
        }
      } else {
        clearInterval(myInterval);
        props.nextPage();
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  const generateCurrentNumber = (number) => {
    let nextNumber =
      props.drawSequence[Math.floor(Math.random() * props.drawSequence.length)];
    let sequence = [...props.drawSequence];
    sequence.splice(sequence.indexOf(nextNumber), 1);
    if (number) props.updateDrawSequence(sequence, number);
    else props.updateSequence(sequence);
    if (!state.currentNumber) setState({ ...state, currentNumber: nextNumber });
    else {
      if (state.numberOfTurns === totalTurns) return props.redirect(number);
      else
        setState({
          ...state,
          currentNumber: nextNumber,
          isReset: true,
          numberOfTurns: state.numberOfTurns + 1,
        });
    }
  };

  useEffect(() => {
    if (state.isReset) {
      return;
    }
    if (state.timeRemaining === 0 && state.numberOfTurns < totalTurns) {
      generateCurrentNumber();
    }
  }, [state.timeRemaining]);

  useEffect(generateCurrentNumber, []);

  const showNumber = (number) => {
    if (
      number === state.currentNumber &&
      !props.passedNumbers.includes(number)
    ) {
      if (state.numberOfTurns <= totalTurns) {
        generateCurrentNumber(number);
      }
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.navigate}>
          <div
            className={classes.navigateBack}
            onClick={() => props.previousPage()}
          ></div>
          <div className={classes.enableSound}></div>
        </div>
        <div className={classes.gameSection}>
          <div className={classes.alignItems}>
            <div className={classes.value}>
              {state.currentNumber ? state.currentNumber : null}
            </div>
            <div className={classes.caption}>Current number</div>
          </div>
          <div className={classes.alignItems}>
            <div className={classes.value}>
              {props.passedNumbers.length * 50}
            </div>
            <div className={classes.caption}>Score</div>
          </div>
          <div className={classes.alignItems}>
            <div className={classes.value}>00:0{state.timeRemaining}</div>
            <div className={classes.caption}>Time remaining</div>
          </div>
        </div>
        <div className={classes.grid}>
          <div style={{ display: "flex" }}>
            {props.tambolaTickets.length &&
              props.tambolaTickets[0].map((number) => (
                <div
                  className={
                    !props.passedNumbers.includes(number)
                      ? classes.section
                      : classes.chosenSection
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    showNumber(number);
                  }}
                  style={
                    !number || state.isReset
                      ? { cursor: "not-allowed", pointerEvents: "none" }
                      : null
                  }
                >
                  {number ? number : null}
                </div>
              ))}
          </div>
          <div style={{ display: "flex" }}>
            {props.tambolaTickets.length &&
              props.tambolaTickets[1].map((number) => {
                return (
                  <div
                    className={
                      !props.passedNumbers.includes(number)
                        ? classes.section
                        : classes.chosenSection
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      showNumber(number);
                    }}
                    style={
                      !number || state.isReset
                        ? { cursor: "not-allowed", pointerEvents: "none" }
                        : null
                    }
                  >
                    {number ? number : null}
                  </div>
                );
              })}
          </div>
          <div style={{ display: "flex" }}>
            {props.tambolaTickets.length &&
              props.tambolaTickets[2].map((number) => (
                <div
                  className={
                    !props.passedNumbers.includes(number)
                      ? classes.section
                      : classes.chosenSection
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    showNumber(number);
                  }}
                  style={
                    !number || state.isReset
                      ? { cursor: "not-allowed", pointerEvents: "none" }
                      : null
                  }
                >
                  {number ? number : null}
                </div>
              ))}
          </div>
        </div>
        <div className={classes.passedNumberSection}>
          <div>
            <div className={classes.caption} style={{ fontSize: 15 }}>
              Passed numbers
            </div>
            <div className={classes.tambolaNumbers}>
              {props.passedNumbers.map((number) => (
                <span>{number},</span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TambolaGrid;
