import config from "../config";
import axios from "axios";
import sessionService from '../services/session-service';

async function saveSurveyAnswers(data) {
  const options = {
    method: 'POST',
    url: `${config.ruleServerUrl}/dalmia/answers/${sessionService.getUserToken()}`,
    data
  };
  await axios.request(options).then(function(response) {
    console.log(response.data);
  }).catch(function (error) {
    console.error(error);
  });
}

async function awardPoints() {
  const opt = {
    method: 'POST',
    url: `${config.ruleServerUrl}/dalmia/award/${sessionService.getUserToken()}`,
    data: {
      source: "SURVEY",
      points: 500
    }
  };
  await axios.request(opt).then(function(response) {
    console.log(response.data);
  }).catch(function (error) {
    console.error(error);
  });
}

async function getAllQuestions () {
  return await fetch(`${config.ruleServerUrl}/dalmia/questions/${sessionService.getUserToken()}`, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'}
  }).then(res => res.json());
}

export default {
  saveSurveyAnswers,
  awardPoints,
  getAllQuestions
}
