import React from 'react';
import Grid from '@material-ui/core/Grid';
import SteelIcon from '../assets/images/steel.jpg';
import AutomationImage from '../assets/images/automation.jpg';
import BathroomFittingsImage from '../assets/images/bathroomFittings.jpg';
import ElectricalImage from '../assets/images/electricals.jpg';
import HardwareImage from '../assets/images/hardware.jpg';
import KitchenImage from '../assets/images/kitchens.jpg';
import LightsImage from '../assets/images/lights.jpg';
import PaintsImage from '../assets/images/paint.jpg';
import PipesImage from '../assets/images/pipes.jpg';
import TilesImage from '../assets/images/tiles.jpg';
import WoodsImage from '../assets/images/woods.jpg';
import CementImage from '../assets/images/cement.jpg';
import DownArrowIcon from '../assets/icons/arrow-down.svg';
import BarChartIcon from '../assets/icons/bar-chart.svg';
import LanguageIcon from '../assets/icons/language.svg';
import LoggedInUserIcon from '../assets/icons/logged-in-user.svg';
import FastDeliveryIcon from '../assets/icons/fast-delivery.svg';
import DescriptionBackground from '../assets/images/Mask-Group-39.png';
import PaymentIcon from '../assets/icons/payment.svg';
import PriceIcon from '../assets/icons/price.svg';
import SmartphoneIcon from '../assets/icons/smartphone.svg';
import SupportIcon from '../assets/icons/support.svg';
import WarehouseIcon from '../assets/icons/warehouse.svg';
import RightArrowIcon from '../assets/icons/arrow-right-home.svg';
import OrangeRightArrowIcon from '../assets/icons/arrow-right-orange.svg';
import DalmiaLogo from '../assets/images/dalmia_logo.jpg';
import Button from '@material-ui/core/Button';
import CustomerInterest from '../components/customer-interest-component';
import _ from 'lodash';
import styles from '../styles/BackgroundVideo.module.css';
import flipCardImage from '../assets/images/orange flip card.png';
import {withStyles} from '@material-ui/core';
import Awards from './awards';
import sessionService from '../services/session-service'

const useStyles = {
  card: {
    width: 191,
    height: 202,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    margin: "10px 0px",
    cursor: "pointer",
    "@media (min-width:0px) and (max-width:700px)": {
      width: 142,
      height: 150,
      margin: "6px 3px",
    },
  },
  flipCard: {
    height: "91%",
    width: 208,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px",
    cursor: "pointer",
    "@media (min-width:0px) and (max-width:700px)": {
      marginTop: "6px",
      height: "92%",
      width: 155,
    },
    "@media (min-width:700px) and (max-width:1000px)": {
      width: 208,
    },
    "@media (min-width:1000px) and (max-width:1150px)": {
      width: 206,
    },
  },
  cardContainer: {
    width: '95%',
    margin: "70px auto",
    // marginTop: 300,
    "@media (min-width:700px) and (max-width:800px)": {
      width: "90%",
      margin: "50px auto",
      // marginTop: 560,
    },
    "@media (min-width:0px) and (max-width:600px)": {
      width: "90%",
      // marginTop: 20,
    },    
  },
  heroTitle: {
    textAlign: "center",
    font: "normal normal bold 20px/27px Noto Sans",
    color: "#151415",
    opacity: 1,
    fontSize: 30,
    "@media (min-width:0px) and (max-width:700px)": {
      marginTop: 20,
      fontSize: 20,
    },
  },
  heroCaption: {
    textAlign: "center",
    font: '24px / 30px "Noto Sans"',
    color: "rgb(21, 20, 21)",
    opacity: 1,
    width: "547px",
    margin: "25px auto",
    "@media (min-width:0px) and (max-width:700px)": {
      font: '16px / 20px "Noto Sans"',
      width: "100%",
      margin: "15px auto",
    },
  },
  description: {
    width: "90%",
    margin: "0 auto",
    marginTop: 60,
    "@media (min-width:0px) and (max-width:700px)": {
      textAlign: "center",
    },
  },
  descriptionLine: {
    height: 4,
    borderWidth: 0,
    color: "#F47E1F",
    backgroundColor: "#F47E1F",
    width: 100,
    "@media (min-width:0px) and (max-width:700px)": {
      margin: "auto",
    },
  },
  flipCardAlignment: {
    margin: 10,
    marginLeft: "25px",
    marginTop: "25px",
    "@media (min-width:0px) and (max-width:700px)": {
      marginLeft: 10,
      marginTop: 10,
    },
  },
  cardTitle: {
    color: "white",
    textAlign: "center",
    font: "normal normal bold 21px/24px Noto Sans",
    paddingTop: 48,
    width: "85%",
    margin: "0 auto",
    "@media (min-width:0px) and (max-width:700px)": {
      paddingTop: 10,
      font: "normal normal bold 17px/24px Noto Sans",
      fontSize: 14,
      lineHeight: 'normal'
    },
  },
  showSubCategories: {
    color: "white",
    textAlign: "center",
    font: "normal normal normal 14px/17px Noto Sans",
    width: "90%",
    margin: "0 auto",
    paddingTop: 40,
    "@media (min-width:0px) and (max-width:700px)": {
      font: "normal normal bold 12px/24px Noto Sans",
      paddingTop: 10,
    },
  },
  signUp: {
    backgroundColor: "#F6F6F6",
    color: "black",
    marginTop: "1em",
    display: "flex",
    flexDirection: "row",
    fontWeight: "bold",
    height: "3em",
    paddingRight: "0.5em",
    paddingLeft: "1em",
    borderRadius: "0px",
    alignItems: "center",
    width: "6em",
    justifyContent: "center",
    cursor: "pointer",
    "@media (min-width:0px) and (max-width:700px)": {
      height: "2em",
      width: "6.5em",
    },
    "@media (min-width:700px) and (max-width:1000px)": {
      height: "2em",
    },
  },
  flipCardTitle: {
    color: "white",
    font: "normal normal bold 20px/22px Noto Sans",
    "@media (min-width:0px) and (max-width:700px)": {
      font: "normal normal bold 16px/22px Noto Sans",
    },
    "@media (min-width:700px) and (max-width:1000px)": {
      font: "normal normal bold 18px/22px Noto Sans",
    },
  },
  flipCardCaption: {
    fontSize: "14px",
    fontFamily: "Noto Sans",
    "@media (min-width:0px) and (max-width:700px)": {
      fontSize: "12px",
    },
    "@media (min-width:700px) and (max-width:1000px)": {
      fontSize: "13px",
    },
  },
  descriptionContainer: {
    height: 750,
    background: `url(${DescriptionBackground}) no-repeat`,
    backgroundPosition: "bottom",
    backgroundSize: "contain",
    padding: "0px 30px",
    "@media (min-width:0px) and (max-width:700px)": {
      height: 2050,
      padding: 0,
    },
    "@media (min-width:700px) and (max-width:1000px)": {
      height: 1050,
    },
  },
};

const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
};

class CustomerHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: null,
      isLoggedIn: sessionService.isLoggedIn(),
      lastScroll: 1,
      videoCacheBurst: `${_.uniqueId('sf')}_${(new Date()).valueOf()}`
    };
    this.videoParentRef = React.createRef();
  }

  setScrollPosition = (position) => {
    this.setState({ lastScroll: position });
  };

  handleEvent = (e) => {
    let { lastScroll } = this.state;

    let st = window.pageYOffset || document.documentElement.scrollTop;
    if (st > lastScroll) {
      lastScroll = lastScroll - 1 < 0 ? lastScroll + 5 : lastScroll - 1;
      this.setScrollPosition(lastScroll);
    } else {
      lastScroll = lastScroll + 1 > 5 ? lastScroll - 5 : lastScroll + 1;
      this.setScrollPosition(lastScroll);
    }
  };

  autoplayVideo() {
    let videoParentRef = this.videoParentRef;
    if (isSafari() && this.videoParentRef.current) {
      // obtain reference to the video element
      const player = videoParentRef.current.children[0];

      // if the reference to video player has been obtained
      if (player) {
        // set the video attributes using javascript as per the
        // webkit Policy
        player.controls = false;
        player.playsinline = true;
        player.muted = true;
        player.setAttribute("muted", ""); // leave no stones unturned :)
        player.autoplay = true;

        // Let's wait for an event loop tick and be async.
        setTimeout(() => {
          // player.play() might return a promise but it's not guaranteed crossbrowser.
          const promise = player.play();
          // let's play safe to ensure that if we do have a promise
          if (promise.then) {
            promise
                .then(() => {})
                .catch(() => {
                  // if promise fails, hide the video and fallback to <img> tag
                });
          }
        }, 0);
      }
    }
  }

  componentDidMount() {

    this.setState({isLoggedIn: sessionService.isLoggedIn()})
    const element = document.getElementById("scrollableComponent");
    if (element) {
      element.addEventListener(
        "scroll",
        _.throttle(this.handleEvent, 900),
        false
      );
    }
    this.autoplayVideo();
    setTimeout(() => this.autoplayVideo(), 100)
  }

  render() {
    const types = [
      {
        label: "Cement",
        icon: CementImage,
        // style: { paddingTop: 50 },
        subCategories: ["33 OPC", "43 OPC", "53 OPC", "PPC", "White Cement"],
      },
      {
        label: "Steel",
        icon: SteelIcon,
        // style: { paddingTop: 50 },
        subCategories: ["FE 415", "FE 500", "FE 550", "I-Beam", "Tees"],
      },
      {
        label: "Sanitary Ware & Bath Fittings",
        icon: BathroomFittingsImage,
        style: { paddingTop: 15 },
        subCategories: ["Water Closets", "Wash Basins", "Bathtubs"],
      },
      {
        label: "Pipes and Fittings",
        icon: PipesImage,
        style: { paddingTop: 15 },
        subCategories: [
          "CPVC Pipe and Fittings",
          "PPR Pipe and Fittings",
          "PVC Pipe and Fittings",
        ],
      },
      {
        label: "Hardware",
        icon: HardwareImage,
        // style: { paddingTop: 50 },
        subCategories: [
          "Architectural Hardware",
          "Furniture Hardware",
          "Kitchen Hardware",
          "Locks and Handles",
        ],
      },
      {
        label: "Paints",
        icon: PaintsImage,
        // style: { paddingTop: 50 },
        subCategories: [
          "Interiors",
          "Exteriors",
          "Undercoats",
          "Woodcoats",
          "Water Proofing",
        ],
      },
      {
        label: "Electricals",
        icon: ElectricalImage,
        // style: { paddingTop: 50 },
        subCategories: [
          "Wires",
          "Cables",
          "Switchgear",
          "Switches",
          "Earthing",
        ],
      },
      {
        label: "Lights",
        icon: LightsImage,
        // style: { paddingTop: 50 },
        subCategories: [
          "Panels",
          "Downlights",
          "Blubs",
          "Batons",
          "LED Strips",
        ],
      },
      {
        label: "Tiles",
        icon: TilesImage,
        // style: { paddingTop: 50 },
        subCategories: [
          "Ceramics",
          "Vetrified",
          "Vetrified Body",
          "Glazed VT",
          "Tile Adhesives",
        ],
      },
      {
        label: "Wood",
        icon: WoodsImage,
        // style: { paddingTop: 50 },
        subCategories: ["Plywood", "MDF", "HDF", "Laminates", "Veneers"],
      },
      {
        label: "Home Automation",
        icon: AutomationImage,
        // style: { paddingTop: 30 },
        subCategories: ["Security", "Device Control", "Lighting", "Power Line"],
      },
      {
        label: "Modular Kitchens",
        icon: KitchenImage,
        // style: { paddingTop: 30 },
        subCategories: [],
      },
    ];
    const descriptionSection = [
      {
        label: "Grow Your Business",
        icon: BarChartIcon,
        description:
          "Grow your business by adding more categories and brands to your store without carrying any additional inventory. Our 100,000 sq ft warehouse will service all your requirements. Buy small lots, more often and increase your ROI.",
      },
      {
        label: "Earn More Profits",
        icon: PaymentIcon,
        description:
          "No need to block money in brand schemes which make you buy more than you can sell. We will aggregate all your small purchases and give you further discounts. Now buy only what your customer wants and and earn more.",
      },
      {
        label: "Everyday Wholesale Pricing",
        icon: PriceIcon,
        description:
          "Get consistent wholesale pricing throughout the year. No more checking of prices every time you need to buy. Check the app and you know the best price.",
      },
      {
        label: "Always Available",
        icon: WarehouseIcon,
        description:
          "No more calls to check availabiity. Our app on your phone will give you the visibility of stocks. Sell and Order as per availability. Even for out of stock items we provide estimated delivery timelines.",
      },
      {
        label: "On Time Delivery",
        icon: FastDeliveryIcon,
        description:
          "We deliver within 24 hours. For paints and electrical products if order is placed before 12 pm you can expect same day delivery.",
      },
      {
        label: "Digitally Connected",
        icon: SmartphoneIcon,
        description:
          "No need to make multiple calls to vendors. Check prices, Check availability, Order Products and Track Delivery. All on the phone. You focus on selling and customer service while we manage the rest.",
      },
      {
        label: "At Your Service",
        icon: SupportIcon,
        description:
          "Get a dedicated sales associate to service all your needs. Reach out to our call center for any queries and follow-ups.",
      },
    ];
    const scrollableContent = [
      { label: "Brands", value: "150+" },
      { label: "Categories", value: "14" },
      { label: "Sub Categories", value: "240" },
      { label: "SKUs In Store", value: "12K" },
      { label: "Sq Ft Store", value: "50K" },
      { label: "SKU's", value: "50K" },
    ];
    const { toggle } = this.state;
    // note: when changing video source, ensure it works on mac, ubuntu, iphone (6s) also
    // const videoSource = require('../assets/videos/customer_banner_video_v2.mp4') + (isSafari() ? `?cb=${this.state.videoCacheBurst}` : '');
    const videoSource = null
    const { classes } = this.props;

    return (
      <>
        <div
          style={{
            height: 80,
            display: "flex",
            backgroundColor: "#F6F6F6",
            alignItems: "center",
          }}
        >
          <div style={{ flex: 8 }}>
            <img
              alt={"DalmiaLogo"}
              src={DalmiaLogo}
              style={{ width: 100, marginLeft: 20 }}
            />
          </div>
          <img
            alt={"language"}
            src={LanguageIcon}
            style={{ marginRight: 30 }}
          />

          {
            this.state.isLoggedIn ? <img
                alt={'language'}
                src={LoggedInUserIcon}
                className={'mr3'}
            /> : <Button
                style={{
                  backgroundColor: '#F47E1F',
                  color: 'white',
                  display: 'flex',
                  flexDirection: 'row',
                  marginRight: 20,
                }}
                onClick={() => {
                  window.location = '/customer?flow=signin';
                }}
            >
              Sign In
              <img
                  alt={'right'}
                  src={RightArrowIcon}
                  style={{marginLeft: 10}}
              />
            </Button>
          }
        </div>
        <div className={styles.Container}>
          <div
              className={styles.Video}
              ref={this.videoParentRef}
              dangerouslySetInnerHTML={{
                __html: `
        <video
          loop
          muted
          autoplay
          playsinline
          preload="metadata"
          class="${styles.Video}"
        >
            <source src="${videoSource}" type="video/mp4" />
            Your browser does not support the video tag.
          </video>`
              }}
          />

          <div className={styles.Content}>
            <div className={styles.SubContent}>
              <h1 className={'mt1'}>India's First</h1>
              <p>All Under One Roof Building Material Store</p>
              <div style={{ fontSize: 36, marginBottom: 20 }}>Opening Soon</div>
              {
                !this.state.isLoggedIn && <div
                className={classes.signUp}
                onClick={() => {
                  window.location = "/customer";
                }}
              >
                SIGN UP
                <img
                  src={OrangeRightArrowIcon}
                  style={{
                    height: "1em",
                    paddingLeft: "0.6em",
                    width: "1em",
                    verticalAlign: "center",
                  }}
                  alt="right"
                />
              </div>
              }
            </div>
          </div>
        </div>
          {this.state.isLoggedIn && <Awards/>}
        <div className={classes.cardContainer}>
          <div className={classes.heroTitle} style={{marginTop:10}}>
            All Kinds of Building Material Under One Roof
          </div>
          <div className={classes.heroCaption}>
            Buy all your requirements from one place. Choose from 12 categories
          </div>
          <div style={{ width: "95%", margin: "0 auto" }}>
            <Grid container>
              {types.map((type, index) =>
                toggle !== index ? (
                  <Grid
                    xs={6}
                    md={4}
                    lg={3}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{backgroundImage: `url(${type.icon})`}}
                      className={classes.card}
                      onClick={() => this.setState({ toggle: index })}
                    >
                      <div
                        style={{
                          height: "100%",
                          width: "100%",
                          background: "#00000072",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            ...type.style,
                          }}
                          className={classes.cardTitle}
                        >
                          {type.label}
                        </div>
                        <div className={classes.showSubCategories}>
                          Show Sub-categories
                          <div style={{ paddingTop: 5 }}>
                            <img
                              alt={"show sub catogries"}
                              src={DownArrowIcon}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                ) : (
                  <Grid
                    xs={6}
                    sm={4}
                    lg={3}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItem: "center",
                    }}
                  >
                    <div
                      className={classes.flipCard}
                      onClick={() => this.setState({ toggle: null })}
                    >
                      <div
                        style={{
                          width: "92%",
                          height: "100%",
                          backgroundSize: "100% 100%",
                          backgroundImage: `url(${flipCardImage})`,
                        }}
                      >
                        <div className={classes.flipCardAlignment}>
                          <div className={classes.flipCardTitle}>
                            {type.label}
                          </div>
                          <div style={{ paddingTop: 10 }}>
                            {type.subCategories.map((subCategory) => (
                              <div className={classes.flipCardCaption}>
                                {subCategory}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                )
              )}
            </Grid>
          </div>
        </div>
        {!this.state.isLoggedIn && <div>
          <CustomerInterest/>
        </div>}
          <Grid container style={{ margin: "50px 0px" }}>
            {scrollableContent.map((content) => (
              <Grid lg={2} sm={6} xs={6}>
                <div style={{ marginBottom: 50, marginTop: 50 }}>
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: 45,
                      fontFamily: "Noto Sans",
                      color: "#1EA0DA",
                      textAlign: "center",
                    }}
                  >
                    {content.value}
                  </div>
                  <div
                    style={{
                      fontSize: 24,
                      fontFamily: "Noto Sans",
                      textAlign: "center",
                      marginTop: 10,
                    }}
                  >
                    {content.label}
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        <div
          style={{
            backgroundColor: "#F6F6F6",
            paddingTop: 20,
          }}
        >
          <div className={classes.descriptionContainer}>
            <Grid container xs={24}>
              {descriptionSection.map((section) => (
                <Grid xs={12} lg={3} sm={6} md={4}>
                  <div className={classes.description}>
                    <img
                      alt={"description"}
                      src={section.icon}
                      style={{ width: 50, height: 50 }}
                    />
                    <div
                      style={{
                        fontFamily: "Noto Sans",
                        fontWeight: "bold",
                        fontSize: 16,
                        marginTop: 20,
                        marginBottom: 20,
                      }}
                    >
                      {section.label}
                    </div>
                    <hr className={classes.descriptionLine} />
                    <div
                      style={{
                        fontFamily: "Noto Sans",
                        fontSize: 14,
                        marginTop: 15,
                        color: "#939597",
                      }}
                    >
                      {section.description}
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
        {!this.state.isLoggedIn && <div>
          <CustomerInterest/>
        </div>}
        <div
          style={{
            height: 70,
            width: "100%",
            backgroundColor: "rgb(246, 246, 246)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 14,
          }}
        >
          © 2020 Dalmia Bharat Group All Rights Reserved
        </div>
      </>
    );
  }
}

export default withStyles(useStyles)(CustomerHome);
