import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { withTheme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Drawer from "@material-ui/core/Drawer";
import { compose } from "../util/functional-util";
import ListItem from "@material-ui/core/ListItem";
import DalmiaLogo from "../assets/images/dalmia_logo.jpg";

const useNavStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  appBar: {
    position: "sticky",
    backgroundColor: "#f9f9f9 !important",
    width: "100%",
    zIndex: 2,
  },
  navIcon: {
    color: "#3c3599",
  },
  settingsButton: {
    marginRight: -18,
  },
  title: {
    fontSize: "1.125rem",
    color: "#3c3599",
    fontWeight: 800,
    flexGrow: 1,
  },
  list: {
    height: "100vh",
    marginTop: "3.5rem",
    width: 250,
  },
});

const NavBar = (props) => {
  const [settingsDrawer, setSettingsDrawer] = useState(props.open);
  const classes = useNavStyles();

  return (
    <div className={classes.root}>
      <AppBar position="absolute" className={classes.appBar}>
        <Toolbar>
          {/*<img src={MenuIcon} alt="Menu" onClick={() => {setSettingsDrawer(true)}} style={{width: 20}}/>*/}
          <img
            src={DalmiaLogo}
            alt="Logo"
            style={{ width: 100, margin: "0 auto" }}
          />
          <div style={{ color: "black" }}></div>
        </Toolbar>
      </AppBar>
      <Drawer
        style={{ position: "relative", zIndex: 1 }}
        onClose={() => setSettingsDrawer(false)}
        open={settingsDrawer}
        anchor="right"
      >
        <div tabIndex={0} role="button" style={{ outline: "none" }}>
          <div className={classes.list} style={{ backgroundColor: "#FFF" }}>
            <ListItem>
              <div onClick={() => props.logout()}>Logout</div>
            </ListItem>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default compose(withTheme, withRouter)(NavBar);
