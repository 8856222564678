import React, { useState, useEffect } from "react";
import StartTambola from "./start-game";
import TambolaGrid from "./tambola-grid";
import GameOver from "./game-over";
import _ from "lodash";
import tambola from "tambola-generator";
import UserService from "../services/user-service";

const Tambola = () => {
  const [state, setState] = useState({
    pageNumber: 1,
    passedNumbers: [],
    level: 3,
    drawSequence: [],
    tambolaTickets: [],
  });
  let referenceState = _.cloneDeep(state);

  const generateRandomNumber = (ticket) => {
    let number = Math.floor(Math.random() * 90) + 1;
    let checkRandomNumber = ticket.some((ticketList) =>
      ticketList.includes(number)
    );
    if (!checkRandomNumber) {
      return number;
    } else {
      return generateRandomNumber(ticket);
    }
  };

  function addAdditionalValues(ticket, level) {
    if (level === 1) return ticket;
    ticket = ticket.map((ticketList) => {
      let index = ticketList.findIndex((number) => number === 0);
      ticketList[index] = generateRandomNumber(ticket);
      return ticketList;
    });
    return addAdditionalValues(ticket, level - 1);
  }

  const generateTickets = (level = 1) => {
    let ticket = tambola.getTickets(1).flat();
    return addAdditionalValues(ticket, level);
  };

  useEffect(() => {
    async function getPoints() {
      const data = await UserService.hasPoints('GAME');
      const level = (data && data.level) || 1;
      let tambolaTickets = generateTickets(level);
      setState({
        ...state,
        data,
        level: level,
        tambolaTickets: tambolaTickets,
        drawSequence: tambolaTickets.flat().filter((ticket) => ticket !== 0),
      });
      return data;
    }
    getPoints();
  }, []);

  const nextPage = () => {
    setState({ ...state, pageNumber: referenceState.pageNumber + 1 });
  };
  const playAgain = async () => {
    await UserService.spendPoints();
    window.location.reload();
  };
  const setPassedNumbers = (number) => {
    setState({
      ...state,
      passedNumbers: [...referenceState.passedNumbers, number],
    });
  };

  const previousPage = () => {
    const { pageNumber } = state;
    if (pageNumber > 1)
      setState({ ...state, pageNumber: referenceState.pageNumber - 1 });
  };

  const redirect = (number) => {
    setState({
      ...state,
      passedNumbers: [...referenceState.passedNumbers, number],
      pageNumber: referenceState.pageNumber + 1,
    });
  };

  async function savePoints() {
    const { passedNumbers, data } = state;
    const body = {
      source: "GAME",
      points: passedNumbers.length * 50,
      actionId: data.actionId,
    };
    await UserService.awardPointsFor(body);
  }

  const startGame = () => {
    const { data } = state;
    return (
      <StartTambola
        nextPage={nextPage}
        hasGameToPlay={data && !!data.hasAction}
      />
    );
  };

  const updateDrawSequence = (updatedSequence, number) => {
    setState({
      ...state,
      drawSequence: updatedSequence,
      passedNumbers: [...referenceState.passedNumbers, number],
    });
  };

  const updateSequence = (sequence) => {
    setState({
      ...state,
      drawSequence: sequence,
    });
  };
  const renderGame = () => {
    return (
      <TambolaGrid
        previousPage={previousPage}
        nextPage={nextPage}
        setPassedNumbers={(number) => setPassedNumbers(number)}
        passedNumbers={state.passedNumbers}
        drawSequence={state.drawSequence}
        tambolaTickets={state.tambolaTickets}
        redirect={(number) => redirect(number)}
        level={state.level}
        updateDrawSequence={(updatedSequence, number) =>
          updateDrawSequence(updatedSequence, number)
        }
        updateSequence={(sequence) => updateSequence(sequence)}
      />
    );
  };
  const endGame = () => {
    return (
      <GameOver
        playAgain={playAgain}
        passedNumbers={state.passedNumbers}
        savePoints={() => savePoints()}
        level={state.level}
      />
    );
  };
  const renderTambola = () => {
    const { pageNumber } = state;
    switch (pageNumber) {
      case 1:
        return startGame();
      case 2:
        return renderGame();
      case 3:
        return endGame();
      default:
        return;
    }
  };
  return renderTambola();
};

export default Tambola;
