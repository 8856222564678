module.exports = {
    ruleServerUrl: 'https://api.casa.ajira.tech',
    tenantId: 91,
    tenantBrandBusinessId: 73,
    ruleUiUrl: 'https://rules.casa.ajira.tech',
    beatServerUrl: 'https://beat.casa.ajira.tech',
    hippostoresUrl: 'https://www.hippostores.com',
    GATrackingCode: 'UA-202989810-2',
    gridlinesURL: 'https://api.gridlines.io',
    beatApiNodeUrl: "https://beat-api.casa.ajira.tech",
    gridlinesAPIKey: "QS81N8T493tY8M7wu5kEKSzEcMq8CFIL"
};
