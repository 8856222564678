import config from "../config";
import sessionService from './session-service';

async function spendPoints(points = 50, spentOn = 'GAME') {
  const response = await fetch(`${config.ruleServerUrl}/dalmia/spend/${sessionService.getUserToken()}`, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({ points, spentOn })
  }).then(res => res.status);
  return response === 200;
}

async function hasPoints(type) {
  return await fetch(`${config.ruleServerUrl}/dalmia/action/${sessionService.getUserToken()}/${type}`, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'}
  }).then(res => res.json());
}

async function awardPointsFor(data) {
  return await fetch(`${config.ruleServerUrl}/dalmia/action/${sessionService.getUserToken()}`, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(data)
  }).then(res => res.json());
}

async function getProxyToken(beatBusinessId, salesPersonToken) {
  return await fetch(`${config.ruleServerUrl}/dalmia/proxy-token`, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({
      beatBusinessId,
      salesPersonToken
    })
  }).then(res => res.json());
}

export default {
  hasPoints,
  awardPointsFor,
  spendPoints,
  getProxyToken
}
