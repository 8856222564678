import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import surveyImage from "../assets/images/Mask-Group-16.svg";
import userService from '../services/user-service';

const styles = {
  wrapper: {
    height: 230,
    width: 470,
    background: `#FFF6C2`,
    display: "flex",
    justifyContent: "space-between",
    "@media (min-width:0px) and (max-width:700px)": {
      width: "88%",
      height: 196,
    },
    boxShadow: "0px 10px 13px -6px rgba(0,0,0,0.75)",
  },
  surveyBackground: {
    background: `url(${surveyImage}) no-repeat`,
    width: "100%",
    height: "220px",
    backgroundSize: "100% 90%",
    "@media (min-width:0px) and (max-width:700px)": {
      width: "95%",
      backgroundSize: "contain",
      height: 184,
    },
  },
  earnSection: {
    paddingLeft: 40,
    paddingTop: 40,
    width: 180,
    "@media (min-width:0px) and (max-width:700px)": {
      width: 80,
    },
  },
  earn: {
    color: "black",
    fontWeight: "bolder",
    fontSize: 25,
    "@media (min-width:0px) and (max-width:700px)": {
      fontSize: 20,
    },
  },
  unlock: {
    color: "#02DCFF",
    fontWeight: "bolder",
    fontSize: 25,
    "@media (min-width:0px) and (max-width:700px)": {
      fontSize: 20,
    },
  },
};

class GameZone extends Component {
  render() {
    const { classes } = this.props;

    const onClickHandler  = async () => {
      await userService.spendPoints(50, 'SURVEY');
      window.location = '/survey';
    };

    return (
      <div
        style={{
          margin: "25px 0px",
          display: "flex",
          justifyContent: "center",
        }}
        onClick={onClickHandler}
      >
        <div className={classes.wrapper}>
          <div className={classes.surveyBackground}>
            <div className={classes.earnSection}>
              <div className={classes.earn}>EARN 50 POINTS&</div>
              <div className={classes.unlock}>UNLOCK LEVEL-1</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(GameZone);
