import React from 'react';
import config from '../config';
import {inputStyles} from '../styles/input-styles';
import withStyles from '@material-ui/styles/es/withStyles';
import ReferralComponent from '../components/referral-component';
import UploadInvoiceComponent from '../components/upload-invoice-component';
import sessionService from '../services/session-service';
import LeaderBoard from '../components/LeaderBoard';
import GameZone from '../components/GameZone';
import Survey from '../components/Survey';
import _ from 'lodash';

class Awards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: sessionService.isLoggedIn(),
      totalPoints: null,
      invoicePoints: null,
      referralPoints: null,
      invoiceCount: null,
      referralCount: null,
    };
  }

  componentDidMount = async () => {
    return sessionService.getUserInfo()
        .then((user) => {
          this.setState({mobile: _.get(user, 'mobile')}, () => {
            this.getPoints();
          })
        })
  };

  async getPoints() {
    const response = await fetch(
      `${config.ruleServerUrl}/dalmia/points/${sessionService.getUserToken()}`
    );
    const data = await response.json();
    const { total, invoices, referrals, stats: pointStats, pointsAvailable, game } = data;
    this.setState({
      totalPoints: total,
      invoicePoints: invoices.points,
      referralPoints: referrals.points,
      invoiceCount: invoices.count,
      referralCount: referrals.count,
      game,
      pointsAvailable,
      pointStats
    });
  }

  logout = () => {
    sessionService.logout();
    this.setState({
      isLoggedIn: false,
      mobile: undefined
    })
  };

  render() {
    const {
      invoicePoints,
      referralPoints,
      invoiceCount,
      referralCount,
      game,
      pointsAvailable
    } = this.state;
    return (
      <div>
        <div className={'mt2'}>
          <LeaderBoard pointStats={this.state.pointStats} />
          <div className="sm-col sm-col-12 md-col-6 lg-col-6">
            <UploadInvoiceComponent
              mobileNumber={this.state.mobile}
              points={invoicePoints}
              count={invoiceCount}
              getPoints={() => this.getPoints()}
            />
          </div>
          <div className="sm-col sm-col-12 md-col-6 lg-col-6">
            <div style={{}}>
              <ReferralComponent
                mobileNumber={this.state.mobile}
                points={referralPoints}
                count={referralCount}
                getPoints={() => this.getPoints()}
              />
            </div>
          </div>
          <div className="sm-col sm-col-12 md-col-6 lg-col-6">
            {pointsAvailable >= 50 && <GameZone points={game} />}
          </div>
          <div className="sm-col sm-col-12 md-col-6 lg-col-6">
            {pointsAvailable >= 50 && <Survey />}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(inputStyles)(Awards);
