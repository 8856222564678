import React, { Component } from 'react';

export default class PrivacyPolicy extends Component {
  render() {
    return (
      <div style={{background:"#F6F6F6"}}>
      <div style={{textAlign: 'center', fontFamily: 'Montserrat', width: '80%', margin: '0 auto'}}>
        <div style={{fontSize: 16,padding:"30px 0px", fontWeight: 900}}>Privacy Policy</div>
        <div style={{textAlign: 'justify', paddingBottom: 100, fontSize: 14}}>
          <p>
            <b>Dalmia Cement (Bharat) Limited (“DCBL” or “We” or “us”)</b> recognizes the importance of maintaining your privacy. We value your privacy and appreciate your trust in us. This Privacy Policy describes how we treat your information we collect on our website _________________________ and other offline sources. This Privacy Policy applies to all information that we have about you and your account. This website is a property of Dalmia Cement (Bharat) Limited, an Indian Company under the meaning of the Companies Act, 2013 having its registered office at Dalmiapuram – 621651, District- Tiruchirapalli, Tamil Nadu.
          </p>
          <p>By entering this website, you give your consent to the terms of this Privacy Policy. By submitting your Information to us, you will be treated as having given your permission for processing the same in a manner provided in this Privacy Policy. If you do not agree to or wish to be bound by the terms and conditions of this Privacy Policy, please do not proceed further to use this website. </p>
          <b>1. What We Collect</b>
          <ul style={{listStyleType: 'disc'}}>
            <li>Information you give us (such as your Name, Mobile Number, Email Address, Date of Birth, Date of Anniversary, Marital Status, Gender, Telephone no., IP Address, Delivery Address, Payment Mode, other payment instrument details)</li>
            <li>Information we collect when you contact us, visit our website, use our applications or services. We may combine any and all of this information to help create better products, services, and customer experiences.</li>
            <li>Demographic information about you, events you like, events you intend to participate in, products you browse, or any other information provided by your during the use of our website. We might collect this as a part of a survey also.</li>
            <li>Information about your IP address and the browser you’re using.</li>
          </ul>
          <b>2. How We Use Your Information</b>
          <ul style={{listStyleType: 'disc'}}>
            <li>Send you the products and services you ask for</li>
            <li>Processing your order, corresponding with you, fulfilling your transaction requests</li>
            <li>Contacting you via surveys to conduct research about your opinion</li>
            <li>Tell you about our and our marketing partners’ products and services</li>
            <li>We may combine any and all of your information to help create better products, services, and customer experiences</li>
            <li>Help us run our sites and services</li>
          </ul>
          <b>3. How, When and with Whom We Share Your Information</b>
          <ul style={{listStyleType: 'disc'}}>
            <li>With our carefully selected partners so that they can send you offers, promotions, or ads about their products and services we believe you may be interested in</li>
            <li>With other companies we hire to help us to provide you services wherein we may share your name, address, mobile number and email address for optimum services</li>
            <li>As part of amalgamation, takeover, assignment, novation of the brand or business to another company</li>
            <li>To help us prevent fraud or information security breach</li>
            <li>When required by law or government authorities</li>
          </ul>
          <b>4. How We Safeguard Your Information</b>
          <ul style={{listStyleType: 'disc'}}>
            <li>We respect your personal information and take appropriate steps to safeguard it from loss, destruction, misuse, unauthorised access or alteration</li>
            <li>We seek to ensure compliance with the requirements of the prevailing laws to ensure the protection and preservation of personal information</li>
            <li>We have a number of physical, electronic, and procedural safeguards/measures in place to actively protect the confidentiality, security, and integrity of your personal information</li>
            <li>We have managerial, technical, operational and physical security control measures for protecting your personal information</li>
            <li>Where appropriate, these steps can include technical measures including but not limited to firewalls, prevention systems and encryption</li>
          </ul>
          <b>5. Consent</b>
          <p style={{marginLeft: 24}}>By using or continuing to use our website/services you agree that:</p>
          <ul style={{listStyleType: 'disc'}}>
            <li>We use your personal information (including your sensitive personal data or information) in accordance with this Privacy Policy</li>
            <li>You have given the consent freely and voluntarily and agree to this Privacy Policy</li>
            <li>You consented us for collecting, storing, processing, transferring and sharing your personal information with third parties or service providers for the purposes as set out in this Privacy Policy</li>
            <li>We may also be required to share the aforesaid information with government authorities and agencies for the purposes of verification of identity or for prevention, detection or investigation, including of cyber incidents, prosecution and punishment of offences</li>
            <li>You consent to disclose your personal information, if so required, under applicable laws</li>
          </ul>
          <b>6. Right of Correction, Modification and Erasure</b>
          <ul style={{listStyleType: 'disc'}}>
            <li>It is important that the information that we hold about you is accurate and current. Please keep us informed if your personal information changes during your association with us. This will help us to provide you better services</li>
            <li>You have a right to ask us to modify, update, correct or delete your personal information available with us. In case you wish to do that you may connect with on given email-id.</li>
          </ul>
          <b>7. Retention, Processing and storage of Information</b>
          <ul style={{listStyleType: 'disc'}}>
            <li>We shall retain Information for only as long as necessary to meet legal or regulatory requirements or for legitimate business purposes as mentioned in this Privacy Policy.</li>
            <li>We have implemented required security practices and standards in line with the global standards which contains managerial, technical, operational and physical security control measures that commensurate with the information assets being protected with us. It is being reviewed periodically to keep pace with business, technology and regulatory changes.</li>
          </ul>
          <b>8. Email Opt-Out</b>
          <p style={{marginLeft: 24}}>You can opt out of receiving our marketing emails. To stop receiving our promotional emails, please email to us on given email id. It may take about ten days to process your request. Even if you opt out of getting marketing messages, we will still be sending you transactional messages through email and SMS about your purchases.</p>
          <b>9.Third party sites</b>
          <p style={{marginLeft: 24}}>If you click on one of the links to third party websites, you may be taken to websites we do not control. This Privacy Policy does not apply to the privacy practices of those websites. Read the privacy policy of other websites carefully. We are not responsible for these third party sites.</p>
          <b>10. Cookies</b>
          <ul style={{listStyleType: 'disc'}}>
            <li>Cookies are small files sent to your computer as you surf the web.  They store useful information about how you interact with the websites you visit. Cookies do not collect any information stored on your computer or device or in your files. Cookies do not contain any information that would directly identify you as a person</li>
            <li>
              <p>We use cookies for a number of reasons, such as:</p>
              <ol>
                <li>to serve you with relevant advertising</li>
                <li>to learn more about the way you interact with Dalmia’s content</li>
                <li>help us improve your experience when visiting our website</li>
                <li>to remember your preferences</li>
                <li>to identify errors and resolve them</li>
                <li>to analyse how well our websites is performing</li>
              </ol>
              <p>You can set your browser to refuse all cookies or to indicate when a cookie is being sent to your computer. However, this may prevent our site or services from working properly. You can also set your browser to delete cookies every time you finish browsing.</p>
            </li>
          </ul>
          <b>11. Processing and Retention</b>
          <p style={{marginLeft: 24}}>As a general rule, we store your data for only as long as it is needed to complete the purpose for which it was collected or as required by law. However, we may need to store your data for longer period to honour your future requests and to comply with legal or other obligations</p>
          <b>12. Updates and Modifications of Terms of Privacy Policy</b>
          <p style={{marginLeft: 24}}>We reserve the right to update, change, alter or modify this Privacy Policy, from time to time, without any prior notification to you. Such change shall come into effect from the date of such update, change, alteration or modification. Please check our site periodically for updates.</p>
          <b>13. Disclaimer</b>
          <p style={{marginLeft: 24}}>We are not responsible for any breach of security or for any actions of any third parties that receive your Information. The website also linked to many other sites and we are not/shall be not responsible for their privacy policies or practices as it is beyond our control. Notwithstanding anything contained in this Privacy Policy or elsewhere, we shall not be held responsible for any loss, damage or misuse of your information, if such loss, damage or misuse is attributable to a force majeure event. We are not responsible for delay or failure to perform any of its obligations beyond its control.</p>
          <b>14. Jurisdiction</b>
          <p style={{marginLeft: 24}}>If you choose to visit the website, your visit and any dispute over privacy is subject to this Privacy Policy and the website’s terms of use. In addition to the foregoing, any disputes arising under this Privacy Policy shall be governed by the laws of India.</p>
          <b>15. Grievance Officer</b>
          <p style={{marginLeft: 24}}>In accordance with Information Technology Act 2000 and rules made there under, the name and contact details of the Grievance Officer are provided below:</p>
          <p style={{marginLeft: 24}}>
            11th & 12th Floor, Hansalaya <br />
            15, Barakhamba Road, <br />
            New Delhi- 110001 <br />
          </p>
          <p style={{marginLeft: 24}}>Please contact us directly with any questions or concerns you may have about your personal information and our data protection practices</p>
          <p style={{textAlign: 'center', marginTop: 20}}>------</p>
        </div>
      </div>
      </div>
    );
  }

  onError(e) {
    console.error(e, 'error in file-viewer');
  }
}
