import React from 'react';
import ReferralBackground from '../assets/images/referral-background.png';
import TextField from "@material-ui/core/TextField";
import {withStyles} from "@material-ui/styles";
import ArrowRightIcon from '../assets/icons/arrow-right-home.svg';
import Button from "@material-ui/core/Button";
import config from "../config";
import PopUpBackground from "../assets/images/popup-background.svg";
import ThumbsUp from "../assets/icons/thumbs-up.svg";
import Modal from "@material-ui/core/Modal";
import ReferralCountBackground from '../assets/images/referral-count-background.png';
import ReferralCountIcon from '../assets/icons/referral-count.svg';
import ReferralPointsIcon from '../assets/icons/referral-points.svg';
import sessionService from '../services/session-service';

const CssTextField = withStyles({
  root: {
    '& label': {
      color: 'white',
      opacity: 0.51
    },
    '& label.Mui-focused': {
      color: '#131313',
      opacity: 0.32
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
      opacity: 0.51
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: 'white',
      opacity: 0.51
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'white',
        opacity: 0.51
      }
    },
    '& .MuiInputBase-input': {
      color: 'white',
      fontSize: 11,
      fontWeight: 700,
      fontFamily: 'Noto Sans TC'
    }
  },
})(TextField);

const styles={
  wrapper:{
    display: 'flex',
    flexDirection: 'row',
    width: 470,
    height: 230,
    margin: '0 auto',
    background: `url(${ReferralBackground}) no-repeat`,
    backgroundSize:"100% 100%",
    marginTop:"-57px",
    boxShadow: "0px 10px 13px -6px rgba(0,0,0,0.75)",
    "@media (min-width:0px) and (max-width:700px)": {
      marginTop:0,
      width: '90%',
      height:270
    },
  },
  coinSection:{
    background: `url(${ReferralCountBackground}) no-repeat`,
    width: 450,
    margin: '0 auto',
    marginTop: 30,
    "@media (min-width:0px) and (max-width:700px)": {
      width: '90%'
    },
  },
  field:{
    width:"90%",
    "@media (min-width:0px) and (max-width:700px)": {
      width: "100%",
    },
  }

}

class ReferralComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      mobile: ''
    }
  }

  handleReferral = async () => {
    const {mobile, name} = this.state;

    if(!name) {
      this.setState({nameError: 'Please check the number entered!'});
      return;
    }

    if(!mobile) {
      this.setState({mobileError: 'Please enter a mobile number!'});
      return;
    }

    if(mobile.length !== 10) {
      this.setState({mobileError: 'Please check the number entered!'});
      return;
    }

    if(mobile === this.props.mobileNumber) {
      this.setState({mobileError: 'You cannot refer yourself!'});
      return;
    }

    const rawResponse = await fetch(`${config.ruleServerUrl}/dalmia/referral`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        token: sessionService.getUserToken(),
        invitedNumber: mobile,
        invitedName: name
      })
    });
    const response = await rawResponse.json();
    if(rawResponse.status !== 200) {
      response.error = response.message || rawResponse.statusText;
    }
    this.setState({isSuccess: rawResponse.ok, ...response, mobileError: response.error});
  };

  render() {
    const {name, mobile, nameError, mobileError, isSuccess, error} = this.state;
    const {points, count, classes} = this.props;
    return (
      <div>
        <div className={classes.coinSection}>
          <div style={{padding: 10, display: 'flex', flexDirection: 'row'}}>
            <div style={{flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'center'}}>
              <div style={{flex: 1}}>
                <img src={ReferralCountIcon} alt='icon' />
              </div>
              <div style={{flex: 10, marginLeft: 10}}>
                <div style={{fontWeight: 700, fontSize: 18, fontFamily: 'Noto Sans', color: 'white'}}>{count}</div>
                <div style={{fontSize: 10, fontFamily: 'Noto Sans'}}>Your total referrals</div>
              </div>
            </div>
            <div style={{flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'center'}}>
              <div style={{flex: 1}}>
                <img src={ReferralPointsIcon} alt='icon' />
              </div>
              <div style={{flex: 10, marginLeft: 10}}>
                <div style={{fontWeight: 700, fontSize: 18, fontFamily: 'Noto Sans', color: 'white'}}>{points}</div>
                <div style={{fontSize: 10, fontFamily: 'Noto Sans'}}>Your total points</div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.wrapper}>
          <div style={{flex: 3}} />
          <div style={{flex: 4, margin: 10, marginTop: 40}}>
            <div style={{marginBottom: 5}}>
              <div style={{fontSize: 16, fontWeight: 700, fontFamily: 'Noto Sans', color: 'white'}}>Hurry Up!</div>
              <div style={{fontSize: 11, fontWeight: 700, fontFamily: 'Noto Sans TC', color: '#005074'}}>Refer more businesses & Earn points</div>
              <div style={{fontSize: 8, fontFamily: 'Noto Sans', color: '#131313'}}>For each referral, you will get 10 points</div>
            </div>
            <div className="sm-col sm-col-6 md-col-6 lg-col-6">
              <CssTextField
                placeholder="Name"
                label="Name"
                value={name}
                error={nameError}
                helperText={<div style={{fontSize: 8}}>{nameError}</div>}
                className={classes.field}
                onChange={(e) => {this.setState({name: e.target.value, nameError: null})}}
              />
            </div>
            <div className="sm-col sm-col-6 md-col-6 lg-col-6">
              <CssTextField
                placeholder="Mobile Number"
                label="Mobile Number"
                value={mobile}
                  type={'number'}
                error={mobileError}
                helperText={<div style={{fontSize: 8}}>{mobileError}</div>}
                style={{ width: '100%'}}
                onChange={(e) => {this.setState({mobile: e.target.value, mobileError: null})}}
              />
            </div>
            <Button style={{backgroundColor: '#F47E1F', marginTop: 15}} onClick={() => this.handleReferral()}>
              <span style={{marginLeft: 5, color: 'white', fontSize: 11, fontFamily: 'Noto Sans', fontWeight: 700, textTransform: 'uppercase'}}>Refer Now</span>
              <img src={ArrowRightIcon} alt='view' style={{width: 11, marginLeft: 5}}/>
            </Button>
            <Modal open={isSuccess} onClose={() => this.setState({showModal: false})}>
              <div style={{fontFamily: 'Nunito', backgroundColor: 'white', margin: '0 auto', marginTop: 100, width: '80%', padding: 20, textAlign: 'center', backgroundImage: `url(${PopUpBackground}`}}>
                {!error && <img src={ThumbsUp} style={{marginTop: 10}} alt=''/>}
                <div style={{textAlign: 'center', fontSize: 26, fontWeight: 700, fontFamily: 'Noto Sans', color: '#F47F22'}}>Thank you</div>
                <div style={{fontWeight: 700, fontSize: 14}}>For Referring!</div>
                {
                  !error && (
                    <div style={{textAlign: 'center', marginTop: 10, marginBottom: 20}}>
                      <div style={{color: '#1EA0DA', fontSize: 29, fontWeight: 900, fontFamily: 'Noto Sans'}}>10</div>
                      <div style={{fontWeight: 700, fontSize: 14}}><b>Points Earned</b></div>
                      <div style={{color: '#9F9F9F', fontWeight: 300, fontSize: 14, marginTop: 15}}>Refer more businesses to
                        earn more Points
                      </div>
                    </div>
                  )
                }
                {
                  error && <div style={{fontWeight: 700, fontSize: 14, margin: 10, marginTop: 30, marginBottom: 30}}><b>{error}!</b></div>
                }
                <Button style={{ backgroundColor: '#F47E1F', color: 'white'}}
                        onClick={async () => {
                          this.setState({isSuccess: false, name: '', mobile: '', error: null});
                          await this.props.getPoints();
                        }}
                >
                  Close
                </Button>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(ReferralComponent)
