import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import classes from '../styles/survey-form.module.css';
import survey_img from '../assets/images/Group 3976.svg';
import good_img from '../assets/images/005-happy-1.svg';
import fine_img from '../assets/images/003-neutral.svg';
import bad_img from '../assets/images/001-sad.svg';
import NavBar from "../components/navbar-component";
import surveyService from '../services/survey-service';
import PopUpBackground from "../assets/images/popup-background.svg";
import ThumbsUp from "../assets/icons/thumbs-up.svg";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import UserService from "../services/user-service";


//edit emoji images here
const emoji = {
	'Fine': fine_img,
	'Good': good_img,
	'Bad': bad_img,
};

const CustomRadioInput = (props) => {

	const { id, questionText, questionOptions, questionType } =  props.data;

	const containsEmoji = questionType!=="options";

	const onChangeHandler = (text,id) => {
		let data = props.responses;
		let idx = _.findIndex(data, function(d){return d.questionId===id});
		data[idx].answer = text;
		props.change(data);
	};

	return (
		<div className={classes.radioContainer}>
			<div style={{fontWeight: 'bold'}}>{questionText}</div>
			<div className={containsEmoji?classes.rowRadioContainer:classes.rowWrapper}>
				{
					questionOptions.map((opt)=>(
						<div
							key={opt}
							className={containsEmoji?classes.optionsColContainer:classes.optionsRowContainer}
						>
							<input
								type="radio"
								name={questionText}
								id={opt}
								value={opt}
								onChange={()=>{onChangeHandler(opt, id)}}
								className={classes.radio}
								style={{
									marginRight: containsEmoji?"0":"2.5vw",
									marginTop: containsEmoji?"2.5vw":"0"
								}}
							/>
							{
								containsEmoji
								?
								(<img
									className={classes.emoji} src={emoji[opt]}
									alt=""
								/>)
								:
								(<label style={{fontWeight:'light'}} for={opt}>{opt}</label>)
							}
						</div>
					))
				}
			</div>
		</div>
	)
};

const SurveyForm = () => {
	//question and options array [question, array_of_answers:[image, value], containsEmoji, function to set answer]
	const [ err, setErr ] = useState("");
	const [ questions, setQuestions ] = useState([]);
	const [ responses, setResponses ] = useState({});
	const [ error, setError ] = useState(false);
	const [ openModal, setOpenModal ] = useState(false);
	const [ state, setState ] = useState({});

	async function getPoints() {
		const data = await UserService.hasPoints('SURVEY');
		setState(data);
	}

	useEffect(() => {
		getPoints();
		fetch_q_and_a();
	},[]);

	const fetch_q_and_a = async () => {
		const questions = await surveyService.getAllQuestions();
		console.log(questions);
		let ids = _.map(questions, 'id');
		setResponses(Array.from(ids.map(id=>({ questionId:id, answer:"" }))));
		setQuestions(questions);
	};

	const onSubmitHandler = async () => {
		const { actionId } = state || {};
		if(_.filter(responses, (r)=>_.isEmpty(r.answer)).length)
			setErr("Please answer all the questions");
		else {
			setErr("");
			try {
				const requestBody = {
					data: responses,
					actionId: actionId || null
				};
				await surveyService.saveSurveyAnswers(requestBody);
				setOpenModal(true);
			} catch (e) {
				setError(true);
			}
		}
	};

	return (
		<div style={{backgroundColor: "#F6F6F6", overflow:"hidden", height:"100%"}}>
			<NavBar/>
			<div className={classes.surveyFormContainer}>
				<div className={classes.surveyImg}>
					<img className={classes.img} src={survey_img} alt=""/>
					<div class={classes.surveyTextContainer}>
						<div
							style={{color:"#003255"}}
							className={classes.surveyText}
						>
							TAKE SURVEY
						</div>
						<div className={classes.takePointsContainer}>
							<div className={classes.surveyText}>10X YOUR&nbsp;</div>
							<div className={classes.surveyText}>POINTS</div>
						</div>
					</div>
				</div>
				{ state['hasAction'] ? (
					<div>
						{
							_.map(questions,(data)=>(
								<CustomRadioInput key={data.id} data={data} responses={responses} change={setResponses}/>
							))
						}
						<div className={classes.err}>{err}</div>
						<div>
							<button className={classes.btn} onClick={onSubmitHandler}>SUBMIT</button>
						</div>
					</div>
				) : (
					<div className={classes.err}>You do not have enough points to take a Survey. Earn more points by Uploading Invoices or by Referring a Friend.</div>
				)}
			</div>
			<Modal open={openModal} onClose={() => setOpenModal( false )}>
				<div style={{fontFamily: 'Nunito', backgroundColor: 'white', margin: '0 auto', marginTop: 100, width: '80%', padding: 20, textAlign: 'center', backgroundImage: `url(${PopUpBackground}`}}>
					{!error && <img src={ThumbsUp} style={{marginTop: 10}} alt=''/>}
					<div style={{textAlign: 'center', fontSize: 26, fontWeight: 700, fontFamily: 'Noto Sans', color: '#F47F22'}}>Thank you</div>
					<div style={{fontWeight: 700, fontSize: 14}}>For Taking Survey!</div>
					{
						!error && (
							<div style={{textAlign: 'center', marginTop: 10, marginBottom: 20}}>
								<div style={{color: '#1EA0DA', fontSize: 29, fontWeight: 900, fontFamily: 'Noto Sans'}}>500</div>
								<div style={{fontWeight: 700, fontSize: 14}}><b>Points Earned</b></div>
								<div style={{color: '#9F9F9F', fontWeight: 300, fontSize: 14, marginTop: 15}}>
									Earn more Points to take another survey
								</div>
							</div>
						)
					}
					<Button style={{ backgroundColor: '#F47E1F', color: 'white'}}
									onClick={() => { window.location = '/' }}>
						Close
					</Button>
				</div>
			</Modal>
		</div>
	);
};

export default SurveyForm;
