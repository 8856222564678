import _ from "lodash";

const data = {
  Cement: {
    subCategories: ["33 OPC", "43 OPC", "53 OPC", "PPC", "White Cement"],
    brands: [
      "UltraTech",
      "Shree",
      "Ambuja",
      "ACC",
      "J K Super",
      "J K Lakshmi",
      "Wonder Cement",
      "Birla Uttam",
      "Birla Chetak",
      "Rockstrong-Shree",
      "Bangur-Shree",
      "Ambuja Roof Plus",
      "Ultratech Super",
      "Ambuja Roof Plus",
      "ACC Gold",
      "JK Lakshmi Pro",
      "Shree Roof Own",
    ],
  },
  Steel: {
    subCategories: [
      "FE 415",
      "FE 500",
      "FE 550",
      "I-Beam",
      "Tees",
      "Angles",
      "Flats",
      "Tubes",
    ],
    brands: ["Tata", "Jindal", "Rathi", "Kamdhenu", "Other"],
  },
  "Sanitary Ware": {
    subCategories: ["Water Closets", "Wash Basins", "Bathtubs", "Jacuzzis"],
    brands: [
      "Jaquar",
      "Kohler",
      "Grohe",
      "American Standard",
      "Queo",
      "Alchemy",
      "Hindware",
      "Benelave",
      "Parryware",
      "Cera",
      "Ess Ess Asian Paints",
      "Delta",
      "Artize",
      "Essco",
      "ROCA",
      "Jhonson",
      "Glocera",
      "Soncera",
      "Simpolo",
      "Kerovit",
      "Somany",
      "Varmora",
      "RAK",
      "Duravit",
      "Hans Grohe",
      "TOTO",
      "Vitra",
      "Gebrit",
      "Plumber",
      "Bathline",
      "Prayag",
      "JAL",
      "Morbi Range",
    ],
  },
  "Bath Fittings & Accessories": {
    subCategories: [
      "Faucets",
      "CP Fittings",
      "Shower Panels",
      "Shower Cubicles",
      "Bath Accessories",
    ],
    brands: [
      "Jaquar",
      "Kohler",
      "Grohe",
      "American Standard",
      "Queo",
      "Alchemy",
      "Hindware",
      "Benelave",
      "Parryware",
      "Cera",
      "Ess Ess Asian Paints",
      "Delta",
      "Artize",
      "Essco",
      "ROCA",
      "Jhonson",
      "Glocera",
      "Soncera",
      "Simpolo",
      "Kerovit",
      "Somany",
      "Varmora",
      "RAK",
      "Duravit",
      "Hans Grohe",
      "TOTO",
      "Vitra",
      "Gebrit",
      "Plumber",
      "Bathline",
      "Prayag",
      "JAL",
      "Morbi Range",
    ],
  },
  "Plumbing Pipes and Fitings": {
    subCategories: [
      "CPVC Pipe and Fittings",
      "PPR Pipe and Fittings",
      "PVC Pipe and Fittings",
      "UPVC Pipe and Fittings",
      "Solvents and Adhesives",
    ],
    brands: [
      "Supreme polytubes pvt ltd.",
      "Astral polytechnic ltd.",
      "Prince pipping system",
      "Finolex Industries",
      "Jain irrigation System Ltd",
      "Ashirvad",
      "Ajay pipes",
      "Hindware tru flo",
      "Birla Aircon",
      "Appolo pipe",
    ],
  },
  Electricals: {
    subCategories: [
      "Wires",
      "Cables",
      "Switchgear",
      "Switches",
      "Earthing",
      "Tools and Accessories",
    ],
    brands: [
      "Polycab",
      "Finolex",
      "KEI",
      "Havells",
      "RR Cable",
      "Daimond",
      "Uniflex",
      "V Guard",
      "Universal Cable",
      "Fybros",
      "Anchor",
      "Great White",
      "Kalinga",
      "Schnieder",
      "Hager",
      "L&T",
      "Schnieder",
      "C&S",
      "Havells",
      "Anchor",
      "Great White",
      "Polycab",
      "Finolex",
      "RR Cable",
      "Legrand",
      "Orient Electric",
      "ABB",
      "Wipro",
      "Eaton",
      "BCH Electric",
      "Havells",
      "LeGrand",
      "Anchor",
      "Schnieder",
      "Siemens",
      "GM Modular",
      "Wipro North West",
      "Orpat",
      "Norisys",
      "Veto",
      "Great White",
      "Crabtree",
      "Reo",
      "Gold Medal",
      "Gaurav",
      "Diamond",
      "Shubham",
      "Kona",
      "Girish",
      "Orient",
      "Crompton",
      "Usha",
      "Bajaj",
      "Havells",
      "Standard",
      "Surya",
    ],
  },
  Lighting: {
    subCategories: [
      "Panels",
      "Downlights",
      "Blubs",
      "Batons",
      "LED Strips",
      "Commercial",
    ],
    brands: [
      "Phillips",
      "Orient",
      "Havells",
      "Bajaj",
      "EverReady",
      "Syska",
      "Crompton",
      "Surya Roshini",
      "Wipro Lighting",
      "Anchor",
      "Great White",
      "LeadAdvance",
      "Compact",
      "Gaurav",
      "Diamond",
      "Shubham",
      "Kona",
      "Fybros",
      "Girish",
      "Oreva",
      "Opple",
      "IB LED",
      "CORVI",
    ],
  },
  Tiles: {
    subCategories: [
      "Ceramics",
      "Vetrified",
      "Vetrified Body",
      "Glazed VT",
      "Tile Adhesives",
      "Grouts",
    ],
    brands: [
      "Kajaria",
      "Somany",
      "Jhonson",
      "Orient Bell",
      "AGL",
      "RAK",
      "Simpolo",
      "Varmora",
      "SunHearrt",
      "Nitco",
      "Cera",
      "Vitrosa",
      "Pergo",
      "Action Tesa",
      "Green Laminates",
      "BVG",
      "Span Floors",
      "Welspun",
    ],
  },
  Stones: {
    subCategories: [
      "Artificial Marble",
      "Quartz",
      "Natural Marble",
      "Granites",
    ],
    brands: ["CeasarStone", "AGL", "Jhonson", "BABA", "Cambria", "Kalinga"],
  },
  Plywood: {
    subCategories: ["Plywood", "MDF", "HDF", "Laminates", "Veneers", "Doors"],
    brands: [
      "Century",
      "GreenPly",
      "Green Panel",
      "Duro",
      "Merino",
      "Bhutan Tuff",
      "KitPly",
      "UniPly",
      "MayurPly",
      "ArchidPly",
      "Century",
      "Action Tesa",
      "Green Panel",
      "GreenPly",
      "Duro",
      "Sonear",
      "ArchidPly",
      "Century",
      "RamaPly",
      "MayurPly",
      "Merino",
      "GreenLam",
      "Century",
      "Sonear",
      "ArchidPly",
      "NewMika",
      "Silicon",
      "GreenTouch",
      "Aica Sunmica",
    ],
  },
  Hardware: {
    subCategories: [
      "Architectural Hardware",
      "Furniture Hardware",
      "Kitchen Hardware",
      "Locks and Handles",
      "Glass Hardware",
      "Tools",
    ],
    brands: [
      "Dorset",
      "IPSA",
      "Dorma",
      "Yale",
      "Quba",
      "Godrej",
      "Harrison",
      "Hafele",
      "Other",
      "Hettich",
      "Hafele",
      "Ebco",
      "Inox",
      "Quba",
      "Ozone",
      "Moda",
      "Other",
      "Ozone",
      "Enox",
      "Hardwyn",
      "Other",
      "Bosch",
      "Stanley",
      "Taparia",
      "Makita",
      "Black and Decker",
      "Cumi",
      "Dewalt",
    ],
  },
  Paints: {
    subCategories: [
      "Interiors",
      "Exteriors",
      "Undercoats",
      "Woodcoats",
      "Water Proofing",
      "Ceiling Solutions",
      "Ancillaries",
    ],
    brands: [
      "Asian Paints",
      "Kansai Nerolac",
      "Berger",
      "Shalimar",
      "Jotun",
      "Nippon",
      "Indigo",
      "AkzoNobel",
      "British Paints",
      "Other",
      "Asian Paints",
      "Kansai Nerolac",
      "Berger",
      "Jivanjor",
      "MRF",
      "ICA",
      "SIRCA",
      "Nippon",
      "Wembley",
      "Other",
      "Pidilite",
      "Sika",
      "FosRox",
      "Siko",
      "BASF",
      "Asian Paints",
      "Other",
      "Saint Gobain Giproc",
      "Laffarge Boral",
      "Everest",
      "Hilux",
      "Armstrong",
      "Other",
      "Sakarni",
      "Saint Gobain",
      "Wall Plast",
      "Shree Nirman",
      "Hi tech",
      "Adhar Shree",
      "Vasundhara",
    ],
  },
  "Modular Kitchens": {
    subCategories: ["Full Service", "Only Hardware", "Sinks"],
    brands: [],
  },
  "Home Automation": {
    subCategories: ["Security", "Device Control", "Lighting", "Power Line"],
    brands: ["Havells", "Schnieder", "Cubical Labs", "Build Track", "Wretro"],
  },
  // "Other Category": {
  //   subCategories: [],
  //   brands: [],
  // },
};

const contractorData = {
  type: [
    { id: "1", value: "Builder" },
    { id: "2", value: "General Contractor" },
    { id: "3", value: "Interior Contractor" },
    { id: "4", value: "Commercial Contractor" },
    { id: "5", value: "Industrial Contractor" },
    { id: "6", value: "Government Contractor" },
    { id: "7", value: "Applicator" },
  ],
  BussinesInfo: {
    projectDetails: [
      "No. of Projects Per Year",
      "Value Per Project(Lakhs)",
      "Material Share Per Project(%)",
      "Of the last 10 projects how many were Material + Labour contracts ? (max 10)",
    ],
    chances: [
      "Least Likely",
      "Less Likely",
      "Neutral",
      "Likely",
      "Most Likely",
    ],
  },
  applicatorType: [
    {
      id: 19,
      name: "Paint Contractor",
      loyaltyPrograms: [
        "Asian Paints",
        "Berger",
        "Nerolac",
        "Shalimar",
        "Sirca",
        "abc",
      ],
    },
    {
      id: 20,
      name: "POP Contactor",
      loyaltyPrograms: [
        "Asian Paints",
        "Berger",
        "Nerolac",
        "Shalimar",
        "Sirca",
        "abc",
      ],
    },
    {
      id: 21,
      name: "False Ceiling Contractor",
      loyaltyPrograms: [
        "Asian Paints",
        "Berger",
        "Nerolac",
        "Shalimar",
        "Sirca",
        "abc",
      ],
    },
    {
      id: 22,
      name: "Carpenter",
      loyaltyPrograms: [
        "Asian Paints",
        "Berger",
        "Nerolac",
        "Shalimar",
        "Sirca",
        "abc",
      ],
    },
    {
      id: 23,
      name: "Electrical Contractor",
      loyaltyPrograms: [
        "Asian Paints",
        "Berger",
        "Nerolac",
        "Shalimar",
        "Sirca",
        "abc",
      ],
    },
    {
      id: 24,
      name: "Plumbing Contractor",
      loyaltyPrograms: [
        "Asian Paints",
        "Berger",
        "Nerolac",
        "Shalimar",
        "Sirca",
        "abc",
      ],
    },
    {
      id: 25,
      name: "Tile Contractor",
      loyaltyPrograms: [
        "Asian Paints",
        "Berger",
        "Nerolac",
        "Shalimar",
        "Sirca",
        "abc",
      ],
    },
    {
      id: 26,
      name: "Stone Contractor",
      loyaltyPrograms: [
        "Asian Paints",
        "Berger",
        "Nerolac",
        "Shalimar",
        "Sirca",
        "abc",
      ],
    },
    {
      id: 26,
      name: "Structural (Mason)",
      loyaltyPrograms: [
        "Asian Paints",
        "Berger",
        "Nerolac",
        "Shalimar",
        "Sirca",
        "abc",
      ],
    },
    {
      id: 27,
      name: "Stone Polish Contractor",
      loyaltyPrograms: [
        "Asian Paints",
        "Berger",
        "Nerolac",
        "Shalimar",
        "Sirca",
        "abc",
      ],
    },
    { id: 28, name: "Other Category" },
  ],
};

export const getContractorType = () => {
  return contractorData["type"] || [];
};

export const getBusinessProjects = () => {
  return contractorData["BussinesInfo"]["projectDetails"] || [];
};

export const getBusinessChances = () => {
  return contractorData["BussinesInfo"]["chances"] || [];
};

export const getSubCategories = (category) => {
  const subCategories = (data[category] && data[category].subCategories) || [];
  return _.sortBy(_.uniq(subCategories));
};

export const getBrands = (category) => {
  const brands = (data[category] && data[category].brands) || [];
  return _.sortBy(_.uniq(brands));
};

export const getApplicator = () => {
  return contractorData["applicatorType"] || [];
};
