import {theme} from "./custom-theme-style";

export const inputStyles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: 200
  },
  cssLabel: {
    color : theme.palette.primary1Color
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${theme.palette.primary1Color} !important`,
    }
  },
  cssFocused: {},
  notchedOutline: {
    borderWidth: '1px',
    borderColor: `${theme.palette.primary1Color} !important`
  },
  floatingLabelFocusStyle: {
    color: `${theme.palette.primary1Color} !important`
  }
});
