import React from 'react';
import InvoiceBackground from '../assets/images/invoice-background.png';
import Button from '@material-ui/core/Button';
import UploadIcon from '../assets/icons/upload.svg';
import Modal from '@material-ui/core/Modal';
import PopUpBackground from '../assets/images/popup-background.svg';
import ThumbsUp from '../assets/icons/thumbs-up.svg';
import config from '../config';
import {withStyles} from '@material-ui/styles';
import sessionService from '../services/session-service';

const styles={
    container:{
      display: 'flex',
      flexDirection: 'row',
      width: 470,
      margin: '0 auto',
      background: `url(${InvoiceBackground}) no-repeat`,
      backgroundSize: "cover",
      height: "229px",
      boxShadow: "0px 10px 13px -6px rgba(0,0,0,0.75)",
      "@media (min-width:0px) and (max-width:700px)":{
        width:"90%",
        backgroundSize:"101% 100%",
        height: 'auto',
      },
    }
}

class UploadInvoiceComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      user: null,
      invoiceCount: 0
    }
  }

  componentDidMount() {
    const user = localStorage.getItem('user');
    if(user) {
      this.setState({user: JSON.parse(user)});
      return;
    }
    fetch(`${config.ruleServerUrl}/users`, {
      "method": "GET",
      "credentials": "include"
    })
      .then(res => res.json())
      .then(res => {
        this.setState({user: !res.ok ? null : res});
        localStorage.setItem('user', JSON.stringify(res));
      })
      .catch(err => console.log(err))
  }

  handleFileSelection = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();

    formData.append('file', file);
    formData.append('token', sessionService.getUserToken());
    fetch(`${config.ruleServerUrl}/dalmia/upload`, {
      method: 'POST',
      body: formData
    }).then(res => res.json())
      .then(() => this.setState({openModal: true}));
  };

  handleUploadInvoice = () => {
    const element = document.createElement('input');
    element.type = 'file';
    element.name = 'file';
    element.accept = 'application/pdf,image/x-png,image/gif,image/jpeg,image/jpg';
    element.enctype="multipart/form-data";
    element.onchange = (e) => this.handleFileSelection(e);
    element.click();
  };

  render() {
    const {openModal} = this.state;
    const {points, classes} = this.props;

    return (
      <div style={{marginTop: 30}}>
        <div style={{width: '99%', margin: '0 auto'}}>
          <div>
            <div className={classes.container}>
              <div style={{flex: 3, padding: 20, paddingTop: 20}}>
                <div style={{fontWeight: 900, fontSize: 16, fontFamily: 'Noto Sans TC', textTransform: 'uppercase'}}>{points ? `You Earned: ${points} pts.` : 'To earn points'}</div>
                <div style={{fontWeight: 900, fontSize: 14, fontFamily: 'Noto Sans TC', letterSpacing: 0, color: '#FFFFFF', textShadow: '0px 1px 2px #00000029', marginTop: 10}}>Upload Invoices</div>
                <div style={{fontSize: 9, fontFamily: 'Noto Sans', color: '#131313'}}>For each invoice upload, you will get 10 points</div>
                <Button
                  style={{backgroundColor: '#FFFFFF', boxShadow: '0px 0px 3px #00000029', borderRadius: 0, marginTop: 10, resizeMode: 'noRepeat'}}
                  onClick={() => this.handleUploadInvoice()}
                >
                  <div style={{display: 'flex', flexDirection: 'row', marginLeft: 10, marginRight: 10}}>
                    <div style={{fontWeight: 'bold', fontSize: 11, fontFamily: 'Noto Sans', color: '#F47E1F', textTransform: 'uppercase', marginRight: 5}}>Upload Invoice</div>
                    <img src={UploadIcon}  alt='upload'/>
                  </div>
                </Button>
              </div>
              <div style={{flex: 2}}/>
            </div>
          </div>
          <Modal open={openModal} onClose={() => this.setState({showModal: false})}>
            <div style={{fontFamily: 'Nunito', backgroundColor: 'white', margin: '0 auto', marginTop: 100, width: '80%', padding: 20, textAlign: 'center', backgroundImage: `url(${PopUpBackground}`}}>
              <img src={ThumbsUp} style={{marginTop: 10}} alt=''/>
              <div style={{textAlign: 'center', fontSize: 26, fontWeight: 700, fontFamily: 'Noto Sans', color: '#F47F22'}}>Thank you</div>
              <div style={{fontWeight: 700, fontSize: 14}}>For uploading invoices</div>
              <div style={{textAlign: 'center', marginTop: 10, marginBottom: 20}}>
                <div style={{color: '#1EA0DA', fontSize: 29, fontWeight: 900, fontFamily: 'Noto Sans'}}>10</div>
                <div style={{fontWeight: 700, fontSize: 14}}><b>Points Earned</b></div>
                <div style={{color: '#9F9F9F', fontWeight: 300, fontSize: 14, marginTop: 15}}>Upload more invoices to earn more Points</div>
              </div>
              <Button style={{ backgroundColor: '#F47E1F', color: 'white'}}
                      onClick={async () => {
                        this.setState({openModal: false});
                        await this.props.getPoints();
                      }}
              >
                Close
              </Button>
            </div>
          </Modal>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(UploadInvoiceComponent)
