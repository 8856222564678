import React from "react";
import RseForm from "../components/rse-form";
import NavBar from "../components/navbar-component";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import sessionService from "../services/session-service";
import beatBusinessService from "../services/beat-business-service";
import _ from "lodash";
export class FormWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      isNew: this.props.isNew || false,
      membershipInfo: props.data,
      salesPersonDetails: {},
    };
  }

  async componentDidMount() {
    await beatBusinessService
      .getSalesPersonDetails(this.props.beatUserToken)
      .then((res) => {
        this.setState({ salesPersonDetails: res });
      });
    sessionService
      .getUserInfo()
      .then((res) => {
        _.set(res, "form.mobile", this.props.mobile || res.mobile);
        this.setState({ membershipInfo: res, mobile: res.mobile });
      })
      .catch((err) => console.log(err));
  }

  render() {
    const { pageNumber, membershipInfo, isNew } = this.state;
    const { mobile, businessId, rejectReasonId, otherRejectReason } = this.props;
    return (
      <>
        <NavBar />
        {membershipInfo && (
          <RseForm
            tokenObj={this.props.tokenObj}
            pageNumber={pageNumber}
            beatUserToken={this.props.beatUserToken}
            weeklyHoliday={this.props.weeklyHoliday}
            setPageNumber={(pageNo) => this.setState({ pageNumber: pageNo })}
            data={!isNew && membershipInfo}
            userNumber={mobile}
            businessId={businessId}
            salesPersonDetails={this.state.salesPersonDetails}
            otherRejectReason={otherRejectReason}
            rejectReasonId={rejectReasonId}
          />
        )}
        {!membershipInfo && <CircularProgress />}
      </>
    );
  }
}
