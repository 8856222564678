import React from 'react';
import config from '../config';

class RedirectToHippostores extends React.Component {

    componentDidMount(){
        window.location.href = config.hippostoresUrl;
      }

    render(){
        return <div/>
    }
}

export default RedirectToHippostores;
