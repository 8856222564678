import ReactGA from "react-ga";

const GAEventTracker = (category = "Event") => {

  const trackEvent = (action,label, value) => {
    
    ReactGA.event({ category, action, label, value})

  }
  return trackEvent
}

export default GAEventTracker