import storage from 'local-storage-fallback';
import _ from 'lodash';
import config from '../config';
import {UserModel} from '../models/user-model';

const TOKEN_OBJ = 'tokenObj';

function isLoggedIn() {
    return _.size(storage.getItem(TOKEN_OBJ)) > 0;
}

function login(tokenObj) {
    storage.setItem(TOKEN_OBJ, JSON.stringify(tokenObj))
}

function getUserTokenObj() {
    let rawTokenObj = storage.getItem(TOKEN_OBJ)
    return JSON.parse(rawTokenObj);
}

function getUserInfo() {
    return fetch(`${config.ruleServerUrl}/dalmia/info/${getUserToken()}`).then((res) => res.json())
        .then((res) => {
            let user = new UserModel()
            return _.extend(user, res)
        })
}

function getUserToken() {
    return _.get(getUserTokenObj(), 'token');
}

function logout() {
    storage.removeItem(TOKEN_OBJ);
}

export default {
    isLoggedIn,
    login,
    getUserInfo,
    getUserToken,
    getUserTokenObj,
    logout
}
