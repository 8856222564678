import React from 'react';
import {makeStyles} from '@material-ui/styles';
import tambolaBackground from '../assets/images/tambola-background.svg';
import Arrow from '../assets/images/tambola-arrow.svg';

const useStyles = makeStyles({
  container: {
    background: `url(${tambolaBackground}) no-repeat`,
    backgroundSize: "cover",
    height: "100vh",
    width: "100vw",
    "@media (min-width:0px) and (max-width:700px)": {
        transform: 'rotate(-90deg)',
        transformOrigin: 'left top',
        width: '100vh',
        height: '100vw',
        overflowX: 'hidden',
        position: 'absolute',
        top: '100%',
        left: '0',
    },
  },
  content: {
    background: `url(${Arrow}) no-repeat`,
    height: "100%",
    backgroundSize: "contain",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "column",
    color: "white",
  },
  title: {
    color: "white",
    fontSize: 87,
    textShadow: "56px 34px 3px #000000a6",
  },
  outterCircle: {
    background: "#f47f2230",
    height: 128,
    width: 128,
    borderRadius: 80,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  innerCircle: {
    background: "#F47F22",
    height: 98,
    width: 98,
    borderRadius: 80,
    boxShadow: "1px 0px 12px 5px rgba(0,0,0,0.75)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    fontSize: 20,
    fontWeight: "bolder",
    cursor: "pointer",
  },
  actionButtons: {
    color: "#F47F22",
    fontSize: 20,
    fontWeight: "bolder",
    cursor: "pointer",
    "@media (min-width:0px) and (max-width:700px)": {
      fontSize: 12,
    },
  }
});

const StartTambola = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.title}>Tambola</div>
        {!props.hasGameToPlay && <div className={'flex flex-column space-around'}>
          <div>You do not have enough points to play the game.</div>
          <div
              className={`${classes.actionButtons} center mt2`}
              onClick={() => window.location.href = '/'}
          >
            GO HOME
          </div>
        </div>}
        {props.hasGameToPlay &&
          <div className={classes.outterCircle}>
            <div className={classes.innerCircle} onClick={()=>props.nextPage()}>START</div>
          </div>
        }
      </div>
    </div>
  );
};

export default StartTambola;
