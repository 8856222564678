import {Grid, TextField} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {theme} from '../styles/custom-theme-style';
import React from 'react';
import {inputStyles} from '../styles/input-styles';
import withStyles from '@material-ui/styles/es/withStyles';
import config from '../config';
import OtpInput from 'react-otp-input';
import GAEventTracker from "../services/GAEventTracker";
import * as moment from 'moment';
import _ from "lodash";

const useGAEventTracker = GAEventTracker("OTP Verification");
class RseOtpFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: this.props.mobileNumber || null,
      otp: '',
      hasAskedForOtp: false,
      otpError: false,
      GATrackStartDate : '',
      attempt : 0
    }
  }

  getOtpToken = async () => {
    this.setState({hasAskedForOtp: true, GATrackStartDate : new Date()})
    await fetch(`${config.ruleServerUrl}/flow-otp/generate-for/beat-business-filling`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        tenantId: config.tenantId,
        tenantBrandBusinessId:  config.tenantBrandBusinessId,
        mobile: this.state.mobile,
        beatBusinessId: this.props.beatBusinessId
      })
    })
  };

  verifyOtp = async () => {
    const { onOtpVerified } = this.props;
    this.setState({otpError: '', attempt : this.state.attempt + 1})
    fetch(`${config.ruleServerUrl}/flow-otp/verify-for/beat-business-filling`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        tenantId: config.tenantId,
        tenantBrandBusinessId:  config.tenantBrandBusinessId,
        mobile: this.state.mobile,
        beatBusinessId: this.props.beatBusinessId,
        otp: this.state.otp || 'invalid-otp'
      })
    }).then(async response => {
        if(response.ok) {
          let body = await response.json();
          onOtpVerified(body.data);
        } else {
          this.setState({otpError: 'Invalid OTP'})
        }
    })
        .catch((err) => {
          console.error(err)
          this.setState({otpError: 'Invalid OTP'})
        })
        .finally(()=>{
          let verificationStatus = _.isEmpty(this.state.otpError) ? "SUCCESS" : "FAIL";
          let GATrackEndDate = new Date();
          let secondsDiff = (GATrackEndDate.getTime() -this.state.GATrackStartDate.getTime());
          let salesPersonEmail = _.replace(this.props.salesPerson.email, '@', '!'); 
          let dateAndEmail = `${moment().format("DD-MM-YYYY hh:mm:ss").toString()} ${salesPersonEmail} attempt${this.state.attempt.toString()} ${verificationStatus}`

          useGAEventTracker("Customer Mobile Verification", dateAndEmail, secondsDiff)
        })
  };

  render() {
    const {mobile, otp, hasAskedForOtp, otpError} = this.state;
    const {classes} = this.props;
    return (
      <div style={{textAlign: "center", paddingTop: 80, width: '70%', margin: '0 auto'}}>
        <Grid xs={12}>
          <h3 className={'mb2'}>OTP Verification</h3>
          <TextField
            id="number"
            label="Enter the Mobile Number"
            variant="outlined"
            placeholder="Mobile"
            disabled={!!mobile}
            value={mobile}
            type="text"
            onChange={event => this.setState({mobile: event.target.value, hasAskedForOtp: false})}
            fullWidth
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              }
            }}
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
          />
          <div style={{marginBottom: 20}}/>
          {!hasAskedForOtp && (
            <Button
              style={{
                marginLeft: '.5rem',
                backgroundColor: theme.palette.primary1Color,
                borderWidth: 1,
                borderRadius: 0,
                borderColor: theme.palette.primary1Color,
                color: 'white'
              }}
              onClick={this.getOtpToken}
              variant="outlined"
              color="secondary"
              size="large">
              Get OTP
            </Button>
          )}
          {hasAskedForOtp && (
            <>
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <OtpInput numInputs={6}
                          value={otp}
                          isInputNum={true}
                          inputStyle={{width: 35, marginRight: 10, fontSize: '2em', border: 0, borderBottom: '2px solid #3f51b5'}}
                          separator={<span>&nbsp;</span>}
                          onChange={(otpString) => {
                            this.setState({otp: otpString})
                          }}/>
              </div>
              {otpError && <div style={{fontSize: 12, color: 'red', textAlign: 'center', marginTop: 10}}>{otpError}</div>}
              <Button
                style={{
                  marginLeft: '.5rem',
                  backgroundColor: theme.palette.primary1Color,
                  borderWidth: 1,
                  borderRadius: 0,
                  borderColor: theme.palette.primary1Color,
                  color: 'white',
                  marginTop: 30
                }}
                onClick={() => this.verifyOtp()}
                variant="outlined"
                color="secondary"
                size="large">
                Start
              </Button>
            </>
          )}
        </Grid>
      </div>
    )
  }
}

export default withStyles(inputStyles)(RseOtpFormComponent);
