import React from 'react';
import {Route, Router, Switch} from 'react-router';

import {Layout} from '../components/layout-component';
import history from '../util/history-util';
import CustomerLandingPage from '../pages/customer-landing-page';
import Acknowledgement from '../pages/acknowledgement-page';
import CustomerHome from '../pages/customer-home';
import PrivacyPolicy from '../pages/privacy-policy';
import OtpFormPage from '../pages/otp-form-page';
import FaqComponent from "../pages/Faq"
import SurveyForm from '../pages/survey-form';
import Tambola from '../pages/tambola';
import RedirectToHippostores from '../pages/redirect-to-hippostores';
/**
 * 👉 if authenticated routes are needed 👈
 *
 * import PrivateRoute from './PrivateRoute';
 *
 * const RestrictedArea = () => {
 * return (
 *  <Switch>
 *    <PrivateRoute path="/something-private" component={SomethingPrivate} />
 *  </Switch>
 * );
 *};
 **/

export const Routes = () => {
  return(
  <Router history={history}>
    <>
      <Layout>
        <Switch>
          
          <Route exact path="/" component={RedirectToHippostores} />
          <Route exact path="/acknowledgement" component={Acknowledgement} />
          <Route exact path="/customer-home" component={CustomerHome} />
          <Route exact path="/form" component={OtpFormPage} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/faq" component={FaqComponent} />
          <Route exact path="/game-tambola" component={Tambola}/>
          <Route exact path="/survey" component={SurveyForm} />
        </Switch>
      </Layout>
      <Route exact path="/customer" component={CustomerLandingPage} />
    </>
  </Router>
)};
