module.exports = {
    ruleServerUrl: 'https://api.casaqa.ajira.tech',
    tenantId: 194,
    tenantBrandBusinessId: 160,
    ruleUiUrl: 'https://rules.casaqa.ajira.tech',
    beatServerUrl: 'https://beat.casaqa.ajira.tech',
    beatApiNodeUrl: "https://beat-api.casaqa.ajira.tech",
    hippostoresUrl : 'https://dalmia-ecomm.casaqa.ajira.tech',
    GATrackingCode : 'UA-202989810-1',
    gridlinesURL: 'https://api.gridlines.io',
    gridlinesAPIKey: "QS81N8T493tY8M7wu5kEKSzEcMq8CFIL"
};
