import React from 'react';
import NavBar from '../components/navbar-component';
import {Button} from '@material-ui/core';
import Backdrop from '../assets/images/acknowledgement_bg.png';
import MembershipNumberBackground from '../assets/images/membership-number.png';
import RightArrow from '../assets/icons/arrow-right.svg';
import DalmiaLogo from '../assets/images/dalmia1x.png';
import classes from '../styles/acknowledgement.module.css';
import sessionService from '../services/session-service';

export default class Acknowledgement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = async () => {
    const data = await sessionService.getUserInfo();
    this.setState({ data: data });
  };

  render() {
    const { data } = this.state;
    const { form } = data || {};
    const { businessName } = form || {};
    return (
      <>
        <NavBar />
        <div className={classes.shadow}></div>
        <div className={classes.wrapper}>
          <div className={classes.cardImage}>
            <img
              src={MembershipNumberBackground}
              alt="a"
              style={{ width: "100%" }}
            />
            <div className={classes.cardContent}>
              <div className={classes.cardShadow}>
                <div className={classes.logoSection}>
                  <div className={classes.dalmiaLogo}>
                    <img
                      src={DalmiaLogo}
                      alt="logo"
                      style={{
                        width: 80,
                      }}
                    />
                  </div>
                </div>
                <b className={classes.name}>
                  {businessName && businessName.substring(0, 24).toUpperCase()}
                </b>
                <br />
                <b className={classes.cardNumber}></b>
              </div>
            </div>
          </div>
          <div className={classes.title}>Welcome to Dalmia</div>
          <div className={classes.caption}>
            You have now joined our ever-growing SMART building material
            business member community.
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "40px 0px",
            }}
          >
            <div className={classes.appreciation}>
              <img src={Backdrop} alt="a" className={classes.acknowledgementWelcomeImg} />
            </div>
          </div>
          <div className={classes.deliveryType}>3-month free delivery</div>
          <div className={classes.purchaseInfo}>for all your purchases</div>
          <div className={classes.team}>
            Our field team will soon get in touch with you to understand your
            business and develop special deals for you.
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              className={classes.button}
              onClick={() => {
                window.location = `/`;
              }}
            >
              <b style={{ display: "flex", alignItems: "center" }}>
                Start My Journey
                <img src={RightArrow} alt="right" style={{ width: 20 }} />
              </b>
            </Button>
          </div>
        </div>
      </>
    );
  }
}
