import React from "react";
import {withStyles} from "@material-ui/core";
import CompletedStepperIcon from  '../assets/icons/CompletedStepperIcon.svg';
import Step1ActiveIcon from '../assets/icons/step1-active.svg';
import Step2ActiveIcon from '../assets/icons/step2-active.svg';
import Step2Icon from  '../assets/icons/step2.svg';

const style = {
  image: {
    width: 25,
    height: 25,
    marginTop: 0
  }
};

function StepIcon({activeStep, index, classes}) {
  switch (index) {
    case 0:
      return activeStep > index
        ? <img src={CompletedStepperIcon} className={classes.image} alt='comp' />
        : <img src={Step1ActiveIcon} className={classes.image} alt='step1' />;
    case 1:
      return activeStep === index
        ? <img src={Step2ActiveIcon} className={classes.image} alt='step2' />
        : <img src={Step2Icon} className={classes.image} alt='step1' />;
    default:
      return;
  }
}

export default withStyles(style)(StepIcon);
