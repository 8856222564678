import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import NavBar from "../components/navbar-component";
import { Button } from "@material-ui/core";

const styles = {
  wrapper: {
    background: "#F6F6F6",
    width: "100%",
    height: "100%",
    paddingBottom: "60px",
  },
  container: {
    width: 968,
    margin: "0 auto",
    PaddingBottom: 30,
    "@media (min-width:0px) and (max-width:700px)": {
      width: "85%",
      margin: "0 auto",
    },
    "@media (min-width:700px) and (max-width:900px)": {
      width: "85%",
      margin: "0 auto",
    },
  },
  title: {
    fontSize: 20,
    padding: "36px 0px",
    paddingTop: 110,
    "@media (min-width:0px) and (max-width:700px)": {
      paddingTop: 95,
    },
  },
  accordionTitle: {
    fontSize: 14,
    color: "#29C3EE",
    fontWeight: "bolder",
    "@media (min-width:0px) and (max-width:700px)": {
      fontSize: 12,
    },
  },
  question: {
    fontFamily: "Noto Sans TC,Regular",
    fontSize: 12,
    "@media (min-width:0px) and (max-width:700px)": {
      fontSize: 12,
    },
  },
  caption: {
    fontFamily: "Noto Sans TC,Regular",
    fontSize: 11,
    color: "#939597",
    "@media (min-width:0px) and (max-width:700px)": {
      fontSize: 11,
    },
  },
  arrowColor: {
    color: "#F47F1F",
  },
  viewAll: {
    fontSize: 11,
    textTransform: "upperCase",
    fontWeight: "bold",
    color: "#F47F1F",
    cursor: "pointer",
    "@media (min-width:0px) and (max-width:700px)": {
      fontSize: 11,
    },
  },
  accordionStyles: {
    border: "1px solid #EEEEF2",
    width: 968,
  },
  titleAccordionStyles: {
    borderLeft: "1px solid #EEEEF2",
    borderRight: "1px solid #EEEEF2",
    borderTop: "1px solid #EEEEF2",
  },
};

const Accordion = withStyles({
  root: {
    border: "1px solid #EEEEF2",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderTop: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "#FFFFFF",
    borderBottom: "1px solid #EEEEF2",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      borderBottom: "0px solid #EEEEF2",
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const Faq = (props) => {
  const [expanded, setExpanded] = React.useState([]);
  let expandables = [...expanded];

  const handleChange = (panel) => (event, newExpanded) => {
    if (expandables.includes(panel))
      expandables.splice(expandables.indexOf(panel), 1);
    else expandables.push(panel);
    setExpanded(expandables.sort());
  };

  const viewAll = () => {
    if (expandables.length === customerRegistrationDetails.length)
      expandables = [];
    else
      customerRegistrationDetails.map((details) =>
        expandables.push(details.id)
      );
    if (
      expandables.length >= 0 &&
      expandables.length !== customerRegistrationDetails.length
    ) {
      expandables = [];
    }
    setExpanded(expandables.sort());
    console.log("expandables=====>",expandables)
  };

  const customerRegistrationDetails = [
    {
      id: 1,
      que: "What is Dalmia Retail ?",
      ans:
        "Dalmia Retail is India’s first all under one roof building material retail store. We sell more than 50,000 products required for completing any construction project. We have wholesale pricing throughout the year for our business members. Dalmia Retail is a part of Dalmia Cement Bharat Ltd.",
    },
    {
      id: 2,
      que: "Why should I buy through Dalmia Retail?",
      ans:
        "Dalmia Retail will help you grow your business and earn more profits. You can grow your business by adding more brands, or more categories without the need to carry high inventory. We will provide 365 days wholesale prices and you can get more discounts on your total monthly billing. Hence, reduce your total cost of purchase and earn more. Now you will be able to do your business from your mobile using our user friendly app. You can check prices, check availability, get delivery times and track your orders - all on your phone.",
    },
    {
      id: 3,
      que: "What information and documents are needed for registration?",
      ans:
        "Business members need to provide basic business information like company name, address and a Valid GSTIN or PAN or Any other business license. Apart from this the owner contact details are required.",
    },
    {
      id: 4,
      que: "Are there any charges for registration?",
      ans: "Registration is free of cost.",
    },
    {
      id: 5,
      que: "Why should I register today?",
      ans:
        "By registering today you will get free delivery in the first three months starting with the opening of our store. Apart from that you have the possibility to participate in many engagement programs to earn points which can be redeemed at the time of opening. These engagement programs do not require you to buy anything today but share easily available information about your business.",
    },
    {
      id: 6,
      que: "How can I place an order?",
      ans:
        "We recommend that you visit our store to see the full merchandise and order the products from there. However, you will be able to order the products from your mobile phone using our user friendly app. You will also have a dedicated account manager who will help in ordering on your behalf. Finally, you will be able to call our call center in case you need further help.",
    },
    {
      id: 7,
      que: "Will you deliver products the same day?",
      ans:
        "Yes, same day delivery will be available for a range of products. However, all stock available products will be shipped within 24 hours of your order.",
    },
    {
      id: 8,
      que: "What will be the cost of delivery post 3 months of free delivery?",
      ans:
        "We will be charging you delivery as per the current market practices from our store.",
    },
    {
      id: 9,
      que: "Who will cover the guarantee and warranty of the product?",
      ans:
        "The manufacturer will cover the guarantee and warranty of the product. However, you will have our customer service desk and call center to help you in-case you face any issues.",
    },
    {
      id: 10,
      que: "Will you have a minimum order quantity for placing an order?",
      ans:
        "For most products the order will be placed in single quantities. However, for some products the minimum order quantity will be mentioned along with product details.",
    },
    {
      id: 11,
      que: "Will I be able to cancel my order?",
      ans:
        "Yes, you can cancel your order before dispatch from the warehouse or store. Once, dispatch has been done then the order cannot be cancelled. You will be able to monitor the status of the order on our app.",
    },
    {
      id: 12,
      que: "Will you take returns and do replacements?",
      ans:
        "Yes, we will be taking returns and offer replacement as per our returns policy which you will be able to view before ordering any product.",
    },
    {
      id: 13,
      que: "Will I be able to track my order?",
      ans:
        "Yes, you can track your order via our app. On the date of delivery you will be notified both by email and sms message at the start of business.",
    },
  ];
  const { classes } = props;
  return (
    <>
      <NavBar />
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <div className={classes.title}>
            <strong>Frequently Asked Questions</strong>
          </div>
          <AccordionSummary
            expandIcon={
              <Button disableRipple className={classes.viewAll} onClick={viewAll}>
                {expanded.length < 1 &&
                expanded.length < customerRegistrationDetails.length
                  ? "view all"
                  : "view less"}
              </Button>
            }
            classes={{ root: classes.titleAccordionStyles }}
            aria-controls="panel1d-content"
            id="panel1d-header"
            // style={{border: "1px solid #EEEEF2"}}
          >
            <Typography>
              <b className={classes.accordionTitle}>
                About Customer Registration
              </b>
            </Typography>
          </AccordionSummary>
          {customerRegistrationDetails &&
            customerRegistrationDetails.map((faq, index) => (
              <Accordion
                square
                expanded={expanded.includes(faq.id)}
                onChange={handleChange(faq.id)}
                key={index}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      color="primary"
                      classes={{ colorPrimary: classes.arrowColor }}
                    />
                  }
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Typography className={classes.question}>
                    {faq.que}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={classes.caption}>{faq.ans}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
        </div>
      </div>
    </>
  );
};

export default withStyles(styles)(Faq);
