import React from "react";
import { makeStyles } from "@material-ui/styles";
import tambolaBackground from "../assets/images/tambola-background.svg";
import Arrow from "../assets/images/tambola-arrow.svg";
import BackArrow from "../assets/images/tambola-back.svg";
import ScoreImage from "../assets/images/score.svg";

const useStyles = makeStyles({
  container: {
    background: `url(${tambolaBackground}) no-repeat`,
    backgroundSize: "cover",
    height: "100vh",
    width: "100vw",
    "@media (min-width:0px) and (max-width:700px)": {
      transform: "rotate(-90deg)",
      transformOrigin: "left top",
      width: "100vh",
      height: "100vw",
      overflowX: "hidden",
      position: "absolute",
      top: "100%",
      left: "0",
    },
  },
  content: {
    background: `url(${Arrow}) no-repeat`,
    height: "100%",
    backgroundSize: "contain",
    color: "white",
  },
  navigate: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 100,
    "@media (min-width:0px) and (max-width:700px)": {
      height: "8vh",
    },
  },
  navigateBack: {
    background: `url(${BackArrow}) no-repeat`,
    height: 30,
    width: 30,
    backgroundSize: "100% 100%",
    marginLeft: 80,
    cursor: "pointer",
  },
  timeout: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginTop: 10,
    "@media (min-width:0px) and (max-width:700px)": {
      marginTop: 0,
    },
  },
  timeoutContent: {
    fontSize: 56,
    fontWeight: "bolder",
    "@media (min-width:0px) and (max-width:700px)": {
      fontSize: 30,
    },
  },
  scoreSection: {
    background: `url(${ScoreImage}) no-repeat`,
    height: 375,
    width: 470,
    backgroundSize: "100% 100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media (min-width:0px) and (max-width:700px)": {
      height: "30vh",
    },
  },
  outterCircle: {
    height: 226,
    width: 226,
    background: "#ffffff52",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media (min-width:0px) and (max-width:700px)": {
      width: 135,
      height: 135,
    },
  },
  innerCircle: {
    height: 180,
    width: 180,
    background: "white",
    borderRadius: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "@media (min-width:0px) and (max-width:700px)": {
      width: 110,
      height: 110,
    },
  },
  score: {
    color: "#F47F22",
    fontSize: 66,
    fontWeight: "bolder",
    "@media (min-width:0px) and (max-width:700px)": {
      fontSize: 40,
    },
  },
  yourScore: {
    color: "black",
    fontSize: 20,
    "@media (min-width:0px) and (max-width:700px)": {
      fontSize: 12,
    },
  },
  action: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  callToAction: {
    width: "50%",
    display: "flex",
    justifyContent: "space-around",
  },
  actionButtons: {
    color: "#F47F22",
    fontSize: 20,
    fontWeight: "bolder",
    cursor: "pointer",
    "@media (min-width:0px) and (max-width:700px)": {
      fontSize: 12,
    },
  },
});

const GameOver = (props) => {
  props.savePoints();
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.navigate}>
          <div className={classes.navigateBack}></div>
        </div>
        <div className={classes.timeout}>
          <div className={classes.timeoutContent}>Level {props.level}</div>
          <div className={classes.scoreSection}>
            <div className={classes.outterCircle}>
              <div className={classes.innerCircle}>
                <div className={classes.score}>
                  {props.passedNumbers.length * 50}
                </div>
                <div className={classes.yourScore}>Your Score</div>
              </div>
            </div>
          </div>
          <div className={classes.action}>
            <div className={classes.callToAction}>
              <div
                className={classes.actionButtons}
                onClick={() => props.playAgain()}
              >
                PLAY AGAIN
              </div>
                <div
                  className={classes.actionButtons}
                  onClick={() => window.location.href="/"}
                >
                  GO HOME
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameOver;
