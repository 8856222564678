import React from 'react';
import Button from '@material-ui/core/Button';
import PhoneIcon from '../assets/icons/phone-home.svg';
import ScheduleIcon from '../assets/icons/schedule.svg';
import ScheduleCallBackground from '../assets/images/scheduleCallBackground.png';
import ScheduleCallDesktopBackground from '../assets/images/scheduler-call-back-desktop.png';
import RightArrowIcon from '../assets/icons/arrow-right-home.svg';
import Modal from '@material-ui/core/Modal';
import * as moment from 'moment';
import config from '../config';
import 'basscss/css/basscss.min.css'


export default class CustomerInterest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileNumber: '',
      customerName: '',
      date: '',
      timeSlot: '',
      showModal: false
    }
  }

  handleChange = (field, value) => {
    this.setState({[`${field}`]: value});
  };

  handleSubmit = async () => {
    const {mobileNumber, customerName, date, timeSlot} = this.state;
    const dateSlot = date === 'Today' ? moment().format('YYYY-MM-DD') : moment().add(1, 'd').format('YYYY-MM-DD');
    await fetch(`${config.ruleServerUrl}/dalmia/schedule`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        mobileNumber,
        name: customerName,
        dateSlot: dateSlot,
        timeSlot
      })
    });
    this.setState({isSubmitted: true});
  };

  handleScheduleCall = () => {
    const {mobileNumber, customerName} = this.state;
    if(!mobileNumber) {
      this.setState({inputError: 'Please Enter Your Mobile Number!'});
      return;
    }
    if(mobileNumber.length !== 10) {
      this.setState({inputError: 'Please Check The Mobile Number You Have Entered!'});
      return;
    }
    if(!customerName) {
      this.setState({inputError: 'Please Enter Your Name!'});
      return;
    }
    this.setState({showModal: true, isSubmitted: false, timeSlot: null, date: null})
  };

  isAfter(hour) {
    let now = moment();
    let dateToCheck = now.hour(hour).minute(0);
    return moment().isAfter(dateToCheck);
  }

  coreContent() {
    const {inputError, mobileNumber, customerName} = this.state;
    return (<div style={{marginLeft: 20, marginRight: 20}} className={'py4'}>
      <div 
      style={{
        textAlign: 'center',
        font: 'normal normal normal 30px/36px Noto Sans',
        color: '#FFFFFF',
        textShadow: '0px 3px 6px #00000029'
      }}>
        Be the new generation business. Register and get all the benefits.
      </div>
      <div className={'clearfix my3 sm-flex lg-flex justify-center'}>
        <div className={'col col-12 xs-col-12 sm-col-5 md-col-4'}>
          <Button style={{
            width: '100%',
            backgroundColor: '#F47E1F',
            color: 'white',
            marginTop: 10,
            display: 'flex',
            flexDirection: 'row',
            height:40
          }}>
              <div style={{flex: 1, textAlign: 'center'}}/>
              <div style={{flex: 10, textAlign: 'center',fontWeight:"bolder"}} onClick={() => window.location = '/customer'}>Sign Up Today
              </div>
              <img src={RightArrowIcon} style={{flex: 1, textAlign: 'center'}} alt='right'/>
            </Button>
            <div style={{marginTop: 20, marginBottom: 20, textAlign: 'center', color: 'white'}}>OR</div>
            <Button style={{
              width: '100%',
              backgroundColor: '#F47E1F',
              color: 'white',
              display: 'flex',
              flexDirection: 'row',
              height:40
            }}>
              <div style={{flex: 1, textAlign: 'center'}}/>
              <div style={{flex: 10, textAlign: 'center'}} onClick={() => {
                window.location = 'tel:6356311598'
              }}><strong>Give A MISSED CALL</strong>
            </div>
            <img src={PhoneIcon} style={{flex: 1, textAlign: 'center'}} alt='phone'/>
          </Button>
        </div>
        <div className={'col col-12 xs-col-12  sm-col-2 flex flex-center'}>
          <div style={{display:"flex",alignItems:"center",color:"white",margin:"20px auto"}}>OR</div>
        </div>
        <div className={'col col-12 xs-col-12  sm-col-5 md-col-4'}>
          <div style={{width: '97%', marginBottom: 20}}>
            <input style={{width: '100%', height: 40, paddingLeft: 10}} value={mobileNumber} type='number'
                   placeholder='ENTER YOUR MOBILE NUMBER'
                   onChange={e => this.handleChange('mobileNumber', e.target.value)}/>
          </div>
          <div style={{width: '97%', marginBottom: 20}}>
            <input style={{width: '100%', height: 40, paddingLeft: 10}} value={customerName}
                   placeholder='ENTER YOUR NAME' onChange={e => this.handleChange('customerName', e.target.value)}/>
          </div>
          <div style={{
            color: 'white',
            fontSize: 14,
            fontWeight: 900,
            textAlign: 'center',
            marginBottom: 20,
          }}>{inputError}</div>
          <Button style={{
            width: '100%',
            backgroundColor: '#F47E1F',
            color: 'white',
            display: 'flex',
            flexDirection: 'row',
            height:40
          }} onClick={() => this.handleScheduleCall()}>
            <div style={{flex: 11,fontWeight:'bolder'}}>SCHEDULE A CALLBACK</div>
            <img src={ScheduleIcon} style={{flex: 1, textAlign: 'center'}} alt='phone'/>
          </Button>
        </div>
      </div>
    </div>);
  }

  render() {
    const {showModal, date, isSubmitted, timeSlot} = this.state;
    return (
        <>
          <div style={{backgroundImage: `url(${ScheduleCallDesktopBackground})`,backgroundSize:"100% 100%"}} className={'xs-hide sm-hide'}>
            {this.coreContent()}
          </div>
          <div style={{backgroundImage: `url(${ScheduleCallBackground})`,backgroundSize:"100% 100%"}} className={'lg-hide md-hide'}>
            {this.coreContent()}
          </div>
          <Modal open={showModal} onClose={() => this.setState({showModal: false})}>
            <div style={{
              fontFamily: 'Nunito',
              backgroundColor: 'white',
              margin: '0 auto',
              marginTop: 100,
              width: '90%'
            }}>
              <div style={{padding: 20}}>
                {!isSubmitted &&
                <div>
                  <div style={{fontSize: 16, fontWeight: 'bold', color: 'black'}}>Please Select Date:</div>
                  <form>
                    <div style={{marginTop: 5}}>
                      <input type='radio' onChange={(e) => this.setState({date: e.target.value})} value='Today'
                             checked={date === 'Today'}/>
                      <span style={{marginTop: 5}}>Today</span>
                      <br/>
                    </div>
                    <div style={{marginTop: 5}}>
                      <input type='radio' onChange={(e) => this.setState({date: e.target.value})} value='Tomorrow'
                             checked={date === 'Tomorrow'}/>
                      <span style={{marginTop: 5}}>Tomorrow</span>
                      <br/>
                    </div>
                  </form>
                  {
                    date && (
                        <>
                          {
                            (date === 'Tomorrow' || (date === 'Today' && !this.isAfter(14))) &&
                            <div style={{fontSize: 16, fontWeight: 'bold', color: 'black', marginTop: 20}}>Please Select
                              Time Slot:
                            </div>
                          }
                          <form>
                            <div>
                              {
                                (date === 'Tomorrow' || !this.isAfter(7)) &&
                                <div style={{marginTop: 5}}>
                                  <input type='radio' onChange={(e) => this.setState({timeSlot: e.target.value})}
                                         value="09:00 AM to 11:00 AM"/>
                                  <span style={{marginTop: 5}}>09:00 AM to 11:00 AM</span> <br/>
                                </div>
                              }
                              {
                                (date === 'Tomorrow' || !this.isAfter(9)) &&
                                <div style={{marginTop: 5}}>
                                  <input type='radio' onChange={(e) => this.setState({timeSlot: e.target.value})}
                                         value="11:00 AM to 01:00 PM"/>
                                  <span style={{marginTop: 5}}>11:00 AM to 01:00 PM</span> <br/>
                                </div>
                              }
                              {
                                (date === 'Tomorrow' || !this.isAfter(12)) &&
                                <div style={{marginTop: 5}}>
                                  <input type='radio' onChange={(e) => this.setState({timeSlot: e.target.value})}
                                         value="02:00 PM to 04:00 PM"/>
                                  <span style={{marginTop: 5}}>02:00 PM to 04:00 PM</span> <br/>
                                </div>
                              }
                              {
                                (date === 'Tomorrow' || !this.isAfter(14)) &&
                                <div style={{marginTop: 5}}>
                                  <input type='radio' onChange={(e) => this.setState({timeSlot: e.target.value})}
                                         value="04:00 PM to 06:00 PM"/>
                                  <span style={{marginTop: 5}}>04:00 PM to 06:00 PM</span> <br/>
                                </div>
                              }
                              {
                                date === 'Today' && this.isAfter(14) &&
                                <div style={{
                                  fontSize: 16,
                                  fontWeight: 'bold',
                                  color: 'black',
                                  marginTop: 20,
                                  textAlign: 'center'
                                }}>No Time slots available today.</div>
                              }
                            </div>
                          </form>
                        </>
                    )
                  }
                </div>
                }
                {
                  isSubmitted && (
                      <>
                        <div style={{textAlign: 'center'}}>You have scheduled a call at
                          <div style={{fontWeight: 700, fontSize: 16}}>{timeSlot} {date}</div>
                        </div>
                        <div style={{textAlign: 'center', marginTop: 10}}>Our representative will contact you at the
                          above time.
                        </div>
                        <div style={{textAlign: 'center', marginTop: 10}}>Thank You!</div>
                      </>
                  )
                }
              </div>
              <div style={{textAlign: 'center', paddingBottom: 20}}>
                {!isSubmitted &&
                <Button style={{backgroundColor: '#F47E1F', color: 'white', marginRight: 10}}
                        onClick={() => this.handleSubmit()}
                >Submit</Button>
                }
                <Button style={{backgroundColor: '#F47E1F', color: 'white'}}
                        onClick={() => this.setState({showModal: false, mobileNumber: '', customerName: ''})}
                >
                  {isSubmitted ? 'Close' : 'Cancel'}
                </Button>
              </div>
            </div>
          </Modal>
        </>
    )
  }
}
