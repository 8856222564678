const cityStates = {
  "Andaman And Nicobar Islands": ["Port Blair"],
  "Andhra Pradesh": [
    "Adoni",
    "Amaravati",
    "Anantapur",
    "Chandragiri",
    "Chittoor",
    "Dowlaiswaram",
    "Eluru",
    "Guntur",
    "Kadapa",
    "Kakinada",
    "Kurnool",
    "Machilipatnam",
    "Nagarjunakoṇḍa",
    "Rajahmundry",
    "Srikakulam",
    "Tirupati",
    "Vijayawada",
    "Visakhapatnam",
    "Vizianagaram",
    "Yemmiganur",
  ],
  "Arunachal Pradesh": ["Itanagar"],
  "Assam": [
    "Dhuburi",
    "Dibrugarh",
    "Dispur",
    "Guwahati",
    "Jorhat",
    "Nagaon",
    "Sibsagar",
    "Silchar",
    "Tezpur",
    "Tinsukia",
  ],
  "Bihar": [
    "Ara",
    "Baruni",
    "Begusarai",
    "Bettiah",
    "Bhagalpur",
    "Bihar Sharif",
    "Bodh Gaya",
    "Buxar",
    "Chapra",
    "Darbhanga",
    "Dehri",
    "Dinapur Nizamat",
    "Gaya",
    "Hajipur",
    "Jamalpur",
    "Katihar",
    "Madhubani",
    "Motihari",
    "Munger",
    "Muzaffarpur",
    "Patna",
    "Purnia",
    "Pusa",
    "Saharsa",
    "Samastipur",
    "Sasaram",
    "Sitamarhi",
    "Siwan",
  ],
  "Chandigarh": ["Chandigarh"],
  "Chattisgarh": [
    "Ambikapur",
    "Bhilai",
    "Bilaspur",
    "Dhamtari",
    "Durg",
    "Jagdalpur",
    "Raipur",
    "Rajnandgaon",
  ],
  "Dadar and Nagar Haveli": ["Silvasa"],
  "Daman and Diu": [
    "Daman",
    "Diu",
  ],
  "Delhi": [
    "Delhi",
    "New Delhi",
  ],
  "Goa": [
    "Madgaon",
    "Panaji",
  ],
  "Gujarat": [
    "Ahmadabad",
    "Amreli",
    "Bharuch",
    "Bhavnagar",
    "Bhuj",
    "Dwarka",
    "Gandhinagar",
    "Godhra",
    "Jamnagar",
    "Junagadh",
    "Kandla",
    "Khambhat",
    "Kheda",
    "Mahesana",
    "Morvi",
    "Nadiad",
    "Navsari",
    "Okha",
    "Palanpur",
    "Patan",
    "Porbandar",
    "Rajkot",
    "Surat",
    "Surendranagar",
    "Valsad",
    "Veraval",
  ],
  "Haryana": [
    "Ambala",
    "Bahadurgarh",
    "Bhiwani",
    "Chandigarh",
    "Faridabad",
    "Firozpur Jhirka",
    "Gurgaon",
    "Hansi",
    "Hisar",
    "Jind",
    "Kaithal",
    "Karnal",
    "Kurukshetra",
    "Panipat",
    "Pehowa",
    "Rewari",
    "Rohtak",
    "Sirsa",
    "Sonipat",
  ],
  "Himachal Pradesh": [
    "Bilaspur",
    "Chamba",
    "Dalhousie",
    "Dharmshala",
    "Hamirpur",
    "Kangra",
    "Kullu",
    "Mandi",
    "Nahan",
    "Shimla",
    "Solan",
    "Una",
  ],
  "Jammu And Kashmir": [
    "Anantnag",
    "Baramula",
    "Doda",
    "Gulmarg",
    "Jammu",
    "Kathua",
    "Leh",
    "Punch",
    "Rajauri",
    "Srinagar",
    "Udhampur",
  ],
  "Jharkhand": [
    "Bokaro",
    "Chaibasa",
    "Deoghar",
    "Dhanbad",
    "Dumka",
    "Giridih",
    "Hazaribag",
    "Jamshedpur",
    "Jharia",
    "Rajmahal",
    "Ranchi",
    "Saraikela",
  ],
  "Karnataka": [
    "Badami",
    "Ballari",
    "Bangalore",
    "Belgavi",
    "Bhadravati",
    "Bidar",
    "Chikkamagaluru",
    "Chitradurga",
    "Davangere",
    "Halebid",
    "Hassan",
    "Hubballi-Dharwad",
    "Kalaburagi",
    "Kolar",
    "Madikeri",
    "Mandya",
    "Mangaluru",
    "Mysuru",
    "Raichur",
    "Shivamogga",
    "Shravanabelagola",
    "Shrirangapattana",
    "Tumkuru",
  ],
  "Kerala": [
    "Alappuzha",
    "Badagara",
    "Idukki",
    "Kannur",
    "Kochi",
    "Kollam",
    "Kottayam",
    "Kozhikode",
    "Mattancheri",
    "Palakkad",
    "Thalassery",
    "Thiruvananthapuram",
    "Thrissur",
  ],
  "Madhya Pradesh": [
    "Balaghat",
    "Barwani",
    "Betul",
    "Bharhut",
    "Bhind",
    "Bhojpur",
    "Bhopal",
    "Burhanpur",
    "Chhatarpur",
    "Chhindwara",
    "Damoh",
    "Datia",
    "Dewas",
    "Dhar",
    "Guna",
    "Gwalior",
    "Hoshangabad",
    "Indore",
    "Itarsi",
    "Jabalpur",
    "Jhabua",
    "Khajuraho",
    "Khandwa",
    "Khargon",
    "Maheshwar",
    "Mandla",
    "Mandsaur",
    "Mhow",
    "Morena",
    "Murwara",
    "Narsimhapur",
    "Narsinghgarh",
    "Narwar",
    "Neemuch",
    "Nowgong",
    "Orchha",
    "Panna",
    "Raisen",
    "Rajgarh",
    "Ratlam",
    "Rewa",
    "Sagar",
    "Sarangpur",
    "Satna",
    "Sehore",
    "Seoni",
    "Shahdol",
    "Shajapur",
    "Sheopur",
    "Shivpuri",
    "Ujjain",
    "Vidisha",
  ],
  "Maharashtra": [
    "Ahmadnagar",
    "Akola",
    "Amravati",
    "Aurangabad",
    "Bhandara",
    "Bhusawal",
    "Bid",
    "Buldana",
    "Chandrapur",
    "Daulatabad",
    "Dhule",
    "Jalgaon",
    "Kalyan",
    "Karli",
    "Kolhapur",
    "Mahabaleshwar",
    "Malegaon",
    "Matheran",
    "Mumbai",
    "Nagpur",
    "Nanded",
    "Nashik",
    "Osmanabad",
    "Pandharpur",
    "Parbhani",
    "Pune",
    "Ratnagiri",
    "Sangli",
    "Satara",
    "Sevagram",
    "Solapur",
    "Thane",
    "Ulhasnagar",
    "Vasai-Virar",
    "Wardha",
    "Yavatmal",
  ],
  "Manipur": ["Imphal"],
  "Meghalaya": [
    "Cherrapunji",
    "Shillong"
  ],
  "Mizoram": [
    "Aizawl",
    "Lunglei",
  ],
  "Nagaland": [
    "Kohima",
    "Mon",
    "Phek",
    "Wokha",
    "Zunheboto",
  ],
  "Odisha": [
    "Balangir",
    "Baleshwar",
    "Baripada",
    "Bhubaneshwar",
    "Brahmapur",
    "Cuttack",
    "Dhenkanal",
    "Keonjhar",
    "Konark",
    "Koraput",
    "Paradip",
    "Phulabani",
    "Puri",
    "Sambalpur",
    "Udayagiri",
  ],
  "Puducherry": [
    'Karaikal',
    'Mahe',
    'Puducherry',
    'Yanam',
  ],
  "Punjab": [
    "Amritsar",
    "Batala",
    "Chandigarh",
    "Faridkot",
    "Firozpur",
    "Gurdaspur",
    "Hoshiarpur",
    "Jalandhar",
    "Kapurthala",
    "Ludhiana",
    "Nabha",
    "Patiala",
    "Rupnagar",
    "Sangrur",
  ],
  "Rajasthan": [
    "Abu",
    "Ajmer",
    "Alwar",
    "Amer",
    "Barmer",
    "Beawar",
    "Bharatpur",
    "Bhilwara",
    "Bikaner",
    "Bundi",
    "Chittaurgarh",
    "Churu",
    "Dhaulpur",
    "Dungarpur",
    "Ganganagar",
    "Hanumangarh",
    "Jaipur",
    "Jaisalmer",
    "Jalor",
    "Jhalawar",
    "Jhunjhunu",
    "Jodhpur",
    "Kishangarh",
    "Kota",
    "Merta",
    "Nagaur",
    "Nathdwara",
    "Pali",
    "Phalodi",
    "Pushkar",
    "Sawai Madhopur",
    "Shahpura",
    "Sikar",
    "Sirohi",
    "Tonk",
    "Udaipur",
  ],
  "Sikkim": [
    "Gangtok",
    "Gyalsing",
    "Lachung",
    "Mangan",
  ],
  "Tamilnadu": [
    "Arcot",
    "Chengalpattu",
    "Chennai",
    "Chidambaram",
    "Coimbatore",
    "Cuddalore",
    "Dharmapuri",
    "Dindigul",
    "Erode",
    "Kanchipuram",
    "Kanniyakumari",
    "Kodaikanal",
    "Kumbakonam",
    "Madurai",
    "Mamallapuram",
    "Nagappattinam",
    "Nagercoil",
    "Palayankottai",
    "Pudukkottai",
    "Rajapalaiyam",
    "Ramanathapuram",
    "Salem",
    "Thanjavur",
    "Tiruchchirappalli",
    "Tirunelveli",
    "Tiruppur",
    "Tuticorin",
    "Udhagamandalam",
    "Vellore",
  ],
  "Telangana": [
    "Hyderabad",
    "Karimnagar",
    "Khammam",
    "Mahbubnagar",
    "Nizamabad",
    "Sangareddi",
    "Warangal",
  ],
  "Tripura": ["Agartala"],
  "Uttar Pradesh": [
    "Agra",
    "Aligarh",
    "Amroha",
    "Ayodhya",
    "Azamgarh",
    "Bahraich",
    "Ballia",
    "Banda",
    "Bara Banki",
    "Bareilly",
    "Basti",
    "Bijnor",
    "Bithur",
    "Budaun",
    "Bulandshahr",
    "Deoria",
    "Etah",
    "Etawah",
    "Faizabad",
    "Farrukhabad-cum-Fatehgarh",
    "Fatehpur",
    "Fatehpur Sikri",
    "Ghaziabad",
    "Ghazipur",
    "Gonda",
    "Gorakhpur",
    "Hamirpur",
    "Hardoi",
    "Hathras",
    "Jalaun",
    "Jaunpur",
    "Jhansi",
    "Kannauj",
    "Kanpur",
    "Lakhimpur",
    "Lalitpur",
    "Lucknow",
    "Mainpuri",
    "Mathura",
    "Meerut",
    "Mirzapur-Vindhyachal",
    "Moradabad",
    "Muzaffarnagar",
    'Noida',
    'Greater Noida',
    "Partapgarh",
    "Pilibhit",
    "Prayagraj",
    "Rae Bareli",
    "Rampur",
    "Saharanpur",
    "Sambhal",
    "Shahjahanpur",
    "Sitapur",
    "Sultanpur",
    "Tehri",
    "Varanasi",
  ],
  "Uttarakhand": [
    "Almora",
    "Dehra Dun",
    "Haridwar",
    "Khatima",
    "Mussoorie",
    "Nainital",
    "Pithoragarh",
  ],
  "West Bengal": [
    "Alipore",
    "Alipur Duar",
    "Asansol",
    "Baharampur",
    "Bally",
    "Balurghat",
    "Bankura",
    "Baranagar",
    "Barasat",
    "Barrackpore",
    "Basirhat",
    "Bhatpara",
    "Bishnupur",
    "Budge Budge",
    "Burdwan",
    "Chandernagore",
    "Darjiling",
    "Diamond Harbour",
    "Dum Dum",
    "Durgapur",
    "Halisahar",
    "Haora",
    "Hugli",
    "Ingraj Bazar",
    "Jalpaiguri",
    "Kalimpong",
    "Kamarhati",
    "Kanchrapara",
    "Kharagpur",
    "Koch Bihar",
    "Kolkata",
    "Krishnanagar",
    "Malda",
    "Midnapore",
    "Murshidabad",
    "Navadwip",
    "Palashi",
    "Panihati",
    "Purulia",
    "Raiganj",
    "Santipur",
    "Shantiniketan",
    "Shrirampur",
    "Siliguri",
    "Siuri",
    "Tamluk",
    "Titagarh",
  ]
};

export function getCity(state) {
  return state ? cityStates[state] : [];
}

export function getStates() {
  return Object.keys(cityStates);
}
