import React from "react";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { theme } from "../styles/custom-theme-style";
// import { getCity } from "../util/city-state-util";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Accordion from "@material-ui/core/Accordion";
import {
  getApplicator,
  getBrands,
  getBusinessChances,
  getBusinessProjects,
  getContractorType,
  getSubCategories,
} from "../util/category";
import LocationComponent from "../pages/location";
import TextField from "@material-ui/core/TextField";
import { FormControlLabel, Radio, withStyles } from "@material-ui/core";
import _ from "lodash";
import config from "../config";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import PopUpBackground from "../assets/images/popup-background.svg";
import ThumbsUp from "../assets/icons/thumbs-up.svg";
import Modal from "@material-ui/core/Modal";
import * as emailValidator from "email-validator";
import sessionService from "../services/session-service";
import beatBusinessService from "../services/beat-business-service";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import "../styles/rseForm.css";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { size } from "lodash";
import GAEventTracker from "../services/GAEventTracker";
import * as moment from 'moment';

const useGAEventTracker = GAEventTracker("Page Transition");

const pageHeaders = [
  "BUSINESS INFORMATION",
  "OWNER INFORMATION",
  "LOCATION CAPTURE",
  "BUSINESS DOCUMENTS",
  "CUSTOMER PROFILE",
  "BUSINESS INFORMATION",
];

const businessDocType = {
  GST_FILE: "gstFile",
  PAN_FILE: "panFile",
  OTHER_FILE: "otherFile",
  OUTSIDE_OUTLET_FILE: "outsideOutletFile",
  INSIDE_OURLET_FILE: "insideOutletFile",
  VISITING_CARD_FILE: "visitingCardFile",
  BUSINESS_DOCUMENT_FILE: "businessDocumentFile",
};

const salesPersonType = {
  RESELLER: "RESELLER",
  CONTRACTOR: "CONTRACTOR",
};

const styles = {
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary1Color,
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary1Color,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary1Color,
    },
    "& .MuiInputLabel-outlined": {
      color: theme.palette.primary1Color,
    },
    "&:hover .MuiInputLabel-outlined": {
      color: theme.palette.primary1Color,
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: theme.palette.primary1Color,
    },
  },
  container: {
    width: "60%",
    margin: "0 auto",
    marginTop: 100,
    "@media (min-width:0px) and (max-width:700px)": {
      width: "80%",
    },
  },
  next: {
    marginLeft: ".5rem",
    backgroundColor: theme.palette.primary1Color,
    borderWidth: 1,
    borderRadius: 0,
    width: 140,
    borderColor: theme.palette.primary1Color,
    color: "white",
    "@media (min-width:0px) and (max-width:700px)": {
      width: 100,
    },
    "&:hover": {
      borderColor: theme.palette.primary1Color,
      backgroundColor: theme.palette.primary1Color,
    },
  },
  flexWrap: {
    width: "100%",
    flex: "0 50%",
    marginTop: 10,
    marginBottom: 10,
    "@media (min-width:0px) and (max-width:600px)": {
      flex: "0 100%",
    },
    "@media (min-width:700px) and (max-width:900px)": {
      flex: "0 50%",
    },
  },
  bussinesDocPosition: {
    display: "flex",
    justifyContent: "space-between",
    width: "91%",
    "@media (min-width:0px) and (max-width:600px)": {
      flexDirection: "column",
    },
  },
  documentUpload: {
    display: "flex",
    alignContent: "center",
    flexDirection: "column",
    width: "40%",
    "@media (min-width:0px) and (max-width:600px)": {
      width: "75%",
    },
  },
  rejectedBusiness: {
    fontFamily: "Nunito",
    backgroundColor: "white",
    margin: "0 auto",
    marginTop: 100,
    width: "35%",
    padding: 20,
    textAlign: "center",
    backgroundImage: `url(${PopUpBackground})`,
    "@media (min-width:0px) and (max-width:600px)": {
      width: "70%",
    },
  },
};

const categories = [
  "Cement",
  "Steel",
  "Sanitary Ware",
  "Bath Fittings & Accessories",
  "Plumbing Pipes and Fitings",
  "Electricals",
  "Lighting",
  "Tiles",
  "Stones",
  "Plywood",
  "Hardware",
  "Paints",
  "Modular Kitchens",
  "Home Automation",
  "Other Category",
];


class RseForm extends React.Component {
  constructor(props) {
    super(props);
    const selectedCategories =
      (this.props.data.form && this.props.data.form.categories) || [];
    const otherCategory = _.difference(selectedCategories, categories);
    const otherCategoryValue = otherCategory.length ? otherCategory[0] : "";
    const form = {
      ...this.props.data.form,
      ownerAddressLine1:
        (this.props.data.form &&
          (this.props.data.form.ownerAddressLine1 ||
            this.props.data.form.addressLine1)) ||
        "",
      ownerAddressLine2:
        (this.props.data.form &&
          (this.props.data.form.ownerAddressLine2 ||
            this.props.data.form.addressLine2)) ||
        "",
      ownerState:
        (this.props.data.form &&
          (this.props.data.form.ownerState || this.props.data.form.state)) ||
        "",
      ownerCity:
        (this.props.data.form &&
          (this.props.data.form.ownerCity || this.props.data.form.city)) ||
        "",
      ownerPincode:
        (this.props.data.form &&
          (this.props.data.form.ownerPincode ||
            this.props.data.form.pincode)) ||
        "",
    };
    this.state = {
      hasNewImages: false,
      hasTriedBusinessLoad: false,
      businessId: props.businessId,
      selectedStateCities:[],
      allStates:[],
      gstFile: "",
      panFile: "",
      otherFile: "",
      outsideOutletFile: "",
      insideOutletFile: "",
      visitingCardFile: "",
      shopActiveDays : [{id:0,name:"Sun",checked:false},{id:1,name:"Mon",checked:false},{id:2,name:"Tue",checked:false},{id:3,name:"Wed",checked:false},{id:4,name:"Thu",checked:false},{id:5,name:"Fri",checked:false},{id:6,name:"Sat",checked:false},{id:7,name:"Open 7 Days",checked:false}],
      businessDocumentType:
        _.get(this.props.data, "form.businessDocumentType") || "",
      businessDocumentFile: "",
      pageLoad : false,
      selectedCategories:
        (this.props.data.form && this.props.data.form.categories) || [],
      otherCategoryValue,
      selectedSubCategories:
        (this.props.data.form && this.props.data.form.subCategories) || [],
      selectedBrands:
        (this.props.data.form && this.props.data.form.brands) || [],
      contractorTypes: [],
      shopOpeningDays: [],
      otherContractors: null,
      businessInformation: {
        numOfProjectsPerYear:
          _.get(
            this.props.data,
            "form.businessInformation.numOfProjectsPerYear"
          ) || "",
        valuePerProject:
          _.get(this.props.data, "form.businessInformation.valuePerProject") ||
          "",
        materialValuePerProject:
          _.get(
            this.props.data,
            "form.businessInformation.materialValuePerProject"
          ) || "",
      },
      applicatorTypes: this.props.data.form.applicatorTypes || [],
      applicatorData: this.props.data.form.applicatorTypes || [],
      ...this.props.data,
      form,
      error: {},
      pg: 1,
      loading: false,
      uploadType: "documentUpload",
      salesPersonDetails: this.props.salesPersonDetails,
      customerType: _.get(this.props.salesPersonDetails, "userType.name"),
      modeOfRegistration: new URL(document.location).searchParams.get("mode"),
      contractorReferral1:
        _.get(this.props.data, "form.contractorReferrals[0]") || {},
      contractorReferral2:
        _.get(this.props.data, "form.contractorReferrals[1]") || {},
      validGST: !_.isEmpty(_.get(this.props.data, "form.gstAddress", '')) && !_.isEmpty(_.get(this.props.data, "form.tradeName", '')),
      validPAN: !_.isEmpty(_.get(this.props.data, "form.tradeName", '')),
      gstAddress: _.get(this.props.data, "form.gstAddress", ''),
      tradeName: _.get(this.props.data, "form.tradeName", ''),
      gstAtStart: form.gstNumber,
      panAtStart: form.panNumber,
      validPANAtStart: !_.isEmpty(form.tradeName)
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.pageNumber !== this.props.pageNumber) {
      this.setState({ hasTriedBusinessLoad: false });
    }
    if(prevState.form.state !== this.state.form.state){
      this.getCityByState(this.state.form.state);
    }
  }

  verifyPincode = async(e)=>{
      e.persist();
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...this.state.form,
          pincode: e.target.value,
          ownerPincode: e.target.value,
        },
      }));
    if(e.target.value.length === 6){
      await fetch(`${config.beatApiNodeUrl}/business-state-city-pin/pincode/${e.target.value}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.beatUserToken}`,
          Accept: "application/json",
        },
        "body": JSON.stringify()
      }).then(response => {
        if (response.ok) {
          response.json().then(selectedRegion => {
            this.setState((prevState) => ({
              ...prevState,
              form: {
                ...this.state.form,
                pincode: e.target.value,
                ownerPincode: e.target.value,
                city:selectedRegion.city,
                ownerCity:selectedRegion.city,
                state:selectedRegion.state,
                ownerState:selectedRegion.state,
              },
            }));
          });
        }else{
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...this.state.form,
              pincode: e.target.value,
              ownerPincode: e.target.value,
              city:"",
              ownerCity:"",
              state:"",
              ownerState:"",
            },
          }));
          alert("Pincode Not Available")
        }
      }).catch(err=>err);
    }
  }

  getCityByState = async (selectedState)=>{
    await fetch(`${config.beatApiNodeUrl}/business-state-city-pin/city/${selectedState}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.beatUserToken}`,
        Accept: "application/json",
      },
      "body": JSON.stringify()
    }).then(response => {
      if (response.ok) {      

        response.json().then(json => {
          this.setState((prevState)=>({
            ...prevState,
            selectedStateCities:json
          }));
        });
      }
    }).catch(err=>err);
  }

  getAllStates = async ()=>{
    await fetch(`${config.beatApiNodeUrl}/business-state-city-pin/state`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.beatUserToken}`,
          Accept: "application/json",
        },
        "body": JSON.stringify()
      }).then(response => {
        if (response.ok) {
          response.json().then(json => {
            this.setState((prevState)=>({...prevState,allStates:json}));
          });
        }
      }).catch(err=>err);
    
}
  componentDidMount() {
    this.getAllStates()
    const { data } = this.props;
    const { form } = data;
    const { contractorTypes } = form;
    let filteredContractor = _(contractorTypes)
      .filter(_.some)
      .filter((contractors) => contractors.value.length)
      .value();
    if (filteredContractor.length)
      this.setState({ contractorTypes: contractorTypes });
    else this.setState({ contractorTypes: [] });
    if(this.state.form.state){
      this.getCityByState(this.state.form.state);
    }
    let existingHolidays = this.props.data.form.weeklyHoliday || [];
     let updatedActiveDays = this.state.shopActiveDays.map((weekDay)=>{
       if(existingHolidays.includes(weekDay.name)){
         weekDay.checked = true;
       }
       return {
         ...weekDay
       }
     });
     this.setState({shopActiveDays:updatedActiveDays})
  }

  getBackButton() {
    const { setPageNumber, pageNumber, classes } = this.props;
    // const {uploadType,pg}=this.state
    return (
      <div>
        <Button
          disabled={this.state.hasNewImages}
          // disabled={pageNumber === initialPageNumber()}
          onClick={() => {
            if (
              this.state.modeOfRegistration === "Telephonic" &&
              pageNumber === 4 &&
              this.state.customerType === "CONTRACTOR"
            )
              setPageNumber(pageNumber - 2);
            else setPageNumber(pageNumber - 1);
          }}
          className={classes.next}
          variant="outlined"
          color="primary"
          size="medium"
          type="submit"
        >
          Back
        </Button>
      </div>
    );
  }

  getNextButton(validatorFn) {
    const { setPageNumber, pageNumber, classes, rejectReasonId } = this.props;
    // const isLastPage = pageNumber === maximumPageNumber();
    // debugger
      // weekly_holiday
    const { applicatorData, applicatorTypes, customerType } = this.state;
    let applicatorDetails = _.cloneDeep(applicatorTypes);
    if (!applicatorData.length)
      applicatorDetails.map((types) => delete types.id);
    return (
      <div>
        <Button
          className={classes.next}
          onClick={() => {
            let GATrackStartDate = new Date();

            if (validatorFn && !validatorFn()) {
              return;
            }
            window.scrollTo(0, 0);
            let {
              error,
              createdAt,
              selectedCategories,
              contractorTypes,
              updatedAt,
              selectedSubCategories,
              selectedBrands,
              gstFile,
              panFile,
              otherFile,
              outsideOutletFile,
              insideOutletFile,
              visitingCardFile,
              id,
              businessInformation,
              applicatorTypes,
              otherCategoryValue,
              contractorReferral1,
              contractorReferral2,
              businessDocumentFile,
              businessDocumentType,
              ...data
            } = this.state;
            if (otherCategoryValue) {
              const index = _.findIndex("Other Category");
              selectedCategories.splice(index, 1, otherCategoryValue);
            }
            let contractorReferrals = [];
            if (!_.isEmpty(contractorReferral1)) {
              contractorReferrals = [contractorReferral1];
            }
            if (!_.isEmpty(contractorReferral2)) {
              contractorReferrals = [
                ...contractorReferrals,
                contractorReferral2,
              ];
            }
            if (
              this.state.uploadType === "reference" ||
              this.state.uploadType === "documentUpload"
            )
              this.setState({ ...this.state, loading: true, pageLoad : true });
            if (this.state.pg === 2 && this.state.uploadType === "reference") {
              if (this.validateReferenceType()) {
                return false;
              }
            }
            data.tenantId = config.tenantId;
            data.mobile = this.props.userNumber;
            data.rejectReasonId = this.props.rejectReasonId;
            data.otherRejectReason = this.props.otherRejectReason;
            data.status = "SEMI_SUBMITTED";
            data.tokenObj = this.props.tokenObj;
            data.form = {
              formStatus: "SEMI_SUBMITTED",
              ...data.form,
              mobile: this.props.userNumber,
              categories: selectedCategories,
              subCategories: selectedSubCategories,
              brands: selectedBrands,
              contractorTypes: contractorTypes,
              applicatorTypes: applicatorDetails,
              address: {
                addressLine1: data.form.addressLine1,
                addressLine2: data.form.addressLine2,
                city: data.form.city,
                state: data.form.state,
                pincode: data.form.pincode,
              },
              contractorReferrals,
              businessDocumentFile,
              businessDocumentType,
              gstFile: gstFile,
              panFile: panFile,
              otherFile: otherFile,
              outsideOutletFile: outsideOutletFile,
              insideOutletFile: insideOutletFile,
              visitingCardFile: visitingCardFile,
              gstAddress: this.state.gstAddress,
              tradeName: this.state.tradeName
            };
            let fieldsToRemove = _.values(businessDocType);
            if (!this.state.hasNewImages) {
              _.each(fieldsToRemove, (fieldToRemove) => {
                _.set(data, fieldToRemove, undefined);
                _.set(data, `form.${fieldToRemove}`, undefined);
              });
            }
            let shopLeaveDays = this.state.shopActiveDays.filter((obj)=> obj.checked).map((obj)=>obj.name);
            data.form.weeklyHoliday = shopLeaveDays;
            delete data.allStates;
            delete data.shopActiveDays;
            delete data.selectedStateCities;
            fetch(`${config.ruleServerUrl}/dalmia/save`, {
              method: "POST",
              body: JSON.stringify(data),
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            })
              .then((res) => {
                this.writeLogs("Customer creation Form","form submission status : SEMI_SUBMITTED");
                return res.ok ? res.json() : Promise.reject(res.json());
              })
              .then((res) =>
                sessionService.getUserInfo().then((res) => {
                  this.setState({ hasNewImages: false });
                  if (
                    this.state.modeOfRegistration === "Telephonic" &&
                    pageNumber === 2 &&
                    this.state.customerType === "CONTRACTOR"
                  ) {
                    this.setState(res, () => setPageNumber(pageNumber + 2));
                  } else res = { ...res, loading: false };
                  this.setState(res, () => {
                    setPageNumber(
                      rejectReasonId && customerType === "CONTRACTOR"
                        ? 5
                        : pageNumber + 1
                    );
                  });
                })
              )
              .catch((err) => console.log(err))
              .finally(()=>{
                this.setState({ ...this.state, pageLoad : false });

                let salesPersonEmail = _.replace(this.state.salesPersonDetails.email, '@', '!'); 
                let GATrackEndDate   = new Date();
                let secondsDiff = (GATrackEndDate.getTime() - GATrackStartDate.getTime());

                let dateAndEmail = moment().format("DD-MM-YYYY hh:mm:ss.SSS").toString() + " " + salesPersonEmail
                useGAEventTracker(pageHeaders[pageNumber - 1], dateAndEmail, secondsDiff)
              })
          }}
          variant="outlined"
          color="secondary"
          size="medium"
        >
          Next
        </Button>
      </div>
    );
  }
  
  writeLogs(page,activity){
    let logData = {kam_email:this.props.data.employeeTag,visited_page:page,activity_description:activity}
    fetch(`${config.beatApiNodeUrl}/user-log/writelog`, {
      method: "POST",
      body: JSON.stringify(logData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.beatUserToken}`,
        Accept: "application/json",
      },
    })
      .then((res) => {
        return res.ok ? res.json() : Promise.reject(res.json());
      }).catch((err) => console.log(err));
  }

  getSaveButton(validatorFn) {
    // if(pageNumber === maximumPageNumber()) {
      let shopLeaveDays = this.state.shopActiveDays.filter((obj)=> obj.checked).map((obj)=>obj.name);
    const { classes } = this.props;
    return (
      <div>
        <Button
          className={classes.next}
          onClick={() => {
            if (validatorFn && !validatorFn()) {
              return;
            }
            let {
              error,
              createdAt,
              selectedCategories,
              updatedAt,
              selectedSubCategories,
              selectedBrands,
              gstFile,
              panFile,
              id,
              contractorTypes,
              businessInformation,
              applicatorTypes,
              ...data
            } = this.state;
            const status = this.props.rejectReasonId
              ? "SEMI_SUBMITTED"
              : "FULL_SUBMITTED";
            data.tenantId = config.tenantId;
            data.mobile = this.props.userNumber;
            data.rejectReasonId = this.props.rejectReasonId;
            data.otherRejectReason = this.props.otherRejectReason;
            data.status = status;
            data.tokenObj = this.props.tokenObj;
            data.form = {
              formStatus: status,
              ...data.form,
              categories: selectedCategories,
              subCategories: selectedSubCategories,
              brands: selectedBrands,
              contractorTypes: contractorTypes,
              applicatorTypes: applicatorTypes,
              address: {
                addressLine1: data.form.addressLine1,
                addressLine2: data.form.addressLine2,
                city: data.form.city,
                state: data.form.state,
                pincode: data.form.pincode,
              },
            };
            data.form.weeklyHoliday = shopLeaveDays;
            delete data.allStates;
            delete data.shopActiveDays;
            delete data.selectedStateCities;
            fetch(`${config.ruleServerUrl}/dalmia/save`, {
              method: "POST",
              body: JSON.stringify(data),
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            })
              .then((res) => {
                this.writeLogs("Customer Creation Form",`form submission status : ${status}`);
                return res.ok ? res.json() : Promise.reject(res.json());
              })
              .then((res) =>
                sessionService.getUserInfo().then((res) => {
                  this.setState(res, () =>
                    this.setState({ shouldShowSummary: true })
                  );
                })
              )
              .catch((err) => console.log(err));
          }}
          variant="outlined"
          color="secondary"
          size="medium"
        >
          Save
        </Button>
      </div>
    );
    // }
  }

  verifyGST = async (gst) => {
    const VALID_GST = "Valid GSTIN";
    const data = {
      gstin: gst,
      include_filing_data: false,
      consent: "Y"
    };

    const result = await fetch(`${config.gridlinesURL}/gstin-api/fetch-lite`, {
      "method": "POST",
      "headers": {
        "content-type": "application/json",
        "x-api-key": config.gridlinesAPIKey,
        "x-auth-type": "API-Key"
      },
      "body": JSON.stringify(data)
    })
    .then(response => {
      return response.json();
    })
    .catch(err => {
      console.error("error in gst validation:", err);
    });

    if (result) {
      const tradeName = _.get(result, 'data.gstin_data.trade_name');
      const gstAddress = _.get(result, 'data.gstin_data.principal_address.address');
      const message = _.get(result, 'data.message');
      const panNumber = _.get(result, 'data.gstin_data.pan');
      const splitAddr = gstAddress && gstAddress.split(',');
      const gstState = splitAddr && _.trim(splitAddr[splitAddr.length - 2]);
      const isFullySubmitted = this.state.form.status === "FULL_SUBMITTED";
      const isDifferentPAN = !_.isEmpty(this.state.panAtStart) && (this.state.panAtStart !== panNumber);
      if (message === VALID_GST && this.state.form.state === gstState) {
        if (isFullySubmitted && isDifferentPAN) {
          this.setState((prevState) => ({
            ...prevState,
            error: {
              gstNumber: 'GST cannot be validated as verified PAN is different. Please enter a GST that matches PAN.'
            }
          }));
          return;
        }
        this.setState((prevState) => ({
          ...prevState,
          validGST: true,
          validPAN: true,
          gstAddress,
          tradeName,
          form: { 
            ...prevState.form,
            panNumber
          },
          error: {}
        }));
      } else {
        const error = {};
        if (message === VALID_GST) {
          error.gstNumber = 'GST cannot be validated as state is different. In this case a new account needs to be opened.';
        } else {
          error.gstNumber = 'Invalid GST';
        }
        this.setState(prev => ({...prev, error : {...prev.error, ...error}}));
      }
    }
  }

  verifyPAN = async (panNumber) => {
    const VALID_PAN = "Valid PAN.";
    const data = {
      pan_number: panNumber,
      consent: "Y"
    };

    const result = await fetch(`${config.gridlinesURL}/pan-api/fetch`, {
      "method": "POST",
      "headers": {
        "content-type": "application/json",
        "x-api-key": config.gridlinesAPIKey,
        "x-auth-type": "API-Key"
      },
      "body": JSON.stringify(data)
    })
    .then(response => {
      return response.json();
    })
    .catch(err => {
      console.error("error in PAN validation:", err);
    });

    if (result) {
      const tradeName = _.get(result, 'data.pan_data.name');
      const message = _.get(result, 'data.message');
      if (message === VALID_PAN) {
        this.setState((prevState) => ({
          ...prevState,
          validPAN: true,
          tradeName,
          error: {}
        }));
      } else {
        this.setState(prev => ({
          ...prev,
          error: {
            ...prev.error,
            panNumber: 'Invalid PAN'
          }
        }));
      }
    }
  }

  verifiedCheckMark = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" stroke="white" fill="#F47E1F">
        <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm-1.25 16.518-4.5-4.319 1.396-1.435 3.078 2.937 6.105-6.218 1.421 1.409-7.5 7.626z" />
      </svg>
    );
  }
  onChangeShoppingDays = (event)=>{
    let bkpShopDays = [...this.state.shopActiveDays];
    let checkedItem = event.target.id;
    let offDays = bkpShopDays.filter((days)=> days.checked !==false);
    /* eslint-disable */
    if(checkedItem === "7"){
      /* eslint-disable */
      bkpShopDays.map((day,id)=>{
        /* eslint-disable */
        if(day.id.toString() === checkedItem){
          /* eslint-disable */
          bkpShopDays[checkedItem].checked = !bkpShopDays[checkedItem].checked;
          return;
        }else{
          /* eslint-disable */
          bkpShopDays[id].checked = false;
        }
      })
        /* eslint-disable */
    }else{
      if(offDays.length > 1 && !bkpShopDays[checkedItem].checked){
         alert("more than two days not allowed")
      }else{
        bkpShopDays[checkedItem].checked = !bkpShopDays[checkedItem].checked;
        bkpShopDays[7].checked =false;
      }
    }
    this.setState({shopActiveDays:bkpShopDays});
  }
  
  getPage1 = () => {
    const { classes, rejectReasonId } = this.props;
    const { form, error, customerType } = this.state;
    const {
      businessName,
      addressLine1,
      addressLine2,
      state,
      city,
      pincode,
      gstNumber,
      panNumber,
      businessLicenseNumber,
      businessLicenseName,
    } = form || {};

    const isFullSubmitted = this.state.form.status === "FULL_SUBMITTED";
    const disableGST = isFullSubmitted && !_.isEmpty(this.state.gstAtStart);
    const disablePAN = this.state.validGST || (this.state.validPANAtStart && isFullSubmitted)
    const {allStates,selectedStateCities} = this.state;
    let isVerifiedGST = this.props.data.form.gstNumber;
    return (
      <>
        <h3
          style={{
            marginTop: 50,
            textTransform: "uppercase",
            textAlign: "center",
          }}
        >
          {pageHeaders[0]}
        </h3>
        <TextField
          label="*Company Name"
          variant="outlined"
          value={businessName || ""}
          error={error.businessName}
          placeholder="minimum of 3 characters"
          helperText={error.businessName}
          style={{ width: "100%", marginTop: 15 }}
          className={classes.root}
          onChange={(e) => {
            e.persist();
            this.setState((prevState) => ({
              ...prevState,
              form: { ...form, businessName: e.target.value },
            }));
          }}
        />
        <TextField
          label="*Address Line 1"
          variant="outlined"
          placeholder="maximum of 40 characters"
          value={addressLine1 || ""}
          error={error.addressLine1}
          helperText={error.addressLine1}
          style={{ width: "100%", marginTop: 15 }}
          className={classes.root}
          onChange={(e) => {
            e.persist();
            let value = e.target.value;
            this.setState((prevState) => ({
              ...prevState,
              form: {
                ...form,
                addressLine1: value,
                ownerAddressLine1: value,
              },
            }));
            if (size(value) > 40) {
              error.addressLine1 =
                "Address Line1 - maximum of 40 characters is allowed";
              return;
            }

            error.addressLine1 = "";
          }}
        />
        <TextField
          label="Address Line 2"
          variant="outlined"
          placeholder="maximum of 40 characters"
          value={addressLine2 || ""}
          error={error.addressLine2}
          helperText={error.addressLine2}
          style={{ width: "100%", marginTop: 15 }}
          className={classes.root}
          onChange={(e) => {
            e.persist();
            let value = e.target.value;

            this.setState((prevState) => ({
              ...prevState,
              form: {
                ...form,
                addressLine2: e.target.value,
                ownerAddressLine2: e.target.value,
              },
            }));

            if (size(value) > 40) {
              error.addressLine2 =
                "Address Line2 - maximum of 40 characters is allowed";
              return;
            }
            error.addressLine2 = "";
          }}
        />
        <div style={{ marginTop: 15, textAlign: "left" }}>
          <FormControl
            error={error.state}
            className={classes.select}
            style={{ width: "100%" }}
            disabled={isVerifiedGST ? true:false}
          >
            <InputLabel
              style={{ marginRight: 20, color: theme.palette.primary1Color }}
            >
              *State
            </InputLabel>
            <Select
              displayEmpty
              disabled
              style={{ width: "100%" }}
              value={state + ""}
              onChange={(e) => {
                e.persist();
                this.setState((prevState) => ({
                  ...prevState,
                  form: {
                    ...form,
                    state: e.target.value,
                    ownerState: e.target.value,
                  },
                }));
              }}
            >
               {
              allStates.length ?
              allStates.map((displayValue) => {
                return <MenuItem value={displayValue.name}>{displayValue.name}</MenuItem>
              }) : <MenuItem value="">None</MenuItem>

            }
            </Select>
            <FormHelperText>{error.state}</FormHelperText>
          </FormControl>
        </div>
        <div style={{ marginTop: 15, textAlign: "left" }}>
          <FormControl error={error.city} style={{ width: "100%" }} disabled={isVerifiedGST ? true:false}>
            <InputLabel
              style={{ marginRight: 20, color: theme.palette.primary1Color }}
            >
              *City
            </InputLabel>
            <Select
              displayEmpty
              disabled
              style={{ width: "100%" }}
              value={city + ""}
              onChange={(e) => {
                e.persist();
                this.setState((prevState) => ({
                  ...prevState,
                  form: {
                    ...form,
                    city: e.target.value,
                    ownerCity: e.target.value,
                  },
                }));
              }}
            >
              {selectedStateCities.length ? _.map(selectedStateCities, (displayValue) => {
                return <MenuItem value={displayValue.name}>{displayValue.name}</MenuItem>;
              }):<MenuItem value=""></MenuItem>}
            </Select>
            <FormHelperText>{error.city}</FormHelperText>
          </FormControl>
        </div>
        <TextField
          label="*Pin Code"
          type="number"
          disabled={isVerifiedGST ? true:false}
          variant="outlined"
          value={pincode + ""}
          error={error.pincode}
          helperText={error.pincode}
          style={{ width: "100%", marginTop: 15 }}
          className={classes.root}
          onChange={this.verifyPincode}
        />
        {!rejectReasonId && (
          <>
            <div style={{ width: "100%", marginTop: 15, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <TextField
                label="GST Number"
                variant="outlined"
                error={error.gstNumber}
                helperText={error.gstNumber}
                value={gstNumber && gstNumber + ""}
                disabled={disableGST}
                style={{ width: "80%"}}
                className={classes.root}
                onChange={(e) => {
                  e.persist();
                  const isPANVerified = isFullSubmitted && !_.isEmpty(this.state.panAtStart);
                  const PANUpdate = isPANVerified ? {} : { validPAN: false, tradeName: "" };
                  this.setState((prevState) => ({
                    ...prevState,
                    validGST: false,
                    gstAddress: "",
                    ...PANUpdate,
                    form: { 
                      ...form, 
                      gstNumber: _.toUpper(e.target.value),
                      ...(isPANVerified ? {} : { panNumber: "" })
                    }
                  }));
                }}
              />
              {
                this.state.validGST?
                (
                  this.verifiedCheckMark()
                )
                :
                (
                  <button className={classes.next} style={{
                    cursor: "pointer",
                    width: "20%",
                    height: "100%",
                    fontSize: "1.5vw",
                    borderRadius: "5px"
                  }} onClick={() => this.verifyGST(gstNumber)}>VERIFY</button>
                )
              }
            </div>
            <div style={{ width: "100%", marginTop: 15, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <TextField
                label="PAN Number"
                variant="outlined"
                error={error.panNumber}
                helperText={error.panNumber}
                value={panNumber && panNumber + ""}
                style={{ width: "80%" }}
                disabled={disablePAN}
                className={classes.root}
                // https://stackoverflow.com/questions/50955603/react-material-ui-label-overlaps-with-text/65070465
                InputLabelProps={{ shrink: panNumber?true:false }}
                onChange={(e) => {
                  e.persist();
                  this.setState((prevState) => ({
                    ...prevState,
                    validPAN: false,
                    tradeName: "",
                    form: { ...form, panNumber: _.toUpper(e.target.value) },
                  }));
                }}
              />
              {
                this.state.validPAN?
                (
                  this.verifiedCheckMark()
                )
                :
                (
                  <button className={classes.next} style={{
                    cursor: "pointer",
                    width: "20%",
                    height: "100%",
                    fontSize: "1.5vw",
                    borderRadius: "5px"
                  }} onClick={() => this.verifyPAN(panNumber)}>VERIFY</button>
                )
              }
            </div>
            {
              !_.isEmpty(this.state.gstAddress) && (
                <TextField
                  label="GST Address"
                  variant="outlined"
                  value={this.state.gstAddress}
                  disabled={this.state.validGST}
                  style={{ width: "100%", marginTop: 15}}
                  className={classes.root}
                />
              )
            }
            {
              !_.isEmpty(this.state.tradeName) && (
                <TextField
                  label="GST/PAN Name"
                  variant="outlined"
                  value={this.state.tradeName}
                  disabled={this.state.validGST || this.state.validPAN}
                  style={{ width: "100%", marginTop: 15}}
                  className={classes.root}
                />
              )
            }
            <TextField
              label="Business License Number"
              variant="outlined"
              value={businessLicenseNumber && businessLicenseNumber + ""}
              style={{ width: "100%", marginTop: 15 }}
              className={classes.root}
              onChange={(e) => {
                e.persist();
                this.setState((prevState) => ({
                  ...prevState,
                  form: { ...form, businessLicenseNumber: e.target.value },
                }));
              }}
            />
            <TextField
              label="Business License Name"
              variant="outlined"
              value={businessLicenseName && businessLicenseName + ""}
              style={{ width: "100%", marginTop: 15 }}
              className={classes.root}
              onChange={(e) => {
                e.persist();
                this.setState((prevState) => ({
                  ...prevState,
                  form: { ...form, businessLicenseName: e.target.value },
                }));
              }}
            />
          </>
        )}
        {
          this.state.customerType === "RESELLER" ? <><p style={{color:"#F47E1F",fontSize:"18px"}}>Shop closure days</p>
          <div style={{display:"flex",flexWrap:"wrap"}}> 
           {this.state.shopActiveDays.map((day, index) => {
                    return (
                      <div
                        style={{
                          width: "100%",
                          flex: "0 50%",
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                      >
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div style={{ flex: 2 }}>
                            <input
                              type="checkbox"
                              // checked={contractorTypes.some(
                              //   (type) => type.id === category.id
                              // )}
                              checked = {day.checked}
                              onChange={this.onChangeShoppingDays}
                              id={day.id}
                            />
                          </div>
                          <div style={{ flex: 13, textAlign: "left" }}>
                            <label for={day.id}>
                              {this.state.shopActiveDays[index].name}
                              <br />
                            </label>
                          </div>
                        </div>
                      </div>
                    );
                  })}
          </div></> : <></>
        }
        <div style={{ marginTop: 20 }}>
          {rejectReasonId && customerType !== "CONTRACTOR"
            ? this.getSaveButton(() => this.validatePage1())
            : this.getNextButton(() => this.validatePage1())}
        </div>
      </>
    );
  };

  validatePage1 = () => {
    const { form, validGST, validPAN } = this.state;
    const {
      businessName,
      addressLine1,
      addressLine2,
      city,
      state,
      pincode,
      gstNumber,
      panNumber,
    } = form || {};
    const error = {};
    const addressRestrictionCharacterCount = 40;

    if (!businessName) {
      error.businessName = "*Business Name cannot be empty";
    }
    if (businessName && businessName.length < 3) {
      error.businessName = "Company Name* - Minimum of 3 characters required";
    }

    if (!addressLine1) {
      error.addressLine1 = "*Address Line1 Name cannot be empty";
    }
    if (
      addressLine1 &&
      addressLine1.length < 3
    ) {
      error.addressLine1 =
        "Address Line1 - minimum of 3 characters required";
    }
    if (
      addressLine1 &&
      addressLine1.length > addressRestrictionCharacterCount
    ) {
      error.addressLine1 =
        "Address Line1 - maximum of 40 characters is allowed";
    }

    if (
      addressLine2 &&
      addressLine2.length > addressRestrictionCharacterCount
    ) {
      error.addressLine2 =
        "Address Line2 - maximum of 40 characters is allowed";
    }

    if (!state) {
      error.state = "*State cannot be empty";
    }

    if (!city) {
      error.city = "*City cannot be empty";
    }

    if (isNaN(pincode)) {
      error.pincode = "*Pin Code can only contain numbers";
    }

    if (pincode && pincode.length !== 6) {
      error.pincode = "*Pin Code can only be 6 digits";
    }

    if (!pincode) {
      error.pincode = "*Pin Code cannot be empty";
    }

    // if (!gstNumber) {
    //   error.gstNumber = '*GST Number cannot be empty';
    // }

    let gstInputFormat = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[\d]{1}[Z]{1}[A-Z\d]{1}/.test(
      gstNumber
    );

    if (gstNumber && (gstNumber.length !== 15 || !gstInputFormat)) {
      error.gstNumber = `Please enter a valid GST Number`;
    }

    let panCardInputFormat = /[A-Z]{3}[PCAFHT]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$/.test(
      panNumber
    );
    if (panNumber && (panNumber.length !== 10 || !panCardInputFormat)) {
      error.panNumber = `Please enter a valid PAN Number`;
    }

    // if(!panNumber) {
    //   error.panNumber = '*PAN Number cannot be empty';
    // }

    if (!validGST && !_.isEmpty(gstNumber)) {
      error.gstNumber = 'Please verify GST Number'
    }
    if (!validPAN && !_.isEmpty(panNumber)) {
      error.panNumber = 'Please verify PAN Number'
    }

    this.setState({ error });
    return _.isEmpty(error);
  };

  getPage2 = () => {
    const { classes } = this.props;
    const { form, error,selectedStateCities,allStates } = this.state;
    const {
      firstName,
      middleName,
      lastName,
      ownerAddressLine1,
      addressLine1,
      ownerAddressLine2,
      addressLine2,
      ownerState,
      state,
      ownerCity,
      city,
      ownerPincode,
      pincode,
      email,
      mobile,
      alternateMobile,
    } = form || {};

    return (
      <>
        <h3
          style={{
            marginTop: 50,
            textTransform: "uppercase",
            textAlign: "center",
          }}
        >
          {pageHeaders[1]}
        </h3>
        <TextField
          label="*First Name"
          variant="outlined"
          value={firstName || ""}
          error={error.firstName}
          helperText={error.firstName}
          style={{ width: "100%", marginTop: 15 }}
          className={classes.root}
          onChange={(e) => {
            e.persist();
            this.setState((prevState) => ({
              ...prevState,
              form: { ...form, firstName: e.target.value },
            }));
          }}
        />
        <TextField
          label="Middle Name"
          variant="outlined"
          value={middleName || ""}
          error={error.middleName}
          helperText={error.middleName}
          style={{ width: "100%", marginTop: 15 }}
          className={classes.root}
          onChange={(e) => {
            e.persist();
            this.setState((prevState) => ({
              ...prevState,
              form: { ...form, middleName: e.target.value },
            }));
          }}
        />
        <TextField
          label="Last Name"
          variant="outlined"
          value={lastName || ""}
          error={error.lastName}
          helperText={error.lastName}
          style={{ width: "100%", marginTop: 15 }}
          className={classes.root}
          onChange={(e) => {
            e.persist();
            this.setState((prevState) => ({
              ...prevState,
              form: { ...form, lastName: e.target.value },
            }));
          }}
        />
        <TextField
          label="Email"
          variant="outlined"
          key={"email"}
          value={email}
          error={error.email}
          helperText={error.email}
          style={{ width: "100%", marginTop: 15 }}
          className={classes.root}
          onChange={(e) => {
            e.persist();
            this.setState((prevState) => ({
              ...prevState,
              form: { ...form, email: e.target.value },
            }));
          }}
        />
        <TextField
          label="*Mobile Number"
          variant="outlined"
          key={"mobile"}
          value={mobile}
          disabled
          style={{ width: "100%", marginTop: 15 }}
          className={classes.root}
          onChange={(e) => {
            e.persist();
            this.setState((prevState) => ({
              ...prevState,
              form: { ...form, mobile: e.target.value },
            }));
          }}
        />
        <TextField
          label="Alternate Mobile Number"
          variant="outlined"
          key={"alternateMobile"}
          value={alternateMobile || ""}
          error={error.alternateMobile}
          helperText={error.alternateMobile}
          style={{ width: "100%", marginTop: 15 }}
          className={classes.root}
          onChange={(e) => {
            e.persist();
            this.setState((prevState) => ({
              ...prevState,
              form: { ...form, alternateMobile: e.target.value },
            }));
          }}
        />
        <TextField
          label="*Address Line 1"
          variant="outlined"
          placeholder="maximum of 40 characters"
          key={"ownerAddressLine1"}
          value={ownerAddressLine1 || addressLine1 || ""}
          error={error.ownerAddressLine1}
          helperText={error.ownerAddressLine1}
          style={{ width: "100%", marginTop: 15 }}
          className={classes.root}
          onChange={(e) => {
            e.persist();
            let value = e.target.value;

            this.setState((prevState) => ({
              ...prevState,
              form: { ...form, ownerAddressLine1: e.target.value },
            }));

            if (size(value) > 40) {
              error.ownerAddressLine1 =
                "Address Line1 - maximum of 40 characters is allowed";
              return;
            }
            error.ownerAddressLine1 = "";
          }}
        />
        <TextField
          label="Address Line 2"
          variant="outlined"
          key={"ownerAddressLine2"}
          placeholder="maximum of 40 characters"
          value={ownerAddressLine2 || addressLine2 || ""}
          error={error.ownerAddressLine2}
          helperText={error.ownerAddressLine2}
          style={{ width: "100%", marginTop: 15 }}
          className={classes.root}
          onChange={(e) => {
            e.persist();
            let value = e.target.value;
            this.setState((prevState) => ({
              ...prevState,
              form: { ...form, ownerAddressLine2: e.target.value },
            }));

            if (size(value) > 40) {
              error.ownerAddressLine2 =
                "Address Line1 - maximum of 40 characters is allowed";
              return;
            }
            error.ownerAddressLine2 = "";
          }}
        />
        <div style={{ marginTop: 15, textAlign: "left" }}>
          <FormControl error={error.ownerState} style={{ width: "100%" }}>
            <InputLabel
              style={{ marginRight: 20, color: theme.palette.primary1Color }}
            >
              *State
            </InputLabel>
            <Select
              displayEmpty
              style={{ width: "100%" }}
              value={ownerState || state}
              onChange={(e) => {
                e.persist();
                this.setState((prevState) => ({
                  ...prevState,
                  form: { ...form, ownerState: e.target.value,ownerCity:"",city:"",pincode:"",ownerPincode:"" },
                }));
              }}
            >
               {
              allStates.length ?
              allStates.map((displayValue) => {
                return <MenuItem value={displayValue.name}>{displayValue.name}</MenuItem>
              }) : <MenuItem value="">None</MenuItem>

            }
            </Select>
            <FormHelperText>{error.ownerState}</FormHelperText>
          </FormControl>
        </div>
        <div style={{ marginTop: 15, textAlign: "left" }}>
          <FormControl error={error.ownerCity} style={{ width: "100%" }}>
            <InputLabel
              style={{ marginRight: 20, color: theme.palette.primary1Color }}
            >
              *City
            </InputLabel>
            <Select
              displayEmpty
              style={{ width: "100%" }}
              value={ownerCity || city}
              onChange={(e) => {
                e.persist();
                this.setState((prevState) => ({
                  ...prevState,
                  form: { ...form, ownerCity: e.target.value,ownerPincode:"",pincode:"" },
                }));
              }}
            >
               {selectedStateCities.length ? _.map(selectedStateCities, (displayValue) => {
                return <MenuItem value={displayValue.name}>{displayValue.name}</MenuItem>;
              }):<MenuItem value=""></MenuItem>}
              {/* <MenuItem value="">None</MenuItem>
              {_.map(getCity(ownerState), (displayValue) => {
                return (
                  <MenuItem value={displayValue + ""}>{displayValue}</MenuItem>
                );
              })} */}
            </Select>
            <FormHelperText>{error.ownerCity}</FormHelperText>
          </FormControl>
        </div>
        <TextField
          label="*Pin Code"
          variant="outlined"
          type="number"
          value={(ownerPincode || pincode) + ""}
          error={error.pincode}
          helperText={error.pincode}
          style={{ width: "100%", marginTop: 15 }}
          className={classes.root}
          onChange={this.verifyPincode}

          // onChange={(e) => {
          //   e.persist();
          //   this.setState((prevState) => ({
          //     ...prevState,
          //     form: { ...form, ownerPincode: e.target.value },
          //   }));
          // }}
        />
        <div
          style={{
            width: "90%",
            margin: "0 auto",
            marginTop: 20,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ flex: 1 }}>{this.getBackButton()}</div>
          <div style={{ flex: 1 }}>
            {this.getNextButton(() => this.validatePage2())}
          </div>
        </div>
      </>
    );
  };

  validatePage2 = () => {
    const { form } = this.state;
    const {
      firstName,
      ownerAddressLine1,
      ownerAddressLine2,
      ownerState,
      ownerCity,
      ownerPincode,
      email,
    } = form || {};
    const error = {};
    const addressRestrictionCharacterCount = 40;

    if (!firstName) {
      error.firstName = "Please Enter Your First Name";
    }

    if (!ownerAddressLine1) {
      error.ownerAddressLine1 = "*Address Line 1 cannot be empty";
    }
    if (
      ownerAddressLine1 &&
      ownerAddressLine1.length < 3
    ) {
      error.ownerAddressLine1 =
        "Address Line1 - minimum of 3 characters required";
    }

    if (
      ownerAddressLine1 &&
      ownerAddressLine1.length > addressRestrictionCharacterCount
    ) {
      error.ownerAddressLine1 =
        "Address Line1 - maximum of 40 characters is allowed";
    }

    if (
      ownerAddressLine2 &&
      ownerAddressLine2.length > addressRestrictionCharacterCount
    ) {
      error.ownerAddressLine2 =
        "Address Line2 - maximum of 40 characters is allowed";
    }

    if (!ownerState) {
      error.ownerState = "Please Select Your State";
    }

    if (!ownerCity) {
      error.ownerCity = "Please Enter Your City";
    }

    if (ownerPincode && ownerPincode.length !== 6) {
      error.ownerPincode = "Pin Code can only be 6 digits";
    }
    if (isNaN(ownerPincode)) {
      error.ownerPincode = "Pin Code can only contain numbers";
    }

    if (!ownerPincode) {
      error.ownerPincode = "Please Enter Your Pin Code";
    }

    if (email && !emailValidator.validate(email)) {
      error.email = "Please Enter a Valid Email ID";
    }

    // if(!email) {
    //   error.email =  'Please Enter Your Email ID';
    // }

    this.setState({ error });
    return _.isEmpty(error);
  };

  loadBusinessId() {
    if (this.state.hasTriedBusinessLoad) {
      return;
    }
    this.setState({ hasTriedBusinessLoad: true });
    if (!this.state.businessId) {
      sessionService.getUserInfo().then((userInfo) => {
        this.setState({ businessId: _.get(userInfo, "beatBusinessId") }, () => {
          this.loadSavedLocation();
        });
      });
    }
    this.loadSavedLocation();
  }

  loadSavedLocation() {
    if (this.state.businessId && !this.state.latitude) {
      beatBusinessService
        .getBeatBusiness(this.state.businessId)
        .then((beatBusiness) => {
          this.setState({
            latitude: _.get(beatBusiness, "lat"),
            longitude: _.get(beatBusiness, "lng"),
          });
        });
    }
  }

  getPage3 = () => {
    const { latitude, longitude, error, businessId } = this.state;
    this.loadBusinessId();
    return (
      <>
        <h3
          style={{
            marginTop: 50,
            textTransform: "uppercase",
            textAlign: "center",
          }}
        >
          {pageHeaders[2]}
        </h3>
        {!!businessId ? (
          <div>
            <LocationComponent
              key={`${latitude}-${longitude}`}
              latitude={latitude}
              longitude={longitude}
              businessId={businessId}
              setLocation={(latitude, longitude) =>
                this.setState({ latitude, longitude })
              }
            />
            {error && error.latitude && (
              <div style={{ fontSize: 12, color: "red", marginTop: 20 }}>
                {error.latitude}
              </div>
            )}
            <div
              style={{
                width: "90%",
                margin: "0 auto",
                marginTop: 20,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ flex: 1 }}>{this.getBackButton()}</div>
              <div style={{ flex: 1 }}>
                {this.getNextButton(() => this.validatePage3())}
              </div>
            </div>
          </div>
        ) : (
          <div className={"flex justify-center"}>
            <CircularProgress />
          </div>
        )}
      </>
    );
  };

  validatePage3 = () => {
    const { latitude, longitude } = this.state;
    const error = {};

    if (!latitude || !longitude) {
      error.latitude =
        "Please click on Get Location to capture current location";
    }

    this.setState({ error });
    return _.isEmpty(error);
  };

  handleTakePhotoAnimationDone = (dataUri) => {
    // Do stuff with the photo..
    this.setState({
      [`${this.state.currentFileType}`]: dataUri,
      hasNewImages: true,
    });
  };

  handleOpenCameraComponent = (fileType) => {
    this.setState({ currentFileType: fileType });
  };

  cameraComponent = () => {
    return _.isEmpty(this.state.currentFileType) ? (
      <div></div>
    ) : (
      <div>
        <p className={"cameraHeader"}>{`Capture ${_.startCase(
          this.state.currentFileType
        )}`}</p>
        <Camera
          onTakePhoto={(dataUri) => this.handleTakePhotoAnimationDone(dataUri)}
          imageType={IMAGE_TYPES.JPG}
          idealFacingMode={FACING_MODES.ENVIRONMENT}
          imageCompression={1}
          isImageMirror={false}
          sizeFactor={1}
        />
        <input
          type="file"
          accept="image/jpeg,image/png"
          onChange={(event) => {
            const reader = new FileReader();
            let file = event.target.files[0];
            if (file) {
              reader.readAsDataURL(event.target.files[0]);
            }
            reader.onloadend = () =>
              this.handleTakePhotoAnimationDone(reader.result);
          }}
        />
      </div>
    );
  };

  getImgDataByType = (type) => {
    // TODO: need to refactor
    if (type === businessDocType.GST_FILE) {
      return !_.isEmpty(this.state.gstFile)
        ? this.state.gstFile
        : _.isEmpty(this.state.form.gstFilePath)
        ? ""
        : `${config.ruleUiUrl}${this.state.form.gstFilePath}`;
    } else if (type === businessDocType.PAN_FILE) {
      return !_.isEmpty(this.state.panFile)
        ? this.state.panFile
        : _.isEmpty(this.state.form.panFilePath)
        ? ""
        : `${config.ruleUiUrl}${this.state.form.panFilePath}`;
    } else if (type === businessDocType.OTHER_FILE) {
      return !_.isEmpty(this.state.otherFile)
        ? this.state.otherFile
        : _.isEmpty(this.state.form.otherFilePath)
        ? ""
        : `${config.ruleUiUrl}${this.state.form.otherFilePath}`;
    } else if (type === businessDocType.OUTSIDE_OUTLET_FILE) {
      return !_.isEmpty(this.state.outsideOutletFile)
        ? this.state.outsideOutletFile
        : _.isEmpty(this.state.form.outsideOutletFilePath)
        ? ""
        : `${config.ruleUiUrl}${this.state.form.outsideOutletFilePath}`;
    } else if (type === businessDocType.INSIDE_OURLET_FILE) {
      return !_.isEmpty(this.state.insideOutletFile)
        ? this.state.insideOutletFile
        : _.isEmpty(this.state.form.insideOutletFilePath)
        ? ""
        : `${config.ruleUiUrl}${this.state.form.insideOutletFilePath}`;
    } else if (type === businessDocType.VISITING_CARD_FILE) {
      return !_.isEmpty(this.state.visitingCardFile)
        ? this.state.visitingCardFile
        : _.isEmpty(this.state.form.visitingCardFilePath)
        ? ""
        : `${config.ruleUiUrl}${this.state.form.visitingCardFilePath}`;
    } else if (type === businessDocType.BUSINESS_DOCUMENT_FILE) {
      return !_.isEmpty(this.state.businessDocumentFile)
        ? this.state.businessDocumentFile
        : _.isEmpty(this.state.form.businessDocumentPath)
        ? ""
        : `${config.ruleUiUrl}${this.state.form.businessDocumentPath}`;
    }
  };

  isImgDataAvailable = (type) => {
    return !_.isEmpty(this.getImgDataByType(type));
  };

  isSalesPersonReseller = () => {
    let { userType } = this.props.salesPersonDetails;
    return userType.name === salesPersonType.RESELLER;
  };

  getFileExtn = (file) => {
    if (file[0] === "d") return file.split(";")[0].split("/")[1];
    let splitString = file.split(".");
    return splitString[splitString.length - 1];
  };

  isSalesPersonContractor = () => {
    let { userType } = this.props.salesPersonDetails;
    return userType.name === salesPersonType.CONTRACTOR;
  };

  contractorDocumentUploadType = () => {
    const document = [
      { id: 1, type: "Visiting Card" },
      { id: 2, type: "Letter Head" },
      { id: 3, type: "Agreement copy with your customer" },
      { id: 4, type: "Any Sale Invoice (with your firm name)" },
      { id: 5, type: "Any Purchase Invoice (with your firm name)" },
      {
        id: 6,
        type:
          "Any letter of certificate or recognition from building material brand",
      },
      { id: 5, type: "Shop Establishment License" },
      { id: 6, type: "Labour License" },
      {
        id: 7,
        type: "GST Certificate with Firm Name (only for Government contractor)",
      },
    ];
    return document;
  };

  resellerDocumentUploadType = () => {
    const document = [
      { id: 1, type: "Visiting Card" },
      { id: 2, type: "Letter Head" },
      { id: 3, type: "Shop Establishment License" },
      { id: 4, type: "Any Sale Invoice (with your firm name)" },
      { id: 5, type: "Any Purchase Invoice (with your firm name)" },
      { id: 6, type: "Labour License" },
    ];
    return document;
  };

  getPage4 = () => {
    const { error, loading } = this.state;
    const { classes } = this.props;
    return (
      <>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <div>
              <h3
                style={{
                  marginTop: 50,
                  textTransform: "uppercase",
                  textAlign: "center",
                }}
              >
                {pageHeaders[3]}
              </h3>
              {this.cameraComponent()}
            </div>
            <div style={{ textAlign: "left" }}>
              {/* Reseller */}
              {this.isSalesPersonReseller() && this.props.data && (
                <div>
                  <div
                    style={{
                      marginTop: 20,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <label
                      className={"cameraFieldLabel itemCenter"}
                      style={{ fontSize: 18 }}
                    >
                      Shop Photograph
                    </label>
                  </div>
                  <div className={"cameraFieldsGrid"}>
                    <label
                      className={"cameraFieldLabel itemCenter"}
                      style={{ color: theme.palette.primary1Color }}
                    >
                      *Shop Exterior
                    </label>
                    <div className={"cameraFieldImageContainer itemCenter"}>
                      {this.isImgDataAvailable(
                        businessDocType.OUTSIDE_OUTLET_FILE
                      ) &&
                        (this.getImgDataByType(
                          businessDocType.OUTSIDE_OUTLET_FILE
                        )[0] === "h" ? (
                          <a
                            download={
                              "outside_outlet" +
                              this.getFileExtn(
                                this.getImgDataByType(
                                  businessDocType.OUTSIDE_OUTLET_FILE
                                )
                              )
                            }
                            href={this.getImgDataByType(
                              businessDocType.OUTSIDE_OUTLET_FILE
                            )}
                          >
                            <img
                              className={"cameraFieldImageContainer"}
                              src={this.getImgDataByType(
                                businessDocType.OUTSIDE_OUTLET_FILE
                              )}
                              alt={"outside_outlet"}
                            />
                          </a>
                        ) : (
                          <img
                            className={"cameraFieldImageContainer"}
                            src={this.getImgDataByType(
                              businessDocType.OUTSIDE_OUTLET_FILE
                            )}
                            alt={"outside_outlet"}
                          />
                        ))}
                    </div>
                    <div className={"itemCenter"}>
                      <CameraAltIcon
                        onClick={() =>
                          this.handleOpenCameraComponent(
                            businessDocType.OUTSIDE_OUTLET_FILE
                          )
                        }
                      />
                    </div>
                  </div>
                  {error.outsideOutletFile && (
                    <div
                      style={{
                        fontSize: 12,
                        color: "red",
                        textAlign: "center",
                        margin: 20,
                      }}
                    >
                      {error.outsideOutletFile}
                    </div>
                  )}
                  <div className={"cameraFieldsGrid"}>
                    <label
                      className={"cameraFieldLabel itemCenter"}
                      style={{ color: theme.palette.primary1Color }}
                    >
                      Shop Interior
                    </label>
                    <div className={"cameraFieldImageContainer itemCenter"}>
                      {this.isImgDataAvailable(
                        businessDocType.INSIDE_OURLET_FILE
                      ) &&
                        (this.getImgDataByType(
                          businessDocType.INSIDE_OURLET_FILE
                        )[0] === "h" ? (
                          <a
                            download={
                              "outside_outlet" +
                              this.getFileExtn(
                                this.getImgDataByType(
                                  businessDocType.INSIDE_OURLET_FILE
                                )
                              )
                            }
                            href={this.getImgDataByType(
                              businessDocType.INSIDE_OURLET_FILE
                            )}
                          >
                            <img
                              className={"cameraFieldImageContainer"}
                              src={this.getImgDataByType(
                                businessDocType.INSIDE_OURLET_FILE
                              )}
                              alt={"outside_outlet"}
                            />
                          </a>
                        ) : (
                          <img
                            className={"cameraFieldImageContainer"}
                            src={this.getImgDataByType(
                              businessDocType.INSIDE_OURLET_FILE
                            )}
                            alt={"outside_outlet"}
                          />
                        ))}
                    </div>
                    <div className={"itemCenter"}>
                      <CameraAltIcon
                        onClick={() =>
                          this.handleOpenCameraComponent(
                            businessDocType.INSIDE_OURLET_FILE
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className={classes.bussinesDocPosition}>
                    <label
                      className={"cameraFieldLabel itemCenter"}
                      style={{
                        color: theme.palette.primary1Color,
                        textAlign: "left",
                      }}
                    >
                      *Proof of Business Document{" "}
                    </label>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-mutiple-name-label">
                        Business Document
                      </InputLabel>
                      <Select
                        fullWidth
                        style={{ width: 250 }}
                        value={this.state.businessDocumentType || ""}
                        onChange={(e) =>
                          this.setState({
                            ...this.state,
                            businessDocumentType: e.target.value,
                          })
                        }
                      >
                        {_.map(
                          this.resellerDocumentUploadType(),
                          (document) => (
                            <option value={document.type} key={document.id}>
                              {document.type}
                            </option>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </div>
                  <div>
                    {this.state.businessDocumentType && (
                      <div className={"cameraFieldsGrid"}>
                        <label
                          className={"cameraFieldLabel itemCenter"}
                          style={{
                            color: theme.palette.primary1Color,
                            fontSize: 14,
                            textAlign: "left",
                          }}
                        >
                          {this.state.businessDocumentType}
                        </label>
                        <div className={"cameraFieldImageContainer itemCenter"}>
                          {this.isImgDataAvailable(
                            businessDocType.BUSINESS_DOCUMENT_FILE
                          ) &&
                            (this.getImgDataByType(
                              businessDocType.BUSINESS_DOCUMENT_FILE
                            )[0] === "h" ? (
                              <a
                                download={
                                  "customer_gst" +
                                  this.getFileExtn(
                                    this.getImgDataByType(
                                      businessDocType.BUSINESS_DOCUMENT_FILE
                                    )
                                  )
                                }
                                href={this.getImgDataByType(
                                  businessDocType.BUSINESS_DOCUMENT_FILE
                                )}
                              >
                                <img
                                  className={"cameraFieldImageContainer"}
                                  src={this.getImgDataByType(
                                    businessDocType.BUSINESS_DOCUMENT_FILE
                                  )}
                                  alt={"customer_gst"}
                                />
                              </a>
                            ) : (
                              <img
                                className={"cameraFieldImageContainer"}
                                src={this.getImgDataByType(
                                  businessDocType.BUSINESS_DOCUMENT_FILE
                                )}
                                alt={"customer_gst"}
                              />
                            ))}
                        </div>
                        <div className={"itemCenter"}>
                          <CameraAltIcon
                            onClick={() =>
                              this.handleOpenCameraComponent(
                                businessDocType.BUSINESS_DOCUMENT_FILE
                              )
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {error.businessDocumentFile && (
                    <div
                      style={{
                        fontSize: 12,
                        color: "red",
                        textAlign: "center",
                        margin: 20,
                      }}
                    >
                      {error.businessDocumentFile}
                    </div>
                  )}
                </div>
              )}
              {/* contractor */}
              {this.isSalesPersonContractor() && this.props.data && (
                <div>
                  <div className={"cameraFieldsGrid"}>
                    <label
                      className={"cameraFieldLabel itemCenter"}
                      style={{ color: theme.palette.primary1Color }}
                    >
                      Site / Office Photograph{" "}
                    </label>
                    <div className={"cameraFieldImageContainer itemCenter"}>
                      {this.isImgDataAvailable(
                        businessDocType.OUTSIDE_OUTLET_FILE
                      ) &&
                        (this.getImgDataByType(
                          businessDocType.OUTSIDE_OUTLET_FILE
                        )[0] === "h" ? (
                          <a
                            download={
                              "outside_outlet" +
                              this.getFileExtn(
                                this.getImgDataByType(
                                  businessDocType.OUTSIDE_OUTLET_FILE
                                )
                              )
                            }
                            href={this.getImgDataByType(
                              businessDocType.OUTSIDE_OUTLET_FILE
                            )}
                          >
                            <img
                              className={"cameraFieldImageContainer"}
                              src={this.getImgDataByType(
                                businessDocType.OUTSIDE_OUTLET_FILE
                              )}
                              alt={"outside_outlet"}
                            />
                          </a>
                        ) : (
                          <img
                            className={"cameraFieldImageContainer"}
                            src={this.getImgDataByType(
                              businessDocType.OUTSIDE_OUTLET_FILE
                            )}
                            alt={"outside_outlet"}
                          />
                        ))}
                    </div>
                    <div className={"itemCenter"}>
                      <CameraAltIcon
                        onClick={() =>
                          this.handleOpenCameraComponent(
                            businessDocType.OUTSIDE_OUTLET_FILE
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className={classes.bussinesDocPosition}>
                    <label
                      className={"cameraFieldLabel itemCenter"}
                      style={{
                        color: theme.palette.primary1Color,
                        textAlign: "left",
                      }}
                    >
                      *Proof of Business Document{" "}
                    </label>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-mutiple-name-label">
                        Business Document
                      </InputLabel>
                      <Select
                        fullWidth
                        style={{ width: 250 }}
                        value={this.state.businessDocumentType || ""}
                        onChange={(e) =>
                          this.setState({
                            ...this.state,
                            businessDocumentType: e.target.value,
                          })
                        }
                      >
                        {_.map(
                          this.contractorDocumentUploadType(),
                          (document) => (
                            <option value={document.type} key={document.id}>
                              {document.type}
                            </option>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </div>
                  <div>
                    {this.state.businessDocumentType && (
                      <div className={"cameraFieldsGrid"}>
                        <label
                          className={"cameraFieldLabel itemCenter"}
                          style={{
                            color: theme.palette.primary1Color,
                            fontSize: 14,
                            textAlign: "left",
                          }}
                        >
                          {this.state.businessDocumentType}
                        </label>
                        <div className={"cameraFieldImageContainer itemCenter"}>
                          {this.isImgDataAvailable(
                            businessDocType.BUSINESS_DOCUMENT_FILE
                          ) &&
                            (this.getImgDataByType(
                              businessDocType.BUSINESS_DOCUMENT_FILE
                            )[0] === "h" ? (
                              <a
                                download={
                                  "customer_gst" +
                                  this.getFileExtn(
                                    this.getImgDataByType(
                                      businessDocType.BUSINESS_DOCUMENT_FILE
                                    )
                                  )
                                }
                                href={this.getImgDataByType(
                                  businessDocType.BUSINESS_DOCUMENT_FILE
                                )}
                              >
                                <img
                                  className={"cameraFieldImageContainer"}
                                  src={this.getImgDataByType(
                                    businessDocType.BUSINESS_DOCUMENT_FILE
                                  )}
                                  alt={"customer_gst"}
                                />
                              </a>
                            ) : (
                              <img
                                className={"cameraFieldImageContainer"}
                                src={this.getImgDataByType(
                                  businessDocType.BUSINESS_DOCUMENT_FILE
                                )}
                                alt={"customer_gst"}
                              />
                            ))}
                        </div>
                        <div className={"itemCenter"}>
                          <CameraAltIcon
                            onClick={() =>
                              this.handleOpenCameraComponent(
                                businessDocType.BUSINESS_DOCUMENT_FILE
                              )
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {error.businessDocumentFile && (
                    <div
                      style={{
                        fontSize: 12,
                        color: "red",
                        textAlign: "center",
                        margin: 20,
                      }}
                    >
                      {error.businessDocumentFile}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div
              style={{
                width: "90%",
                margin: "0 auto",
                marginTop: 20,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ flex: 1 }}>
                {this.state.customerType === "CONTRACTOR" ? (
                  <Button
                    className={classes.next}
                    variant="outlined"
                    color="secondary"
                    size="medium"
                    onClick={() => this.setState({ ...this.state, pg: 1 })}
                  >
                    Back
                  </Button>
                ) : (
                  this.getBackButton()
                )}
              </div>
              <div style={{ flex: 1 }}>
                {this.getNextButton(() => this.validatePage4())}
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  validatePage4 = () => {
    const {
      outsideOutletFile,
      form,
      customerType,
      businessDocumentFile,
    } = this.state;
    const error = {};

    if (customerType === "RESELLER") {
      if (outsideOutletFile === "" && form.outsideOutletFilePath === null) {
        error.outsideOutletFile = "Please upload files from Outside the Outlet";
      }
    }

    if (businessDocumentFile === "" && form.businessDocumentPath === null) {
      error.businessDocumentFile = "Please upload Atleast 1 Business Documents";
    }

    this.setState({ error });
    return _.isEmpty(error);
  };

  isChecked = (category, categories) => {
    const { selectedCategories } = this.state;
    if (selectedCategories.includes(category)) return true;
    else if (
      category === "Other Category" &&
      _.difference(selectedCategories, categories).length
    )
      return true;
    return false;
  };
  resellerType = () => {
    const {
      otherCategoryValue,
      selectedCategories,
      selectedSubCategories,
      selectedBrands,
      error,
    } = this.state;
    return (
      <>
        <Accordion>
          <AccordionSummary style={{ backgroundColor: "#EFEFEF" }}>
            <div>*Category</div>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {categories.map((category) => (
                <div
                  style={{
                    width: "100%",
                    flex: "0 50%",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ flex: 2 }}>
                      <input
                        type="checkbox"
                        checked={this.isChecked(category, categories)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            this.setState({
                              selectedCategories: [
                                ...selectedCategories,
                                category,
                              ],
                            });
                            return;
                          }
                          let filteredCategories = _.filter(
                            selectedCategories,
                            (c) =>
                              _.includes(categories, c)
                                ? category !== c
                                : c !== otherCategoryValue
                          );
                          this.setState({
                            selectedCategories: filteredCategories,
                          });
                        }}
                        id={category}
                      />
                    </div>
                    <div style={{ flex: 13, textAlign: "left" }}>
                      <label for={category}>{category}</label>
                    </div>
                  </div>
                </div>
              ))}
              {(selectedCategories.includes("Other Category") ||
                _.difference(selectedCategories, categories).length > 0) && (
                <div style={{ marginLeft: 21, width: "100%", display: "flex" }}>
                  <TextField
                    value={otherCategoryValue}
                    onChange={(e) => {
                      this.setState({ otherCategoryValue: e.target.value });
                    }}
                  />
                </div>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
        {error.category && (
          <div
            style={{
              fontSize: 12,
              color: "red",
              textAlign: "center",
              margin: 20,
            }}
          >
            {error.category}
          </div>
        )}
        {selectedCategories.length > 0 && (
          <Accordion>
            <AccordionSummary style={{ backgroundColor: "#EFEFEF" }}>
              <div>*Sub Category</div>
            </AccordionSummary>
            {selectedCategories.map((category) => (
              <AccordionDetails>
                <div style={{ width: "95%", margin: "0 auto" }}>
                  {categories.includes(category) &&
                    category !== "Other Category" && (
                      <div style={{ fontWeight: "bold", fontSize: 18 }}>
                        {category}
                      </div>
                    )}
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {getSubCategories(category).map((subCategory) => (
                      <div
                        style={{
                          width: "100%",
                          flex: "0 50%",
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                      >
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div style={{ flex: 2 }}>
                            <input
                              type="checkbox"
                              checked={selectedSubCategories.includes(
                                subCategory
                              )}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  this.setState({
                                    selectedSubCategories: [
                                      ...selectedSubCategories,
                                      subCategory,
                                    ],
                                  });
                                  return;
                                }
                                this.setState({
                                  selectedSubCategories: _.remove(
                                    selectedSubCategories,
                                    (subCategoryValue) =>
                                      subCategoryValue !== subCategory
                                  ),
                                });
                              }}
                              id={`${subCategory}1`}
                            />
                          </div>
                          <div style={{ flex: 13, textAlign: "left" }}>
                            <label for={`${subCategory}1`}>{subCategory}</label>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </AccordionDetails>
            ))}
          </Accordion>
        )}
        {error.subCategory && (
          <div
            style={{
              fontSize: 12,
              color: "red",
              textAlign: "center",
              margin: 20,
            }}
          >
            {error.subCategory}
          </div>
        )}
        {selectedCategories.length > 0 && (
          <Accordion>
            <AccordionSummary style={{ backgroundColor: "#EFEFEF" }}>
              <div>*Brands</div>
            </AccordionSummary>
            {selectedCategories.map((category) => (
              <AccordionDetails>
                <div style={{ width: "95%", margin: "0 auto" }}>
                  <div style={{ fontWeight: "bold", fontSize: 18 }}>
                    {getBrands(category).length ? category : null}
                  </div>
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {getBrands(category).map((brand) => (
                      <div
                        style={{
                          width: "100%",
                          flex: "0 50%",
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                      >
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div style={{ flex: 2 }}>
                            <input
                              type="checkbox"
                              checked={selectedBrands.includes(brand)}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  this.setState({
                                    selectedBrands: [...selectedBrands, brand],
                                  });
                                  return;
                                }
                                this.setState({
                                  selectedBrands: _.remove(
                                    selectedBrands,
                                    (brandValue) => brandValue !== brand
                                  ),
                                });
                              }}
                              id={`${brand}2`}
                            />
                          </div>
                          <div style={{ flex: 13, textAlign: "left" }}>
                            <label for={`${brand}2`}>{brand}</label>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </AccordionDetails>
            ))}
          </Accordion>
        )}
        {error.brand && (
          <div
            style={{
              fontSize: 12,
              color: "red",
              textAlign: "center",
              margin: 20,
            }}
          >
            {error.brand}
          </div>
        )}
      </>
    );
  };

  contractorType = () => {
    const {
      error,
      contractorTypes,
      applicatorTypes,
      applicatorData,
      form,
    } = this.state;
    const { businessInformation } = form;
    const {
      numOfProjectsPerYear,
      valuePerProject,
      materialValuePerProject,
      materialLabourContract,
    } = businessInformation || {};
    const { buyLikeliness } = form;
    const { classes } = this.props;
    const fieldValue = [
      { name: "numOfProjectsPerYear", value: numOfProjectsPerYear },
      { name: "valuePerProject", value: valuePerProject },
      { name: "materialValuePerProject", value: materialValuePerProject },
      { name: "materialLabourContract", value: materialLabourContract },
    ];
    const otherCategories = _.difference(
      applicatorTypes.map((applicator) => applicator.name),
      getApplicator()
        .map((applicator) => applicator.name)
        .filter((applicator) => applicator !== "Other Category")
    );
    const otherCategoryValue =
      otherCategories.length > 0 ? otherCategories[0] : "";
    return (
      <>
        {getContractorType().length > 0 && (
          <Accordion defaultExpanded>
            <AccordionSummary style={{ backgroundColor: "#EFEFEF" }}>
              <div>*Contractor Type</div>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {getContractorType().map((category, index) => {
                  return (
                    <div
                      style={{
                        width: "100%",
                        flex: "0 50%",
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ flex: 2 }}>
                          <input
                            type="checkbox"
                            checked={contractorTypes.some(
                              (type) => type.id === category.id
                            )}
                            onChange={(e) => {
                              if (e.target.checked) {
                                this.setState({ contractorTypes: [category] });
                                return;
                              }
                              this.setState({
                                contractorTypes: _.remove(
                                  contractorTypes,
                                  (categoryValue) =>
                                    categoryValue.id !== category.id
                                ),
                              });
                            }}
                            id={category.id}
                          />
                        </div>
                        <div style={{ flex: 13, textAlign: "left" }}>
                          <label for={category.id}>
                            {getContractorType()[index].value}
                            <br />
                          </label>
                        </div>
                      </div>
                    </div>
                  );
                })}
                
                {/* {(contractorTypes.some((type)=>type.id==='6'))?(
                      <div style={{marginLeft:52}}>
                        <TextField
                        value={contractorTypes.find((type)=>type.id==='6').value||''}
                        error={error.otherContractors}
                        helperText={error.otherContractors}
                        onChange={(e)=>{
                          e.persist();
                          let userInfo=[...contractorTypes];
                          let value=e.target.value;
                          userInfo.map((user,index)=>{
                            if(user.id==='6'){
                              userInfo[index].value=value
                            }
                            return null
                          });
                          this.setState(prevState => ({...prevState,contractorTypes: userInfo}))
                        }}
                        />
                      </div>
                        ):null} */}
                {contractorTypes.some((type) => type.id === "7") ? (
                  <div
                    style={{
                      width: "90%",
                      display: "flex",
                      flexWrap: "wrap",
                      margin: "0px auto",
                    }}
                  >
                    {getApplicator().map((applicator, index) => {
                      return (
                        <div className={classes.flexWrap}>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div
                              style={{
                                flex: 2,
                                textAlign: "left",
                                marginBottom: "1rem",
                              }}
                            >
                              <input
                                type="checkbox"
                                checked={
                                  applicatorTypes.find(
                                    (type) => type.name === applicator.name
                                  ) ||
                                  (applicator.name === "Other Category" &&
                                    otherCategories.length > 0)
                                }
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    let data = {};
                                    data.loyaltyPrograms = [];
                                    data.name = applicator.name;
                                    data.id = applicator.id;
                                    if (applicatorData.length) {
                                      let checkType = applicatorData.find(
                                        (type) => type.name === applicator.name
                                      );
                                      if (checkType) data.id = checkType.id;
                                    }
                                    this.setState({
                                      applicatorTypes: [
                                        ...applicatorTypes,
                                        data,
                                      ],
                                    });
                                    return;
                                  }
                                  this.setState({
                                    applicatorTypes: _.filter(
                                      applicatorTypes,
                                      (type) => {
                                        if (
                                          applicator.name === "Other Category"
                                        ) {
                                          return (
                                            type.name !== otherCategoryValue
                                          );
                                        }
                                        return type.name !== applicator.name;
                                      }
                                    ),
                                  });
                                }}
                                id={`${applicator.name}1`}
                              />
                              <span style={{ marginLeft: 10 }}>
                                <label for={`${applicator.name}1`}>
                                  {applicator.name}
                                </label>
                              </span>
                            </div>
                            <div style={{ marginLeft: 23, textAlign: "left" }}>
                              {applicator.name === "Other Category" &&
                                otherCategories.length > 0 && (
                                  <TextField
                                    value={
                                      otherCategoryValue === "Other Category"
                                        ? ""
                                        : otherCategoryValue
                                    }
                                    onChange={(e) => {
                                      let info = [...applicatorTypes];
                                      const data =
                                        info.find(
                                          (type) =>
                                            type.name === otherCategoryValue
                                        ) ||
                                        applicatorTypes.find(
                                          (applicatorType) =>
                                            applicatorType.name ===
                                            "Other Category"
                                        );
                                      data.name = e.target.value;
                                      this.setState({ applicatorTypes: info });
                                    }}
                                  />
                                )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </div>
            </AccordionDetails>
          </Accordion>
        )}
        {error.contractorType && (
          <div
            style={{
              fontSize: 12,
              color: "red",
              textAlign: "center",
              margin: 20,
            }}
          >
            {error.contractorType}
          </div>
        )}
        <div style={{ marginTop: 20 }}>
          {getBusinessProjects().length > 0 && !this.props.rejectReasonId && (
            <Accordion defaultExpanded>
              <AccordionSummary style={{ backgroundColor: "#EFEFEF" }}>
                <div>*Business Information</div>
              </AccordionSummary>
              <AccordionDetails>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  {getBusinessProjects().map((category, index) => (
                    <div className={classes.flexWrap}>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ flex: 1, textAlign: "left" }}>
                          {category}
                          <br />
                          <TextField
                            type="number"
                            value={fieldValue[index].value}
                            name={fieldValue[index].name}
                            error={error[fieldValue[index].name]}
                            helperText={error[fieldValue[index].name]}
                            onChange={(e) => {
                              let business = { ...businessInformation };
                              business[e.target.name] = e.target.value;
                              error[fieldValue[index].name] = "";
                              if (
                                e.target.name === "materialValuePerProject" &&
                                e.target.value > 80
                              )
                                business[e.target.name] = "80";
                              if (
                                e.target.name === "materialLabourContract" &&
                                e.target.value > 10
                              )
                                business[e.target.name] = "10";
                              if (
                                e.target.name === "numOfProjectsPerYear" &&
                                e.target.value > 10
                              )
                                business[e.target.name] = "10";
                              this.setState((prevState) => ({
                                form: {
                                  ...prevState.form,
                                  businessInformation: business,
                                },
                                error: error,
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </AccordionDetails>
              <AccordionDetails>
                <div style={{ width: "100%", textAlign: "left" }}>
                  <div>*How likely is the customer to buy from us ?</div>
                  <>
                    {getBusinessChances() &&
                      getBusinessChances().map((user, index) => (
                        <FormControlLabel
                          value={buyLikeliness}
                          control={
                            <Radio
                              checked={buyLikeliness === index}
                              onChange={() => {
                                let formValues = { ...form };
                                formValues.buyLikeliness = index;
                                this.setState({ form: formValues });
                              }}
                              color="primary"
                              size="small"
                            />
                          }
                          label={user}
                        />
                      ))}
                    {error.buyLikeliness && (
                      <div
                        style={{
                          fontSize: 12,
                          color: "red",
                          textAlign: "center",
                          margin: 20,
                        }}
                      >
                        {error.buyLikeliness}
                      </div>
                    )}
                  </>
                </div>
              </AccordionDetails>
            </Accordion>
          )}
        </div>
      </>
    );
  };

  renderCustomerType = (type) => {
    switch (type) {
      case "RESELLER":
        return this.resellerType();
      case "CONTRACTOR":
        return this.contractorType();
      default:
        return;
    }
  };

  getPage5 = () => {
    const { customerType, error } = this.state;
    return (
      <div>
        <h3
          style={{
            marginTop: 50,
            textTransform: "uppercase",
            textAlign: "center",
          }}
        >
          {pageHeaders[4]}
        </h3>
        <div style={{ marginTop: 15, textAlign: "left", marginBottom: 15 }}>
          <FormControl style={{ width: "100%" }} error={error.customerType}>
            <InputLabel
              style={{ marginRight: 20, color: theme.palette.primary1Color }}
            >
              *Customer Type
            </InputLabel>
            <Select
              displayEmpty
              disabled
              readOnly
              style={{ width: "100%" }}
              value={customerType}
              onChange={(e) => {
                e.persist();
                this.setState((prevState) => ({
                  ...prevState,
                  customerType: e.target.value,
                }));
                this.setState((prevState) => ({ ...prevState, error: {} }));
              }}
            >
              <MenuItem value="RESELLER">Reseller</MenuItem>
              <MenuItem value="CONTRACTOR">Contractor</MenuItem>
            </Select>
            <FormHelperText>{error.customerType}</FormHelperText>
          </FormControl>
        </div>
        {this.renderCustomerType(customerType)}
        <div
          style={{
            width: "90%",
            margin: "0 auto",
            marginTop: 20,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ flex: 1 }}>{this.getBackButton()}</div>
          {customerType === "RESELLER" ? (
            <div style={{ flex: 1 }}>
              {this.getNextButton(() => this.validatePage5())}
            </div>
          ) : (
            <div style={{ flex: 1 }}>
              {this.getSaveButton(() => this.validatePage5())}
            </div>
          )}
        </div>
      </div>
    );
  };

  validatePage5 = () => {
    const {
      selectedCategories,
      selectedSubCategories,
      selectedBrands,
      customerType,
      contractorTypes,
      otherContractors,
      form,
    } = this.state;
    const { businessInformation } = form;
    const error = {};
    const hasOtherCategory =
      selectedCategories.includes("Other Category") ||
      _.difference(selectedCategories, categories).length > 0;

    if (!customerType) {
      error.customerType = "Customer Type is Required";
    }

    if (customerType === "RESELLER") {
      if (selectedCategories.length === 0) {
        error.category = "Atleast 1 Category is Required";
      }

      if (
        !(hasOtherCategory && selectedCategories.length === 1) &&
        selectedSubCategories.length === 0
      ) {
        error.subCategory = "Atleast 1 Subcategory is Required";
      }

      if (
        !(hasOtherCategory && selectedCategories.length === 1) &&
        selectedBrands.length === 0
      ) {
        error.brand = "Atleast 1 Brand is Required";
      }
    } else if (customerType === "CONTRACTOR") {
      if (contractorTypes.length === 0) {
        error.contractorType = "Atleast 1 contractor type is Required";
      }
      if (
        !this.props.rejectReasonId &&
        contractorTypes.includes("Other Category") &&
        !otherContractors
      ) {
        error.otherContractors = "This field is required.";
      }
      if (
        !this.props.rejectReasonId &&
        !businessInformation.numOfProjectsPerYear.length
      ) {
        error.numOfProjectsPerYear = "This field is mandatory.";
      }
      if (
        !this.props.rejectReasonId &&
        !businessInformation.valuePerProject.length
      ) {
        error.valuePerProject = "This field is mandatory";
      }
      if (
        !this.props.rejectReasonId &&
        !businessInformation.materialValuePerProject.length
      ) {
        error.materialValuePerProject = "This field is mandatory";
      }
      if (
        !this.props.rejectReasonId &&
        !_.isNumber(_.get(form, "buyLikeliness"))
      ) {
        error.buyLikeliness = "This field is mandatory buyLikeliness";
      }
    }

    this.setState({ error });
    return _.isEmpty(error);
  };

  getPage6 = () => {
    const { classes } = this.props;
    const { form, error, customerType } = this.state;
    const { buyLikeliness } = form || {};
    const {
      monthlySale,
      retailSalesPercent,
      wholesaleSalesPercent,
      directPurchasePercent,
      indirectPurchasePercent,
    } = form || {};

    return (
      <>
        <h3
          style={{
            marginTop: 50,
            textTransform: "uppercase",
            textAlign: "center",
          }}
        >
          {pageHeaders[1]}
        </h3>
        <TextField
          label="Monthly Sale(in Lakhs)"
          variant="outlined"
          type="number"
          value={monthlySale}
          error={error.monthlySale}
          helperText={error.monthlySale}
          style={{ width: "100%", marginTop: 15 }}
          className={classes.root}
          onChange={(e) => {
            e.persist();
            let value;
            // e.parseInt(e.target.value)
            if (e.target.value < 200) value = parseInt(e.target.value);
            else value = 200;
            this.setState((prevState) => ({
              ...prevState,
              form: { ...form, monthlySale: value },
            }));
          }}
        />
        <TextField
          label="Percent Sale - Retail"
          variant="outlined"
          type="number"
          value={retailSalesPercent}
          error={error.retailSalesPercent}
          helperText={error.retailSalesPercent}
          style={{ width: "100%", marginTop: 15 }}
          className={classes.root}
          onChange={(e) => {
            e.persist();
            let percentValueRaw = parseInt(e.target.value);
            let percentValue =
              _.isNumber(percentValueRaw) && percentValueRaw > 100
                ? 100
                : percentValueRaw;
            this.setState((prevState) => ({
              ...prevState,
              form: {
                ...form,
                retailSalesPercent: percentValue,
                wholesaleSalesPercent: parseInt(100 - percentValue),
              },
            }));
          }}
        />
        <TextField
          label="Percent Sale - Wholesale"
          variant="outlined"
          type="number"
          InputProps={{
            readOnly: true,
          }}
          value={wholesaleSalesPercent || 0}
          error={error.wholesaleSalesPercent}
          helperText={error.wholesaleSalesPercent}
          style={{ width: "100%", marginTop: 15 }}
          className={classes.root}
          onChange={(e) => {
            e.persist();
            this.setState((prevState) => ({
              ...prevState,
              form: {
                ...form,
                wholesaleSalesPercent: parseInt(e.target.value),
              },
            }));
          }}
          disabled
        />
        <TextField
          label="Percent Purchase - Direct"
          variant="outlined"
          type="number"
          value={directPurchasePercent}
          error={error.directPurchasePercent}
          helperText={error.directPurchasePercent}
          style={{ width: "100%", marginTop: 15 }}
          className={classes.root}
          onChange={(e) => {
            e.persist();
            let percentValueRaw = parseInt(e.target.value);
            let percentValue =
              _.isNumber(percentValueRaw) && percentValueRaw > 100
                ? 100
                : percentValueRaw;
            this.setState((prevState) => ({
              ...prevState,
              form: {
                ...form,
                directPurchasePercent: percentValue,
                indirectPurchasePercent: parseInt(100 - percentValue),
              },
            }));
          }}
        />
        <TextField
          label="Percent Purchase - Indirect"
          variant="outlined"
          type="number"
          InputProps={{
            readOnly: true,
          }}
          value={indirectPurchasePercent || 0}
          error={error.indirectPurchasePercent}
          helperText={error.indirectPurchasePercent}
          style={{ width: "100%", marginTop: 15 }}
          className={classes.root}
          onChange={(e) => {
            e.persist();
            this.setState((prevState) => ({
              ...prevState,
              form: {
                ...form,
                indirectPurchasePercent: parseInt(e.target.value),
              },
            }));
          }}
          disabled
        />
        {customerType === "RESELLER" && (
          <div style={{ marginTop: 15, textAlign: "left", marginBottom: 15 }}>
            <InputLabel
              style={{ marginRight: 20, color: theme.palette.primary1Color }}
            >
              How likely is the customer to buy from us?
            </InputLabel>
            <FormControl style={{ width: "100%" }} error={error.buyLikeliness}>
              <Select
                displayEmpty
                style={{ width: "100%" }}
                value={buyLikeliness}
                onChange={(e) => {
                  e.persist();
                  this.setState((prevState) => ({
                    ...prevState,
                    form: { ...form, buyLikeliness: e.target.value },
                  }));
                }}
              >
                <MenuItem value={0}>Least Likely</MenuItem>
                <MenuItem value={1}>Less Likely</MenuItem>
                <MenuItem value={2}>Neutral</MenuItem>
                <MenuItem value={3}>Likely</MenuItem>
                <MenuItem value={4}>Most Likely</MenuItem>
              </Select>
              <FormHelperText>{error.buyLikeliness}</FormHelperText>
            </FormControl>
          </div>
        )}
        <div
          style={{
            width: "90%",
            margin: "0 auto",
            marginTop: 20,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ flex: 1 }}>{this.getBackButton()}</div>
          <div style={{ flex: 1 }}>
            {this.getSaveButton(() => this.validatePage6())}
          </div>
        </div>
      </>
    );
  };

  validatePage6 = () => {
    const { form } = this.state;
    const { buyLikeliness } = form || {};
    const {
      monthlySale,
      retailSalesPercent,
      wholesaleSalesPercent,
      directPurchasePercent,
      indirectPurchasePercent,
    } = form || {};
    const error = {};

    if (!_.isNumber(buyLikeliness)) {
      error.buyLikeliness = "Please Select Likeliness of Purchase";
    }

    if (monthlySale && isNaN(monthlySale)) {
      error.monthlySale = "Monthly Sales must be a number";
    }

    if (
      wholesaleSalesPercent &&
      !(wholesaleSalesPercent >= 0 && wholesaleSalesPercent <= 100)
    ) {
      error.wholesaleSalesPercent =
        "Wholesale Sales Percent must be between 0 and 100";
    }

    if (
      retailSalesPercent &&
      !(retailSalesPercent >= 0 && retailSalesPercent <= 100)
    ) {
      error.retailSalesPercent =
        "Retail Sales Percent must be between 0 and 100";
    }

    if (
      directPurchasePercent &&
      !(directPurchasePercent >= 0 && directPurchasePercent <= 100)
    ) {
      error.directPurchasePercent =
        "Direct Purchase Percent must be between 0 and 100";
    }

    if (
      indirectPurchasePercent &&
      !(indirectPurchasePercent >= 0 && indirectPurchasePercent <= 100)
    ) {
      error.indirectPurchasePercent =
        "Indirect Purchase Percent must be between 0 and 100";
    }

    this.setState({ error });
    return _.isEmpty(error);
  };

  chooseUploadType = () => {
    let { uploadType, error, form } = this.state;
    const { businessDocumentPath, contractorReferrals } = form;
    const { classes } = this.props;
    if (!!businessDocumentPath && uploadType === undefined)
      uploadType = "documentUpload";
    if (contractorReferrals.length > 0 && uploadType === undefined)
      uploadType = "reference";

    return (
      <>
        <h3>What type of proof of business you have?</h3>
        <div
          style={{
            display: "flex",
            marginTop: "30px",
            justifyContent: "center",
          }}
        >
          <div className={classes.documentUpload}>
            <FormControlLabel
              value={uploadType}
              control={
                <Radio
                  checked={uploadType === "documentUpload"}
                  color="primary"
                  onChange={() => {
                    error.uploadType = "";
                    this.setState({
                      ...this.state,
                      uploadType: "documentUpload",
                      error: error,
                    });
                  }}
                />
              }
              label="Document upload page"
            />
            <FormControlLabel
              value={uploadType}
              control={
                <Radio
                  color="primary"
                  checked={uploadType === "reference"}
                  onChange={() => {
                    error.uploadType = "";
                    this.setState({
                      ...this.state,
                      uploadType: "reference",
                      error: error,
                    });
                  }}
                />
              }
              label="Refer a customer"
            />
          </div>
        </div>
        {error.uploadType && (
          <div style={{ fontSize: 14, color: "red" }}>{error.uploadType}</div>
        )}
        <div
          style={{
            width: "90%",
            margin: "0 auto",
            marginTop: 20,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ flex: 1 }}>{this.getBackButton()}</div>
          <div style={{ flex: 1 }}>
            <Button
              className={classes.next}
              variant="outlined"
              color="secondary"
              size="medium"
              onClick={() => {
                if (!uploadType) {
                  error.uploadType = "Please Choose business Type";
                  this.setState({ ...this.state, error: error });
                } else this.setState({ ...this.state, pg: 2 });
              }}
            >
              Next
            </Button>
          </div>
          {/* <div style={{flex: 1}}>{this.getNextButton(() => this.validatePage5())}</div> */}
        </div>
      </>
    );
  };

  validateReferenceType = () => {
    const { contractorReferral1, contractorReferral2 } = this.state;
    let errors = {
      contractorReferral1: {
        name: "",
        mobile: "",
      },
      contractorReferral2: {
        name: "",
        mobile: "",
      },
    };
    let errorFlag = false;
    if (!contractorReferral1.name) {
      errorFlag = true;
      errors.contractorReferral1.name = "*This Field is Mandatory";
    }
    if (
      contractorReferral1.mobile &&
      contractorReferral1.mobile.toString().length !== 10
    ) {
      errorFlag = true;
      errors.contractorReferral1.mobile = "*Enter valid Mobile Number.";
    }
    if (
      contractorReferral2.mobile &&
      contractorReferral2.mobile.toString().length !== 10
    ) {
      errorFlag = true;
      errors.contractorReferral2.mobile = "*Enter valid Mobile Number.";
    }
    if (!contractorReferral1.mobile) {
      errorFlag = true;
      errors.contractorReferral1.mobile = "*This Field is Mandatory";
    }
    if (!contractorReferral2.name) {
      errorFlag = true;
      errors.contractorReferral2.name = "*This Field is Mandatory";
    }
    if (!contractorReferral2.mobile) {
      errorFlag = true;
      errors.contractorReferral2.mobile = "*This Field is Mandatory";
    }
    this.setState({ ...this.state, error: errors });
    return errorFlag;
  };

  referenceType = () => {
    const { classes } = this.props;
    const { contractorReferral1, contractorReferral2, error } = this.state;
    let errors = _.cloneDeep(error);
    return (
      <>
        <h3>Referral 1 Details</h3>
        <TextField
          value={contractorReferral1.name || ""}
          label="*Referral 1 Name"
          variant="outlined"
          error={error.contractorReferral1 && error.contractorReferral1.name}
          helperText={
            error.contractorReferral1 && error.contractorReferral1.name
          }
          style={{ width: "100%", marginTop: 15 }}
          className={classes.root}
          onChange={(e) => {
            e.persist();
            this.setState((prevState) => ({
              contractorReferral1: {
                ...prevState.contractorReferral1,
                name: e.target.value,
              },
              error: errors,
            }));
          }}
        />
        <TextField
          value={contractorReferral1.mobile || ""}
          label="*Referral 1 Mobile"
          variant="outlined"
          type="number"
          error={error.contractorReferral1 && error.contractorReferral1.mobile}
          helperText={
            error.contractorReferral1 && error.contractorReferral1.mobile
          }
          style={{ width: "100%", marginTop: 15 }}
          className={classes.root}
          onChange={(e) => {
            e.persist();
            this.setState((prevState) => ({
              contractorReferral1: {
                ...prevState.contractorReferral1,
                mobile: e.target.value,
              },
              error: errors,
            }));
          }}
        />
        <TextField
          value={contractorReferral1.address || ""}
          label="Referral 1 Address"
          variant="outlined"
          style={{ width: "100%", marginTop: 15 }}
          className={classes.root}
          onChange={(e) => {
            e.persist();
            this.setState((prevState) => ({
              contractorReferral1: {
                ...prevState.contractorReferral1,
                address: e.target.value,
              },
            }));
          }}
        />
        <h3 style={{ marginTop: 10 }}>Referral 2 Details</h3>
        <TextField
          value={contractorReferral2.name || ""}
          label="*Referral 2 Name"
          variant="outlined"
          style={{ width: "100%", marginTop: 15 }}
          className={classes.root}
          error={error.contractorReferral2 && error.contractorReferral2.name}
          helperText={
            error.contractorReferral2 && error.contractorReferral2.name
          }
          onChange={(e) => {
            e.persist();
            this.setState((prevState) => ({
              contractorReferral2: {
                ...prevState.contractorReferral2,
                name: e.target.value,
              },
              error: errors,
            }));
          }}
        />
        <TextField
          value={contractorReferral2.mobile || ""}
          label="*Referral 2 Mobile"
          variant="outlined"
          type="number"
          error={error.contractorReferral2 && error.contractorReferral2.mobile}
          helperText={
            error.contractorReferral2 && error.contractorReferral2.mobile
          }
          style={{ width: "100%", marginTop: 15 }}
          className={classes.root}
          onChange={(e) => {
            e.persist();
            this.setState((prevState) => ({
              contractorReferral2: {
                ...prevState.contractorReferral2,
                mobile: e.target.value,
              },
              error: errors,
            }));
          }}
        />
        <TextField
          value={contractorReferral2.address || ""}
          label="Referral 2 Address"
          variant="outlined"
          style={{ width: "100%", marginTop: 15 }}
          className={classes.root}
          onChange={(e) => {
            e.persist();
            this.setState((prevState) => ({
              contractorReferral2: {
                ...prevState.contractorReferral2,
                address: e.target.value,
              },
            }));
          }}
        />
        <div
          style={{
            width: "90%",
            margin: "0 auto",
            marginTop: 20,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ flex: 1 }}>
            {/* {
              this.state.uploadType?( */}
            <Button
              className={classes.next}
              variant="outlined"
              color="secondary"
              size="medium"
              onClick={() => {
                this.setState({ ...this.state, pg: 1, error: {} });
              }}
            >
              Back
            </Button>
            {/* ):this.getBackButton() */}
            {/* } */}
          </div>
          <div style={{ flex: 1 }}>{this.getNextButton()}</div>
        </div>
      </>
    );
  };
  renderChooseUpload = () => {
    const { pg, uploadType } = this.state;
    switch (pg) {
      case 1:
        return this.chooseUploadType();
      case 2:
        if (uploadType === "documentUpload") return this.getPage4();
        else return this.referenceType();
      default:
        return;
    }
  };

  getPages() {
    const { pageNumber, data } = this.props;
    switch (pageNumber) {
      case 1:
        return this.getPage1();
      case 2:
        return this.getPage2();
      case 3:
        return this.getPage3();
      case 4:
        if (data.customerType === "RESELLER") return this.getPage4();
        else return this.renderChooseUpload();
      case 5:
        return this.getPage5();
      case 6:
        return this.getPage6();
      default:
        return;
    }
  }

  render() {
    const { form, openModal,shouldShowSummary } = this.state;
    let {
      firstName,
      businessName,
      addressLine1,
      addressLine2,
      city,
      state,
      mobileNumber,
      email,
      gstNumber,
    } = form || {};
    const { classes } = this.props;
    return (
      <div
        style={{
          marginTop: 60,
          textAlign: "center",
          height: "auto",
          paddingBottom: 50,
        }}
      >
        {shouldShowSummary ? (
          <>
            <div style={{ width: "60%", margin: "0 auto" }}>
              <div style={{ padding: 10 }}>
                <h3>Welcome {firstName || ""}!</h3>
                <div style={{ width: "95%", margin: "0 auto" }}>
                  Welcome to Dalmia business, your application has been
                  submitted.
                </div>
              </div>
              <h3 style={{ backgroundColor: "#EFEFEF", padding: 10 }}>
                Application Details
              </h3>
              <div
                style={{
                  fontSize: 14,
                  padding: 20,
                  width: "90%",
                  margin: "0 auto",
                  textAlign: "left",
                }}
              >
                <div style={{ display: "flex", marginBottom: 10 }}>
                  <div style={{ flex: 4 }}>
                    <b>Name</b>
                  </div>
                  <div style={{ flex: 3, marginLeft: 20 }}>
                    {firstName || ""}
                  </div>
                </div>
                <div style={{ display: "flex", marginBottom: 10 }}>
                  <div style={{ flex: 4 }}>
                    <b>Member Type</b>
                  </div>
                  <div style={{ flex: 3, marginLeft: 20 }}>
                    Primary Card Holder
                  </div>
                </div>
                <div style={{ display: "flex", marginBottom: 10 }}>
                  <div style={{ flex: 4 }}>
                    <b>Business Name</b>
                  </div>
                  <div style={{ flex: 3, marginLeft: 20 }}>
                    {businessName || ""}
                  </div>
                </div>
                <div style={{ display: "flex", marginBottom: 10 }}>
                  <div style={{ flex: 4 }}>
                    <b>Business Address</b>
                  </div>
                  <div style={{ flex: 3, marginLeft: 20 }}>
                    <div>{addressLine1 || ""},</div>
                    <div>{addressLine2 || ""},</div>
                    <div>{city || ""},</div>
                    <div>{state || ""}</div>
                  </div>
                </div>
                <div style={{ display: "flex", marginBottom: 10 }}>
                  <div style={{ flex: 4 }}>
                    <b>Business Phone</b>
                  </div>
                  <div style={{ flex: 3, marginLeft: 20 }}>
                    {mobileNumber || ""}
                  </div>
                </div>
                <div style={{ display: "flex", marginBottom: 10 }}>
                  <div style={{ flex: 4 }}>
                    <b>Business Email</b>
                  </div>
                  <div style={{ flex: 3, marginLeft: 20 }}>{email || ""}</div>
                </div>
                <div style={{ display: "flex", marginBottom: 10 }}>
                  <div style={{ flex: 4 }}>
                    <b>GST Number</b>
                  </div>
                  <div style={{ flex: 3, marginLeft: 20 }}>
                    {gstNumber || ""}
                  </div>
                </div>
                <div style={{ textAlign: "center" }}>
                  <Button
                    style={{
                      marginLeft: ".5rem",
                      backgroundColor: theme.palette.primary1Color,
                      borderWidth: 1,
                      borderRadius: 0,
                      borderColor: theme.palette.primary1Color,
                      color: "white",
                      marginTop: 30,
                      width: 150,
                    }}
                    onClick={() => {
                      this.setState({ openModal: true });
                    }}
                    variant="outlined"
                    color="secondary"
                    size="large"
                  >
                    Complete Submission
                  </Button>
                </div>
              </div>
            </div>
            <Modal
              open={openModal}
              onClose={() => {
                sessionService.logout();
                this.setState({ showModal: false });
              }}
            >
              <div className={classes.rejectedBusiness}>
                <img src={ThumbsUp} style={{ marginTop: 10 }} alt="" />
                {!this.props.rejectReasonId ? (
                  <>
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: 26,
                        fontWeight: 700,
                        fontFamily: "Noto Sans",
                        color: "#F47F22",
                      }}
                    >
                      Registration Successful!
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: 26,
                        fontWeight: 700,
                        fontFamily: "Noto Sans",
                        color: "#F47F22",
                      }}
                    >
                      Thank you
                    </div>
                    <div
                      style={{
                        fontWeight: 700,
                        fontSize: 14,
                        marginTop: 10,
                        marginBottom: 20,
                      }}
                    >
                      For Registering
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: 26,
                      fontWeight: 700,
                      fontFamily: "Noto Sans",
                      color: "#F47F22",
                      marginBottom: 20,
                    }}
                  >
                    Business marked as Rejected
                  </div>
                )}
                <Button
                  style={{ backgroundColor: "#F47E1F", color: "white" }}
                  onClick={async () => {
                    this.setState({ openModal: false });
                    !!window.ReactNativeWebView &&
                      window.ReactNativeWebView.postMessage("GO_BACK");
                  }}
                >
                  Close
                </Button>
              </div>
            </Modal>
          </>
        ) : (
          this.state.pageLoad 
          ?  
          <div className={'flex flex-column justify-center items-center fixed bottom-0 top-0  left-0 right-0'}>
            <CircularProgress/>
          </div> 
          : 
          <div className={classes.container}>{this.getPages()}</div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(RseForm);