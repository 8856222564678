import React from 'react';
import RseOtpFormComponent from '../components/rse-otp-form-component';
import NavBar from '../components/navbar-component';
import {FormWrapper} from './form-page';
import sessionService from '../services/session-service';
import beatBusinessService from '../services/beat-business-service';
import userService from '../services/user-service';
import _ from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';

export default class OtpFormPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tokenObj: undefined,
            isLoading: true,
            isError: false,
            weeklyHoliday:[]
        }
    }

    componentDidMount() {
        let params = (new URL(document.location)).searchParams;
        let businessId = params.get('business_id');
        let token = params.get('token');
        let mobileFromQueryParam = params.get('mobile');
        let rejectReasonId = params.get('rejectReasonId');
        let otherRejectReason = params.get('otherRejectReason');
        this.setState({
            isError: !(businessId || mobileFromQueryParam)
        })
        if(rejectReasonId) {
            this.setState({
                isOtpVerified: true,
                rejectReasonId,
                otherRejectReason,
            });
        }
        sessionService.logout();
        this.setState({
            businessId: businessId,
            beatUserToken: token
        })
        //todo: use jwt token, beat api was not working with bearer token, going with api key for demo
        beatBusinessService.getSalesPersonDetails(token)
            .then((salesPerson) => {
                if(!businessId) {
                    this.setState({
                        mobileNumber: mobileFromQueryParam,
                        salesPerson: salesPerson,
                        isLoading: false
                    })
                } else {
                    beatBusinessService.getBeatBusiness(businessId)
                        .then((beatBusiness) => {
                            userService.getProxyToken(businessId, token)
                                .then((tokenResponse) => {
                                    let tokenObj = _.get(tokenResponse, 'data');
                                    sessionService.login(tokenObj);
                                    this.setState({
                                        mobileNumber: _.get(beatBusiness, 'contactPersonMobile'),
                                        salesPerson: salesPerson,
                                        weeklyHoliday:beatBusiness.weeklyHoliday,
                                        tokenObj: tokenObj,
                                        isLoading: false,
                                        isOtpVerified: !!beatBusiness.registeredById
                                    })
                                })
                        });
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    render() {
        const {mobileNumber, isOtpVerified, businessId,weeklyHoliday,isLoading, isError, rejectReasonId, otherRejectReason, salesPerson} = this.state;
        return (
            <div>
                <NavBar/>
                {
                    isError ? <div className={'flex flex-column justify-center items-center fixed bottom-0 top-0  left-0 right-0'}>Mobile Or Business Id missing</div> : (isLoading ?
                            <div className={'flex flex-column justify-center items-center fixed bottom-0 top-0  left-0 right-0'}>
                                <CircularProgress/>
                            </div> : (isOtpVerified ?
                                <FormWrapper tokenObj={this.state.tokenObj} beatUserToken={this.state.beatUserToken}
                                             onLogout={() => this.props.onLogout()} mobile={mobileNumber}
                                             otherRejectReason={otherRejectReason}
                                             weeklyHoliday={weeklyHoliday}
                                             rejectReasonId={rejectReasonId}
                                             businessId={businessId}/> : (
                                    !!mobileNumber &&
                                    <RseOtpFormComponent beatBusinessId={businessId} mobileNumber={mobileNumber}
                                                        salesPerson = {salesPerson}
                                                         onOtpVerified={(tokenObj) => {
                                                             sessionService.login(tokenObj);
                                                             this.setState({
                                                                 isOtpVerified: true,
                                                                 tokenObj: tokenObj
                                                             });
                                                         }}/>
                                ))

                    )
                }
            </div>
        );
    }
}
