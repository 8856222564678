import React, {useEffect, useState} from "react";

export default function Timer({duration, onEnd, classes}) {
  const [seconds, setSeconds] = useState(duration);

  useEffect(() => {
    seconds > 0 ? setTimeout(() => setSeconds(seconds - 1), 1000) : onEnd();
  });

  return (
    <div className={classes}>
      {Math.floor(seconds/60)}:{seconds%60 < 10 ? `0${seconds%60}` : seconds%60}
    </div>
  );
}
