import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import Arrow from "../assets/images/Mask Group 46.png";
import gameBackground from "../assets/images/Group 4161.png";
import gameZoneIcon from "../assets/images/Group-1259@2x.png";
import userService from '../services/user-service';

const styles = {
  wrapper: {
    height: 230,
    width: 470,
    background: `url(${gameBackground}) no-repeat`,
    backgroundSize: "cover",
    display: "flex",
    justifyContent: "space-between",
    "@media (min-width:0px) and (max-width:700px)": {
      width: "88%",
      height: 196,
    },
    boxShadow:"0px 10px 13px -6px rgba(0,0,0,0.75)"
  },
  gameZone:{
    background:`url(${gameZoneIcon}) no-repeat`,
    height:144,
    width:200,
    backgroundSize:"100% 100%",
    "@media (min-width:0px) and (max-width:700px)": {
      width: 139,
      height: 107,
    },
  },
  earnSection: {
    background: `url(${Arrow}) no-repeat`,
    backgroundSize: "contain",
    height: 226,
    width: 156,
    "@media (min-width:0px) and (max-width:700px)": {
      height: 187,
      width: 120,
      backgroundSize:"cover",
      marginTop: 5,
    },
  },
  earn: {
    color: "white",
    fontWeight: "bolder",
    fontSize: 25,
    "@media (min-width:0px) and (max-width:700px)": {
      fontSize: 20,
    },
  },
  unlock: {
    color: "#02DCFF",
    fontWeight: "bolder",
    fontSize: 25,
    "@media (min-width:0px) and (max-width:700px)": {
      fontSize: 20,
    },
  },
  welcomeSection:{
    padding: 40,
    color: "white",
    fontWeight: "bolder",
    "@media (min-width:0px) and (max-width:700px)": {
      padding:"40px 20px",
    },
  }
};

class GameZone extends Component {

  getGameLevel() {
    const {points} = this.props;
    const {count} = points;
    if(!count)
      return 1;
    if(count > 2)
      return 3;
    return count + 1;
  }

  async takeUserToGame() {
    const isSuccess = await userService.spendPoints();
    if(isSuccess) {
      window.location = '/game-tambola'
    }
  }

  render() {
    const { classes } = this.props;
    const gameLevel = this.getGameLevel();

    return (
      <div style={{ marginTop: 25, display: "flex", justifyContent: "center" }} onClick={() => this.takeUserToGame()}>
        <div className={classes.wrapper}>
          <div className={classes.welcomeSection}>
            WELCOME TO THE
            <div className={classes.gameZone}/>
          </div>
          <div className={classes.earnSection}>
            <div style={{ paddingRight: 40, paddingTop: 40 }}>
              <div className={classes.earn}>EARN 50 POINTS&</div>
              <div className={classes.unlock}>UNLOCK LEVEL-{gameLevel}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(GameZone);
