import React from "react";
import Button from "@material-ui/core/Button";
import {theme} from "../styles/custom-theme-style";
import CircularProgress from "@material-ui/core/CircularProgress";
import config from "../config";

//Raja - Adding Comment For Dummy Push
export default class LocationComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      latitude: this.props.latitude,
      longitude: this.props.longitude,
      status: 'fresh'
    }
  }

  handleLocation = async (position) => {
    const {coords} = position;
    const {setLocation, businessId} = this.props;
    const {latitude, longitude} = coords;
    const requestPayload = {
      lat: latitude,
      lng: longitude,
      businessId
    };
    const response = await fetch(`${config.ruleServerUrl}/dalmia/location`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestPayload)
    });
    const data = await response.json();

    this.setState({latitude, longitude, statusCode: response.status, message: data.message})
    setLocation(latitude, longitude);
  };
 
  getLocation = () => {
    this.setState({status: 'loading'});
//Temp Fix for geolocation api issue
    const position = {coords : { latitude:28.624838,longitude:77.1213101}};
    this.handleLocation(position);

    // if(navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(
    //     (position) => this.handleLocation(position),
    //     (e) => {console.log(e.message); 
    //             this.getLocation();},{ enableHighAccuracy : true , timeout : 5000 , maximumAge : 0 });
    //      }
    
  };

  render() {
    const {status, latitude, longitude, statusCode, message} = this.state;
    return (
      <div>
        {
          (!latitude || !longitude) && status === 'loading' && (
            <div>
              <CircularProgress />
            </div>
          )
        }
        {
          latitude && longitude && (
            <div style={{display: 'flex', flexDirection: 'row'}}  className={'mt2'}>
              <div style={{flex: 1}}>
                <div style={{backgroundColor: '#EFEFEF', padding: 20, borderRadius: 5}}>Latitude:</div>
                <div style={{fontSize: 24, fontWeight: 'bold', marginTop: 30, marginBottom: 30}}>{latitude.toFixed(6)}</div>
              </div>
              <div style={{flex: 1}}>
                <div style={{backgroundColor: '#EFEFEF', padding: 20, borderRadius: 5}}>Longitude:</div>
                <div style={{fontSize: 24, fontWeight: 'bold', marginTop: 30, marginBottom: 30}}>{longitude.toFixed(6)}</div>
              </div>
            </div>
          )
        }
        <Button
          onClick={() => this.getLocation()}
          style={{
            backgroundColor: theme.palette.primary1Color,
            borderWidth: 1,
            borderRadius: 5,
            borderColor: theme.palette.primary1Color,
            color: 'white',
            width: 150
          }}
        >
          {(!latitude || !longitude) ? 'Get Location' : 'Change Location'}
        </Button>
        {
          statusCode && (
            <div style={{marginTop: 10, color: statusCode === 200 ? 'green' : 'red', fontSize: 12}}>
              {message}
              {statusCode !== 200 && <div style={{marginTop: 10, color: 'red', fontSize: 12}}>Please click on Change Location to Retry!</div>}
            </div>
          )
        }
      </div>
    )
  }
}
